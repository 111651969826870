import React from "react";
import DKCharacterFooter from "../../../../resources/images/1-Home/DK-Character-Footer.png";
import { ConHomeFooter, ImageHomeFooter } from "./HomeFooter.component";

function HomeFooter() {
  return (
    <ConHomeFooter id="dk-website-home-page-footer">
      <ImageHomeFooter src={DKCharacterFooter}></ImageHomeFooter>
    </ConHomeFooter>
  );
}

export default HomeFooter;
