import React from "react";
import styled from "styled-components";
import CommonConstants from "../../../resources/constants/CommonConstants";
import CharacterBearImageResource from "../../../resources/images/3-Dicer/coala.png";
import MyDicerData from "../../../resources/model/Dicer/MyDicerData";

/**
 *
 * @param {Props} props
 * @returns {React.FC}
 */
function MyDicerItem(props) {
  const { data } = props;

  const renderButton = () => {
    if (data?.staked) {
      return <BlueButton onClick={handleUnstakeClick}>Unstake</BlueButton>;
    } else {
      return (
        <ButtonGroup>
          <GreenButton onClick={handleStakeClick}>Stake</GreenButton>
          <BlueButton onClick={handleSellClick}>Sell</BlueButton>
        </ButtonGroup>
      );
    }
  };

  const handleUnstakeClick = () => {
    //TODO: waiting for implements
  };

  const handleStakeClick = () => {
    //TODO: waiting for implements
  };

  const handleSellClick = () => {
    //TODO: waiting for implements
  };

  return (
    <Container>
      <Image src={CharacterBearImageResource} />
      <RomanText>I II IV V V</RomanText>
      <DicerIdText>Dicer #{data?.id}</DicerIdText>
      <DetailText>
        Clone: {data?.cloneCount} Skill: {data?.skillCount}
      </DetailText>
      {renderButton()}
    </Container>
  );
}

export default MyDicerItem;

const Container = styled.div`
  padding: 1.25rem 1.25rem 1rem 1.25rem;
  display: flex;
  flex-direction: column;
  width: fit-content;
  border: 1px solid #f4a623;
  border-radius: 12px;
  color: #f9e7c8;
  background-color: #1c0b09;
  margin-top: 1rem;
  margin-right: 1rem;
  width: 12rem;
`;

const Image = styled.img`
  width: 150px;
  height: 150px;
  object-fit: contain;
  align-self: center;
`;

const RomanText = styled.div`
  color: #ffa300;
  margin: 0.5rem 0;
  align-self: center;
`;

const DicerIdText = styled.div`
  font-weight: bold;
  color: #ffffff;
`;

const DetailText = styled.div`
  font-size: 0.8rem;
  color: #f4d8be;
  margin-bottom: 1rem;
`;

const ButtonGroup = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  gap: 1rem;
`;

const GreenButton = styled.button`
  border: 3px solid #561700;
  border-radius: 0.5rem;
  background-image: linear-gradient(#aaff73, #67ef18, #4cc100);
  transform: skewX(-6deg);
  padding: 0.5rem 1.25rem;
  justify-content: center;
  font-weight: bold;
  color: #4f1304;
  cursor: pointer;
  overflow: hidden;
  min-width: 5rem;
  /* width: max-content; */

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #00000015;
    display: none;
  }

  &:focus::after {
    display: block;
  }

  &:focus {
    box-shadow: 0 0 0 3px #55555580;
  }
`;

const BlueButton = styled(GreenButton)`
  background-image: linear-gradient(#6cbdff, #2291ff, #007ce2);
`;

class Props {
  constructor() {
    /**
     * @type {MyDicerData}
     */
    this.data = {};
  }
}
