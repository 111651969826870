import React from "react";
import SiteLeftMenu from "../../../../../../resources/components/SiteLeftMenu/SiteLeftMenu";
import {
  MarketplaceLeftMenuPage,
  MarketplaceLeftMenuContainer,
} from "./MarketplaceLeftMenu.component";

function MarketplaceLeftMenu(props) {
  const { children, mobileShow } = props;
  return (
    <MarketplaceLeftMenuContainer
      id="dk-website-marketplace-page-dicer-left-menu"
      show={mobileShow}
    >
      <MarketplaceLeftMenuPage>{children}</MarketplaceLeftMenuPage>
    </MarketplaceLeftMenuContainer>
  );
}

export default MarketplaceLeftMenu;
