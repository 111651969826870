import styled from "styled-components";
import CommonConstants from "../../../../../../resources/constants/CommonConstants";

export const MarketplaceDicerPage = styled.div `
  position: relative;
  /* height: 100%; */
  width: 100%;
`;

export const MarketplaceLeftContainer = styled.div `
  position: relative;
  /* height: 100%; */
  width: 100%;
  display: flex;
`;

export const MarketplaceTapSectionOrderItem = styled.div `
  min-width: 300px;
  display: flex;
  flex-direction: row;
  align-self: center;
  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    width: 100%;
    margin-bottom: 20px;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_TABLET_MIN}) and (max-width: ${CommonConstants.MIN_WIDTH_TABLET_MAX}) {
    width: 100%;
    margin-bottom: 20px;
  }
`;

export const MarketplaceTapSectionIconGridDicerContainer = styled.div `
  margin-left: 15px;
  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    display: none;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_TABLET_MIN}) and (max-width: ${CommonConstants.MIN_WIDTH_TABLET_MAX}) {
    display: none;
  }
`;