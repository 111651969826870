import React from "react";
import {
  FLMC_ComingSoonText,
  FLMC_Container,
  FLMC_FarmingButtonContainer,
  FLMC_FarmingButtonIcon,
  FLMC_FarmingButtonText,
} from "./FarmLeftMenu.component";
import FarmingActiveIconResource from "../../../../resources/images/2-Farm/Icon-Farming-Y.png";
import FarmingInactiveIconResource from "../../../../resources/images/2-Farm/Icon-Farming-W.png";
import LotteryActiveIconResource from "../../../../resources/images/2-Farm/Icon-Lottery-Y.png";
import LotteryInactiveIconResource from "../../../../resources/images/2-Farm/Icon-Lottery-W.png";

function FarmLeftMenu() {
  return (
    <FLMC_Container>
      <FLMC_FarmingButtonContainer active>
        <FLMC_FarmingButtonIcon src={FarmingActiveIconResource} />
        <FLMC_FarmingButtonText>Farming</FLMC_FarmingButtonText>
      </FLMC_FarmingButtonContainer>
      <FLMC_FarmingButtonContainer disabled>
        <FLMC_FarmingButtonIcon src={LotteryInactiveIconResource} />
        <FLMC_FarmingButtonText>
          <FLMC_ComingSoonText>Coming Soon</FLMC_ComingSoonText>
          Lottery
        </FLMC_FarmingButtonText>
      </FLMC_FarmingButtonContainer>
    </FLMC_Container>
  );
}

export default FarmLeftMenu;
