import styled from "styled-components";

const Policyen = styled.div`
position: relative;
z-index: 2;
color: white;
top: 120px;
padding: 15px;
padding-bottom: 120px;
`;

const Topic = styled.div`
    font-size: 20px;
    font-weight: bold;
    padding : 10px;
`;

export default function PolicyEN() {
    return (
        
        <Policyen>
            <h2><center>Privacy Policy for Customer</center></h2>
            
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ABX Co., Ltd. recognizes the importance of the protection of your personal data. This Privacy Policy explains our practices regarding the collection, use or disclosure of personal data including other rights of the Data Subjects in accordance with the Personal Data Protection Laws.<br/>

<Topic>Collection of Personal Data</Topic>
We will collect your personal data that receive directly from you as following:<br/>
- your account registration<br/>
- email address<br/>
<Topic>Types of Data Collected</Topic>
Personal data such as name, surname, age, date of birth, nationality, identification card, passport, etc.<br/>
Contact information such as address, telephone number, e-mail address, etc.<br/>
Account details such as username, password, transactions history, etc.<br/>
Technical data such as IP address, Cookie ID, Activity Log, etc.
<Topic>Children</Topic>
If you are under the age of 20 or having legal restrictions, we may collect use or disclose your personal data. We require your parents or guardian to be aware and provide consent to us or allowed by applicable laws. If we become aware that we have collected personal data from children without verification of parental consent, we take steps to remove that information from our servers.
<Topic>Storage of Data</Topic>
We store your personal data as hard copy and soft copy.<br/>
We store your personal data by using the following systems:<br/>
Third-party server service providers outside of Thailand
<Topic>Use of Data</Topic>
We use the collected data for various purposes:<br/>
- To create and manage accounts<br/>
- To improve products, services, or user experiences<br/>
- To share and manage information within organization<br/>
- To gather user’s feedback<br/>
- To comply with our Terms and Conditions
<Topic>Disclosure of Personal Data</Topic>
We may disclose your personal data to the following parties in certain circumstances:<br/>
Organization<br/>
We may disclose your personal data within our organization to provide and develop our products or services. We may combine information internally across the different products or services covered by this Privacy Policy to help us be more relevant and useful to you and others.<br/>
<br/>Service Providers<br/>
We may use service providers to help us provide our services such as payments, marketing and development of products or services. Please note that service providers have their own privacy policy.<br/>
<br/>Business Partners<br/>
In relation with our business partners, we may disclose certain personal data to them in order to coordinate and provide our products or services to you and provide necessary information about the availability of our products or services.<br/>
<br/>Cross-Border Data Transfer<br/>
We may disclose or transfer your personal data to third parties, organizations or servers located in foreign countries. We will take steps and measures to ensure that your personal data is securely transferred, and the receiving parties have an appropriate level of personal data protection standard or as allowed by laws.
<Topic>Data Retention</Topic>
We will retain your personal data for as long as necessary during the period you are a customer or under relationship with us, or for as long as necessary in connection with the purposes set out in this Privacy Policy, unless law requires or permits a longer retention period. We will erase, destroy or anonymize your personal data when it is no longer necessary or when the period lapses.
<Topic>Data Subject Rights</Topic>
Subject to the Personal Data Protection Laws thereof, you may exercise any of these rights in the following:
<br/>
<br/>Withdrawal of consent: <br/>If you have given consent to us to collect, use or disclose your personal data whether before or after the effective date of the Personal Data Protection Laws, you have the right to withdraw such consent at any time throughout the period your personal data available to us, unless it is restricted by laws or you are still under beneficial contract.
<br/>
<br/>Data access: <br/>You have the right to access your personal data that is under our responsibility; to request us to make a copy of such data for you; and to request us to reveal as to how we obtain your personal data.
<br/>
<br/>Data portability: <br/>You have the right to obtain your personal data if we organize such personal data in automatic machine-readable or usable format and can be processed or disclosed by automatic means; to request us to send or transfer the personal data in such format directly to other data controllers if doable by automatic means; and to request to obtain the personal data in such format sent or transferred by us directly to other data controller unless not technically feasible.
<br/>
<br/>Objection: <br/>You have the right to object to collection, use or disclosure of your personal data at any time if such doing is conducted for legitimate interests of us, corporation or individual which is within your reasonable expectation; or for carrying out public tasks.
<br/>
<br/>Data erasure or destruction: <br/>You have the right to request us to erase, destroy or anonymize your personal data if you believe that the collection, use or disclosure of your personal data is against relevant laws; or retention of the data by us is no longer necessary in connection with related purposes under this Privacy Policy; or when you request to withdraw your consent or to object to the processing as earlier described.
<br/>
<br/>Suspension: <br/>You have the right to request us to suspend processing your personal data during the period where we examine your rectification or objection request; or when it is no longer necessary and we must erase or destroy your personal data pursuant to relevant laws but you instead request us to suspend the processing.
<br/>
<br/>Rectification: <br/>You have the right to rectify your personal data to be updated, complete and not misleading.
<br/>
<br/>Complaint lodging: <br/>You have the right to complain to competent authorities pursuant to relevant laws if you believe that the collection, use or disclosure of your personal data is violating or not in compliance with relevant laws.
<br/>You can exercise these rights as the Data Subject by contacting our Data Protection Officer as mentioned below. We will notify the result of your request within 30 days upon receipt of such request. If we deny the request, we will inform you of the reason via SMS, email address, telephone, registered mail (if applicable).
<Topic>Cookies</Topic>
To enrich and perfect your experience, we use cookies or similar technologies to display personalized content, appropriate advertising and store your preferences on your computer. We use cookies to identify and track visitors, their usage of our website and their website access preferences. If you do not wish to have cookies placed on your computer you should set their browsers to refuse cookies before using our website.
<Topic>Data Security</Topic>
We endeavor to protect your personal data by establishing security measures in accordance with the principles of confidentiality, integrity, and availability to prevent loss, unauthorized or unlawful access, destruction, use, alteration, or disclosure including administrative safeguard, technical safeguard, physical safeguard and access controls.
<Topic>Data Breach Notification</Topic>
We will notify the Office of the Personal Data Protection Committee without delay and, where feasible, within 72 hours after having become aware of it, unless such personal data breach is unlikely to result in a risk to the rights and freedoms of you. If the personal data breach is likely to result in a high risk to the rights and freedoms of you, we will also notify the personal data breach and the remedial measures to you without delay through our website, SMS, email address, telephone or registered mail (if applicable).
<Topic>Changes to this Privacy Policy</Topic>
We may change this Privacy Policy from time to time. Any changes of this Privacy Policy, we encourage you to frequently check on our website.
This Privacy Policy was last updated and effective on 3rd June 2023
<Topic>Links to Other Sites</Topic>
The purpose of this Privacy Policy is to offer products or services and use of our website. Any websites from other domains found on our site is subject to their privacy policy which is not related to us.
<Topic>Contact Information</Topic>
If you have any questions about this Privacy Policy or would like to exercise your rights, you can contact us by using the following details:<br/>
Data Controller<br/>
ABX Co., Ltd.<br/>
63/30  , Moo 5, Banmai, Pakgrad, Nonthaburi, 11120<br/>
support@dicekingdom.com<br/>
Data Protection Officer<br/>
chatchavan suriyayanyong<br/>
63/30 , Moo 5, Banmai, Pakgrad, Nonthaburi, 11120<br/>
dicekingdomgame@gmail.com<br/>


        </Policyen>
    )
}