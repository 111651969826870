import React from "react";
import styled from "styled-components";
import { DicerDetail_Absorb } from "../../../../../../../resources/model/Marketplace/DicerDetail";
import MarketplaceDialogAccordion from "./MarketplaceDialogAccordion";

/**
 *
 * @param {Props} props
 * @returns {React.FC}
 */
function MarketplaceDialogAbsorb(props) {
  const { dicerId, dicerImage, items = [] } = props;

  return (
    <MarketplaceDialogAccordion title="Absorb">
      <Grid>
        {items.map((item, index) => (
          <Container key={"cloning_" + index}>
            <Title>Dicer #{dicerId}</Title>
            <Detail>
              Clone : {item.cloneCount} Skill : {item.skillCount}
            </Detail>
            <ImageContainer>
              <Image src={dicerImage} />
            </ImageContainer>
          </Container>
        ))}
      </Grid>
    </MarketplaceDialogAccordion>
  );
}

export default MarketplaceDialogAbsorb;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(auto, 1fr));
  gap: 1rem;
  margin-top: 1rem;
`;

const Container = styled.div`
  width: 5rem;
  border: 2px solid #ffcd83;
  border-radius: 0.6rem;
  padding: 0.5rem 1rem;
  background: #694007;
`;

const Title = styled.div`
  color: #ffffff;
  font-weight: 600;
  font-size: 0.7rem;
`;

const Detail = styled.div`
  color: #ffe3c7;
  font-size: 0.6rem;
`;

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.5rem 0;
`;

const Image = styled.img`
  width: 4rem;
  height: 4rem;
  object-fit: contain;
`;

class Props {
  constructor() {
    this.dicerId = "";
    this.dicerImage = "";
    /**
     * @type {DicerDetail_Absorb[]}
     */
    this.items = [];
  }
}
