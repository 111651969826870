import styled from "styled-components";
import CommonConstants from "../../../../resources/constants/CommonConstants";
import { FPCC_Button } from "../FarmPoolContent/FarmPoolContent.component";
// FPDC = Farm Pool Dialog Component
export const FPDC_Container = styled.div`
  display: ${(props) => (props.visible ? "flex" : "none")};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #00000080;
  border-radius: 0.6rem;
  align-items: center;
  justify-content: center;
  animation: fadeIn 50ms ease-out;
  z-index: 5;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @media (max-width: ${CommonConstants.MIN_WIDTH_DESKTOP}) {
    z-index: 99;
  }
`;

export const FPDC_Dialog = styled.div`
  position: relative;
  width: calc(80% - 4rem);
  height: calc(90% - 4rem);
  background: #f9e7c8;
  border-radius: 0.6rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  animation: fadeZoomIn 150ms ease-out;

  @keyframes fadeZoomIn {
    from {
      opacity: 0;
      transform: scale(0.75);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
`;

export const FPDC_Title = styled.div`
  font-size: 2.5rem;
  font-weight: bold;
  color: #000000;

  @media (max-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XXL}) {
    font-size: 2rem;
  }

  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    font-size: 1.25rem;
  }
`;

export const FPDC_DicerListContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow-y: auto;
  margin-top: 1rem;
  margin-top: 1rem;
  max-height: 65%;

  @media (max-width: ${CommonConstants.MIN_WIDTH_DESKTOP}) {
    max-height: 85%;
  }

  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    max-height: 80%;
  }
`;

export const FPDC_DicerItem = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  margin-right: 0.75rem;
`;

export const FPDC_DicerItemSelected = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  background: #ffa300;
  color: #000000;
  font-weight: 900;
`;

export const FPDC_DicerAvatarContainer = styled.div`
  width: 5rem;
  padding: 2rem 2.5rem;
  background: #67574b;
  border-radius: 2rem;
  border: 6px solid ${(props) => (props.selected ? "#ffa300" : "#f9e7c8")};
`;

export const FPDC_DicerAvatar = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const FPDC_DicerID = styled.div`
  margin-top: 0.3rem;
  color: #8a4829;
  text-align: center;
`;

export const FPDC_ButtonContainer = styled.div`
  position: absolute;
  bottom: 2rem;
  right: 2rem;
  display: grid;
  grid-template-columns: auto auto;
  justify-content: flex-end;
  gap: 1rem;

  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
`;

export const FPDC_SubmitButton = styled(FPCC_Button)`
  max-width: fit-content;
`;

export const FPDC_CancelButton = styled(FPDC_SubmitButton)`
  background: #313a51;
  color: #1d2130;

  &:hover {
    background: #464f6b;
  }
`;
