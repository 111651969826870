import "./App.css";
import "./App.scss";

import Routing from "./routes/Router";
import Header from "./resources/components/Header/Header";
import Footer from "./resources/components/Footer/Footer";

function App() {
  return (
    <div id="dk-website-app-js" className="AppJS">
      {/* <Header></Header>

      <Routing /> */}
      <div className="bubbles">
        <Header></Header>
        <div className="score">
          <Routing />
          <Footer />
        </div>
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>

        <div className="bubble2"></div>
        <div className="bubble2"></div>
        <div className="bubble2"></div>
        <div className="bubble2"></div>
        <div className="bubble2"></div>
        <div className="bubble2"></div>
        <div className="bubble2"></div>
        <div className="bubble2"></div>
        <div className="bubble2"></div>
        <div className="bubble2"></div>

        <div className="bubble3"></div>
        <div className="bubble3"></div>
        <div className="bubble3"></div>
        <div className="bubble3"></div>
        <div className="bubble3"></div>
        <div className="bubble3"></div>
        <div className="bubble3"></div>
        <div className="bubble3"></div>
        <div className="bubble3"></div>
        <div className="bubble3"></div>
      </div>
    </div>
  );
}

export default App;
