import React from "react";
import style from "../style/style";

import ButtonGooglePlay from "../../../../images/1-Home/Button-GooglePlay-Comingsoon.png";
import ButtonAppStore from "../../../../images/1-Home/Button-Appstore-comingsoon.png";
import ButtonWeb from "../../../../images/1-Home/Button-web.png";

function FooterAppDownload() {
  const StyleFooterAppDownloadContainer = style.FooterAppDownloadContainer;
  const StyleFooterAppDownloadAndroid = style.FooterAppDownloadAndroid;
  const StyleFooterAppDownloadIOS = style.FooterAppDownloadIOS;
  const StyleFooterAppDownloadWeb = style.FooterAppDownloadWeb;

  const androidPlay = () => {
    window.open('https://play.google.com/store/apps/details?id=com.dreamdice.dicekingdom&fbclid=IwZXh0bgNhZW0CMTAAAR0WWQyj9veQphXZw-7relSo3Fl_NoptaPWa-LUPDE8Ja7ynf3J5Qws8epQ_aem_ATbWUfMIoJsFd5tBsnOYijNiOfoOw2VpOJ7aOb2wSinkj1IXTkQJCTFc3d3h41mIgC1lQNv4rUXJpKDa_ASaRMHW') 
  }

  const applePlay = () => {
    window.open('https://apps.apple.com/th/app/dice-kingdom/id1136786743?l=th')
  }

  const playNow = () => {
    window.open('https://play.dicekingdom.com/')
  }

  return (
    <StyleFooterAppDownloadContainer>
      <StyleFooterAppDownloadWeb src={ButtonWeb} onClick={playNow}/>
      <StyleFooterAppDownloadIOS src={ButtonAppStore} onClick={applePlay}/>
      <StyleFooterAppDownloadAndroid src={ButtonGooglePlay} onClick={androidPlay}/>
    </StyleFooterAppDownloadContainer>
  );
}

export default FooterAppDownload;
