import React from "react";
import IconToken from "../../../../resources/images/2-Farm/Icon-Token.png";
import FarmItem from "../../../../resources/model/Farm/FarmItem";
import NumberFormat from "../../../../utils/NumberFormat";
import {
  FPHC_ContentContainer,
  FPHC_DetailContainer,
  FPHC_DetailIcon,
  FPHC_DetailText,
  FPHC_Header,
  FPHC_HeaderIcon,
  FPHC_HighlightContainer,
  FPHC_HighlightText,
  FPHC_HightlightTextDetail,
  FPHC_SubTitle,
  FPHC_Title,
} from "./FarmPoolHeader.component";

/**
 *
 * @param {Props} props
 * @returns {React.FC}
 */
function FarmPoolHeader(props) {
  const { data, expanded } = props;

  return (
    <FPHC_Header>
      <FPHC_ContentContainer row itemCenter>
        <FPHC_HeaderIcon src={IconToken} />
        <FPHC_ContentContainer>
          <FPHC_Title>{data?.name || "DK-Title"}</FPHC_Title>
          <FPHC_SubTitle>Earned {data?.earnType}</FPHC_SubTitle>
          <FPHC_SubTitle>Deposit Fee {data?.depositFee}%</FPHC_SubTitle>
        </FPHC_ContentContainer>
      </FPHC_ContentContainer>
      <FPHC_ContentContainer>
        <FPHC_SubTitle>Earned</FPHC_SubTitle>
        <FPHC_HighlightContainer>
          <FPHC_HighlightText>{data?.earnValue}</FPHC_HighlightText>
          <FPHC_HightlightTextDetail>
            ~{NumberFormat.toUSD(data?.earnValueAround)}
          </FPHC_HightlightTextDetail>
        </FPHC_HighlightContainer>
      </FPHC_ContentContainer>
      <FPHC_ContentContainer>
        <FPHC_SubTitle>Deposited LP</FPHC_SubTitle>
        <FPHC_HighlightContainer>
          <FPHC_HighlightText>{data?.depositLP}</FPHC_HighlightText>
          <FPHC_HightlightTextDetail>
            ~{NumberFormat.toUSD(data?.depositLPAround)}
          </FPHC_HightlightTextDetail>
        </FPHC_HighlightContainer>
      </FPHC_ContentContainer>
      <FPHC_ContentContainer>
        <FPHC_SubTitle>Total APR</FPHC_SubTitle>
        <FPHC_HighlightText>{data?.totalAPR}%</FPHC_HighlightText>
      </FPHC_ContentContainer>
      <FPHC_ContentContainer>
        <FPHC_SubTitle>TVL</FPHC_SubTitle>
        <FPHC_HighlightText>{data?.tvl}</FPHC_HighlightText>
      </FPHC_ContentContainer>
      <FPHC_DetailContainer expanded={expanded}>
        <FPHC_DetailText>Details</FPHC_DetailText>
        <FPHC_DetailIcon className="material-icons" expanded={expanded}>
          expand_less
        </FPHC_DetailIcon>
      </FPHC_DetailContainer>
    </FPHC_Header>
  );
}

export default FarmPoolHeader;

class Props {
  constructor() {
    /**
     * @type {FarmItem}
     */
    this.data = {};
    this.expanded = false;
    this.onToggleExpandCollapse = () => {};
  }
}
