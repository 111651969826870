import React from "react";
import style from "./style/style";

const StyleSiteButtonYellow = style.SiteButtonYellow;

function SiteButtonYellow(props) {
  const { children, ...rest } = props;

  return (
    <StyleSiteButtonYellow
      active
      variant="contained"
      color="primary"
      disableRipple
      {...rest}
    >
      {children}
    </StyleSiteButtonYellow>
  );
}

export default SiteButtonYellow;
