class MyDicerData {
  constructor() {
    this.id = "";
    this.cloneCount = 0;
    this.skillCount = 0;
    this.staked = false;
  }
}

export default MyDicerData;
