import React from "react";
import styled from "styled-components";
import CommonConstants from "../../../../../../../resources/constants/CommonConstants";
import { DicerDetail_SaleHistory } from "../../../../../../../resources/model/Marketplace/DicerDetail";

/**
 *
 * @param {Props} props
 * @returns {React.FC}
 */
function MarketplaceDialogSaleHistory(props) {
  const { saleHistories = [] } = props;
  return (
    <Container>
      <Title>Sale history</Title>
      <Content>
        {saleHistories.map((item, index) => (
          <Row key={"saleHistory_" + index}>
            <Col>
              <FieldName>Buyer</FieldName>
              <FieldValue>{item.buyer}</FieldValue>
            </Col>
            <Col>
              <FieldName>Seller</FieldName>
              <FieldValue>{item.seller}</FieldValue>
            </Col>
            <Col>
              <Price>{item.price}BNB</Price>
              <Date>{item.date}</Date>
            </Col>
          </Row>
        ))}
      </Content>
    </Container>
  );
}

export default MarketplaceDialogSaleHistory;

const Container = styled.div`
  margin-top: 1rem;
`;

const Title = styled.div`
  font-weight: bold;
`;

const Content = styled.div``;

const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
  align-items: center;
  gap: 1rem;
  min-height: 5rem;
  padding: 1rem;
  background: #af8068;

  &:first-child {
    border-top-left-radius: 0.6rem;
    border-top-right-radius: 0.6rem;
  }

  &:last-child {
    border-bottom-left-radius: 0.6rem;
    border-bottom-right-radius: 0.6rem;
  }

  &:not(:last-child) {
    margin-bottom: 2px;
  }

  @media (max-width: ${CommonConstants.MIN_WIDTH_DESKTOP}) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const FieldName = styled.div`
  color: #4e0904;
`;

const FieldValue = styled.div`
  color: #fadcc1;
`;

const Price = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  color: #ffffff;
`;

const Date = styled.div`
  font-size: 0.8rem;
  color: #4e0904;
`;

class Props {
  constructor() {
    /**
     * @type {DicerDetail_SaleHistory[]}
     */
    this.saleHistories = [];
  }
}
