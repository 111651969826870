import React, { useState, useEffect } from "react";
import {
  MarketplaceHeaderTapPageMbile,
  MarketplaceHeaderTapSelectImage,
  MarketplaceHeaderTapSelectImageContainer,
  MarketplaceHeaderTapFilterContainer,
  MarketplaceHeaderTapBtnFilter,
} from "./MarketplaceHeaderTapMobile.component";
import IconDicer from "../../../../../../resources/images/4-Marketplace/Icon-Dicer.png";
import IconEquipment from "../../../../../../resources/images/4-Marketplace/Icon-Equipment.png";
import SiteSelectImage from "../../../../../../resources/components/SiteSelect/SiteSelectImage";

function MarketplaceHeaderTapMobile(props) {

  const {
    onClickFilter,
    dataSource = [],
    indexActive = 0,
    onChange = (value) => {},
  } = props;

  const formatDataSource = () => {
    return dataSource.map((item, index) => ({
      value: index,
      display: item.name,
      image: item.icon,
    }));
  };

  return (
    <MarketplaceHeaderTapPageMbile id="dk-website-marketplace-page-dicer-header-tap-mobile">
      <MarketplaceHeaderTapSelectImageContainer>
        <MarketplaceHeaderTapSelectImage
          width="150"
          source={formatDataSource()}
          value={indexActive}
          onChange={(e) => onChange(e.target.value)}
        />
      </MarketplaceHeaderTapSelectImageContainer>
      <MarketplaceHeaderTapFilterContainer>
        <MarketplaceHeaderTapBtnFilter onClick={onClickFilter}>
          Filter
        </MarketplaceHeaderTapBtnFilter>
      </MarketplaceHeaderTapFilterContainer>
    </MarketplaceHeaderTapPageMbile>
  );
}

export default MarketplaceHeaderTapMobile;
