import React, { useState } from "react";
import styled from "styled-components";
import MarketplaceDicer from "./components/MarketplaceDicer/MarketplaceDicer";
import { MarketplacePage } from "./resources/style/style";

function Marketplace() {
  return (
    <MarketplacePage id="dk-website-marketplace-page">    	
      <MarketplaceDicer>
      </MarketplaceDicer>
    </MarketplacePage>
  );
}

export default Marketplace;
