import styled from "styled-components";
import CommonConstants from "../../../../resources/constants/CommonConstants";

export const OverviewMarketPage = styled.div`
  /* min-height: 77vh; */
  position: relative;
  margin-top: 5rem;
  padding: 2rem 4rem 2rem 4rem;
  z-index: 2;

  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
  margin-top: 3rem;
  padding: 1rem;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_TABLET_MIN}) and (max-width: ${CommonConstants.MIN_WIDTH_TABLET_MAX}) {
  margin-top: 3rem;
  padding: 1rem;
  }

`;

export const OverviewMarketLatestSalesAndLatestListingsContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 0px 2rem;

  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    flex-direction: column;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_TABLET_MIN}) and (max-width: ${CommonConstants.MIN_WIDTH_TABLET_MAX}) {
    flex-direction: column;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP}) and (max-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XL}) {
    flex-direction: column;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XL}) {
    flex-direction: row;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XXL}) {
    flex-direction: row;
  }


  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP_2K}) {
    flex-direction: row;
  }
  
`;
