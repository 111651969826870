import React from "react";

import style from "../../style/style";
import HeaderMenuButton from "../HeaderMenuButton";
import HeaderMenuText from "../HeaderMenuText";

function HeaderLogo() {
  const HeaderMenu = style.HeaderMenu;

  return (
    <HeaderMenu id="dk-website-header-menu">
      <HeaderMenuText></HeaderMenuText>
      <HeaderMenuButton></HeaderMenuButton>
    </HeaderMenu>
  );
}

export default HeaderLogo;
