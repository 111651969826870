import React from "react";
import {
  MarketplaceHeaderTapPage,
  MarketplaceHeaderTapItem,
  MarketplaceHeaderTapItemImage,
  MarketplaceHeaderTapItemText,
  MarketplaceHeaderTapItemTextComingSoon,
} from "./MarketplaceHeaderTap.component";
import IconDicer from "../../../../../../resources/images/4-Marketplace/Icon-Dicer.png";
import IconEquipment from "../../../../../../resources/images/4-Marketplace/Icon-Equipment.png";

function MarketplaceHeaderTap(props) {
  const { data, indexActive , onClickItem } = props;

  const _rendeerMarketplaceHeaderTapItem = () => {
    return data.map((item, index) => (
      <MarketplaceHeaderTapItem
        {...index === indexActive ? {active : true} : {}}
        onClick={(e) => onClickItem(e , index)}
      >
        <MarketplaceHeaderTapItemImage src={item.icon} />
        <MarketplaceHeaderTapItemText>
          {_rendeerMarketplaceHeaderTapComingSoon(item.isComingSoon)}
          {item.name}
        </MarketplaceHeaderTapItemText>
      </MarketplaceHeaderTapItem>
    ));
  };

  const _rendeerMarketplaceHeaderTapComingSoon = (isComingSoon) => {
    if (isComingSoon) {
      return (
        <MarketplaceHeaderTapItemTextComingSoon>
          Coming Soon
        </MarketplaceHeaderTapItemTextComingSoon>
      );
    } else {
      return null;
    }
  };

  return (
    <MarketplaceHeaderTapPage id="dk-website-marketplace-page-dicer-header-tap">

      {_rendeerMarketplaceHeaderTapItem()}

      {/* <MarketplaceHeaderTapItem active>
        <MarketplaceHeaderTapItemImage src={IconDicer} />
        <MarketplaceHeaderTapItemText>Dicer</MarketplaceHeaderTapItemText>
      </MarketplaceHeaderTapItem>

      <MarketplaceHeaderTapItem>
        <MarketplaceHeaderTapItemImage src={IconEquipment} />
        <MarketplaceHeaderTapItemText>
          <MarketplaceHeaderTapItemTextComingSoon>
            Coming Soon
          </MarketplaceHeaderTapItemTextComingSoon>
          Equipment
        </MarketplaceHeaderTapItemText>
      </MarketplaceHeaderTapItem> */}
    </MarketplaceHeaderTapPage>
  );
}

export default MarketplaceHeaderTap;
