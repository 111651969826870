import React from "react";
import { Switch, Route, BrowserRouter } from "react-router-dom";
import DemoPlay from "../pages/DemoPlay/DemoPlay";
import Error404 from "../pages/Error404/Error404";
import PolicyTH from "../pages/policy-th";
import PolicyEN from "../pages/policy-en";

import ConfigMenu from "../resources/constants/ConfigMenu";

function Router(props) {
  const _renderRoute = () => {
    let menuList = ConfigMenu.MENU_LIST.filter((item, index) => {
      return item.MENU_ACTIVE;
    });

    return menuList.map((item, index) => {
      return (
        <Route
          exact
          path={item.MENU_ROUTER}
          component={item.MENU_COMPONENT}
          key={"Route" + index}
        />
      );
    });
  };

  return (
    <BrowserRouter basename={ConfigMenu.MENU_BASE_PATH}>
      <Route
        render={({ location }) => {
          return (
            <Switch location={location}>
              {_renderRoute()}
              <Route exact path="/demoplay" component={DemoPlay} />
              <Route path="/policy-th" component={PolicyTH} />
              <Route path="/policy-en" component={PolicyEN} />
              <Route component={Error404} />
            </Switch>
          );
        }}
      />
    </BrowserRouter>
  );
}

export default Router;
