import GameIconResource from "../../../resources/images/5-DemoPlay/Icon-Game.png";
import NewsIconResource from "../../../resources/images/5-DemoPlay/Icon-News.png";
import VideoIconResource from "../../../resources/images/5-DemoPlay/Icon-Video.png";
import LibraryIconResource from "../../../resources/images/5-DemoPlay/Icon-Library.png";

const DemoPlayMenuMaster = {
  GAME: {
    NAME: "Game",
    ICON: GameIconResource,
  },
  NEWS: {
    NAME: "News",
    ICON: NewsIconResource,
  },
  VIDEO: {
    NAME: "Video",
    ICON: VideoIconResource,
  },
  LIBRARY: {
    NAME: "Library",
    ICON: LibraryIconResource,
  },
};

export default DemoPlayMenuMaster;
