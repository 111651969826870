class CapsuleDetail {
  constructor() {
    this.id = "";
    this.ownerId = "";
    this.name = "";
    this.image = "";
    this.price = 0;
    this.discount = 0;
    /**
     * @type {CapsuleDetail_About}
     */
    this.about = {};
    /**
     * @type {CapsuleDetail_SaleHistory[]}
     */
    this.saleHistories = [];
    /**
     * @type {CapsuleDetail_Clone[]}
     */
    this.clones = [];
  }
}

export class CapsuleDetail_About {
  constructor() {
    this.currentStage = 0;
  }
}

export class CapsuleDetail_SaleHistory {
  constructor() {
    this.buyer = "";
    this.seller = "";
    this.price = 0;
    this.date = "";
  }
}

export class CapsuleDetail_Clone {
  constructor() {
    this.name = "";
    this.cloneCount = 0;
    this.skillCount = 0;
  }
}

export default CapsuleDetail;
