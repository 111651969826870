import React from "react";
import styled from "styled-components";
import CommonConstants from "../../../../../../../resources/constants/CommonConstants";
import { DicerDetail_Part } from "../../../../../../../resources/model/Marketplace/DicerDetail";

/**
 *
 * @param {Props} props
 * @returns {React.FC}
 */
function MarketplaceDialogPart(props) {
  const { parts = [] } = props;
  return (
    <Container>
      <Title>Parts</Title>
      <Content>
        <Row>
          {parts.map((item, index) => (
            <Col key={"part_" + index}>
              <PartImage src={item.image} />
              <PartDetail>
                <PartName>{item.name}</PartName>
                <PartPlusDetail>
                  {`${item.plusName} 
                  +${item.plusValue?.toLocaleString()}`}
                </PartPlusDetail>
              </PartDetail>
            </Col>
          ))}
        </Row>
      </Content>
    </Container>
  );
}

export default MarketplaceDialogPart;

const Container = styled.div`
  margin-top: 1rem;
`;

const Title = styled.div`
  font-weight: bold;
`;

const Content = styled.div`
  background: #af8068;
  border-radius: 0.6rem;
  padding: 1.5rem 2rem;
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;

  @media (max-width: ${CommonConstants.MIN_WIDTH_DESKTOP}) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const Col = styled.div`
  display: flex;
  flex-direction: row;
`;

const PartImage = styled.img`
  width: 4rem;
  height: 4rem;
  object-fit: contain;
`;

const PartDetail = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
`;

const PartName = styled.div`
  font-weight: bold;
  color: #ffffff;
`;

const PartPlusDetail = styled.div`
  font-size: 0.8rem;
  color: #fadcc1;
`;

class Props {
  constructor() {
    /**
     * @type {DicerDetail_Part[]}
     */
    this.parts = [];
  }
}
