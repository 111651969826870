import React from "react";
import SloganText from "../../../../resources/images/1-Home/Slogan-Text.png";
import { ConHomeHighlight, ImgHighlight } from "./HomeHighlight.component";
import styled from "styled-components";
import css from "./HomeHighlight.css"

const AddressText = styled.div`
margin-top: 10px;
margin-left: 30px;
vertical-align:middle;
color: white;
font-weight: bold;
font-size: 20px;
`
const AddressContainer = styled.div`
  padding: 10px;
  width: 100%;
  height: 100px;
  @media (max-width: 600px) {
    height: 180px;
  }
`

const Address_img = styled.div`
  margin-left: 10px;
  vertical-align:middle;
  display: inline-block;
  background-image: url('${props=>props.img}');
  background-repeat: no-repeat;
  background-size: contain;
  height: 100px;
  width: 300px;
  max-width: 100%;
  max-height: 140px;

  @media (max-width: 600px) {
    height: 60px;
  }
`

const CopyImg = styled.img`
  display: inline-block;
  height: auto;
  cursor: pointer;
`

function copy (addr) {
  navigator.clipboard.writeText(addr);
}

function HomeHighlight() {
  return (
    <div>
    <ConHomeHighlight id="dk-website-home-page-highlight">
      <ImgHighlight src={SloganText}></ImgHighlight>
      <AddressContainer>
        
        <Address_img img="/dk-address.png">
          <AddressText>
            <a class="bkc-link" target="_blank" href="https://www.bkcscan.com/address/0x8E3C2B00eFEBB64a6B775c451197a9dbA1077967/transactions">0x8E3C...77967</a>&nbsp;&nbsp;
            <div class="tooltip">
              <CopyImg src="/icon-copy.png" width={30} onClick={()=>copy('0x8E3C2B00eFEBB64a6B775c451197a9dbA1077967')}/>
              <span class="tooltiptext">Copy</span>
            </div>
          </AddressText>
          
        </Address_img>
        
        <Address_img img="/km-address.png">
          <AddressText>
          <a class="bkc-link" target="_blank" href="https://www.bkcscan.com/address/0xB810bC46B200c8024EE63071691D84f0fd111a9B/transactions">0xB810...11a9B</a>&nbsp;&nbsp;
          <div class="tooltip">
            <CopyImg src="/icon-copy.png" width={30} onClick={()=>copy('0xB810bC46B200c8024EE63071691D84f0fd111a9B')}/>
            <span class="tooltiptext">Copy</span>
          </div>
          </AddressText>
        </Address_img>
        
        <Address_img img="/scp-address.png">
          <AddressText>
          <a class="bkc-link" target="_blank" href="https://www.bkcscan.com/address/0xf482B77FbE4076b18e49E74ACda79789C84d16cc/transactions">0xf482...d16cc</a>
          &nbsp;&nbsp;
          <div class="tooltip">
            <CopyImg src="/icon-copy.png" width={30} onClick={()=>copy('0xf482B77FbE4076b18e49E74ACda79789C84d16cc')}/>
            <span class="tooltiptext">Copy</span>
          </div>
          </AddressText>
        </Address_img>
        
      </AddressContainer>
    </ConHomeHighlight>
    
    </div>
  );
}

export default HomeHighlight;
