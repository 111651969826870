import React from "react";
import SiteContainer from "../SiteContainer/SiteContainer";
import FooterAppDownload from "./resources/components/FooterAppDownload";
import FooterContactUs from "./resources/components/FooterContactUs";
import FooterSocial from "./resources/components/FooterSocial";
import style from "./resources/style/style";

function Footer() {
  const StyleFooter = style.Footer;
  const StyleFooterContainer = style.FooterContainer;

  return (
    <StyleFooter id="dk-website-footer">
      <StyleFooterContainer>
        <FooterContactUs></FooterContactUs>
        <FooterSocial></FooterSocial>
        <FooterAppDownload></FooterAppDownload>
      </StyleFooterContainer>
    </StyleFooter>
  );
}

export default Footer;
