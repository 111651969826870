import React from "react";
import style from "./style/style";

const StyleSiteTextH1 = style.SiteTextH1;

function SiteTextH1(props) {
  const { children, ...rest } = props;

  return <StyleSiteTextH1 {...rest}>{children}</StyleSiteTextH1>;
}

export default SiteTextH1;
