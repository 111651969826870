export default {
    URL_VIDEO_HOME: "_HEZXvqaydc",

    URL_TWITTER: "https://twitter.com/dicekingdomgame",
    URL_FACEBOOK: "https://www.facebook.com/dicekingdom",
    URL_TELEGRAM: "https://t.me/dicekingdomgame",
    URL_MEDIUM: "https://medium.com/@dicekingdomgame",
    URL_DISCORD: "https://discord.gg/NpVYzyX",
    URL_YOUTUBE: "https://www.youtube.com/channel/UCxI6IbjJFpE20gs7q6vLamA",
    URL_IG: "#",
}