import React from "react";
import style from "./style/style";

const StyleSiteCheckbox = style.SiteCheckbox;
const StyleSiteCheckboxSpanImage = style.SiteCheckboxSpanImage;
const StyleSiteCheckboxImage = style.SiteCheckboxImage;
const StyleSiteCheckboxImageImg = style.SiteCheckboxImageImg;
const StyleSiteContainerCheckboxImage = style.SiteContainerCheckboxImage;


function SiteCheckboxImage(props) {
  const { value, checked, image, onChange } = props;

  return (
    <StyleSiteContainerCheckboxImage onClick={onChange} >

      <StyleSiteCheckboxImage checked={checked}/>
      <StyleSiteCheckboxImageImg src={image} />
      {/* <StyleSiteCheckboxSpanImage>{value}</StyleSiteCheckboxSpanImage> */}
    </StyleSiteContainerCheckboxImage>
  );
}

export default SiteCheckboxImage;
