import React from "react";
import _ from "lodash";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import {
  ConHomeFeatures,
  ImgHomeOverview,
  ContainerFeatures,
  ContainerFeaturesMobile,
} from "./HomeOverview.component.js";
// import MenuFeatures from "../../../../resources/images/1-Home/Menu-Features.png";
import Overview from "../../../../resources/images/1-Home/Menu-Overview.png";
import Thumbnail from "../../../../resources/components/Thumbnail/Thumbnail";
// import FeaturesConstants from "../../../../resources/constants/FeaturesConstants";
import OverviewConstants from "../../../../resources/constants/OverviewConstants.js";

const ThumbnailImage = styled.img`
  width: 90%;
  border-radius: 10px 10px 10px 10px;
  border-style: solid;  
  border-color: #FFA00D;
  /* background: #fff1ca; */
  min-height: 200px;
  box-shadow: 0px 0px 5px 5px rgba(39, 32, 24, 0.514);
  margin-bottom:20px;
`;

function HomeFeatures() {
  const listFeatures = OverviewConstants;

  const _renderGridMain = () => {
    let chunkListFeatures = _.chunk(listFeatures, 3);

    return chunkListFeatures.map((item, index) => {
      return <Grid container>{_renderGridItem(item)}</Grid>;
    });
  };

  const _renderGridItem = (list) => {
    return list.map((item, index) => {
      return (
        <Grid item xs={4}>
            
          <ThumbnailImage
            key={"_renderGridItem" + index}
            // title={item.title}
            // detail={item.detail}
            src={item.image}
          />
        </Grid>
      );
    });
  };

  const _renderGridMainMobile = () => {
    let chunkListFeatures = _.chunk(listFeatures, 3);

    return chunkListFeatures.map((item, index) => {
      return <Grid container>{_renderGridItemMobile(item)}</Grid>;
    });
  };

  const _renderGridItemMobile = (list) => {
    return list.map((item, index) => {
      return (
        <Grid item xs={12}>
          <ThumbnailImage
            key={"_renderGridItemMobile" + index}
            // title={item.title}
            // detail={item.detail}
            src={item.image}
          />
        </Grid>
      );
    });
  };

  return (
    <ConHomeFeatures id="dk-website-home-page-features">
      <ImgHomeOverview src={Overview}></ImgHomeOverview>
      <ContainerFeatures>{_renderGridMain()}</ContainerFeatures>
      <ContainerFeaturesMobile>
        {_renderGridMainMobile()}
      </ContainerFeaturesMobile>
    </ConHomeFeatures>
  );
}

export default HomeFeatures;
