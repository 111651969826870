
import React from "react";
import style from "./style/style";

const StyleSiteButtonOutlineDoubleRadius = style.SiteButtonOutlineDoubleRadius;

function SiteButtonOutlineDoubleRadius(props) {
  const { children, ...rest } = props;

  return (
    <StyleSiteButtonOutlineDoubleRadius
      active
      variant="contained"
      color="primary"
      disableRipple
      {...rest}
    >
      {children}
    </StyleSiteButtonOutlineDoubleRadius>
  );
}

export default SiteButtonOutlineDoubleRadius;
