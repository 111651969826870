import styled from "styled-components";
import CommonConstants from "../../../../constants/CommonConstants";
import FooterBar from "../../../../images/1-Home/Footer-Bar.png";
import SiteContainer from "../../../SiteContainer/SiteContainer";
import SiteTextH1 from "../../../Text/SiteTextH1";
import SiteTextH3 from "../../../Text/SiteTextH3";

const Footer = styled.div`
  background-image: url(${FooterBar});
  background-position: top;
  background-repeat: no-repeat;
  background-size: 100vw 100%;
  /* min-height: 210px; */
  z-index: 2;
  position: relative;
  padding-bottom: 20px;

  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    background-size: auto 100%;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_TABLET_MIN}) and (max-width: ${CommonConstants.MIN_WIDTH_TABLET_MAX}) {
    background-size: auto 100%;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP}) and (max-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XL}) {
    background-size: auto 100%;
  }
`;

const FooterContainer = styled(SiteContainer)`
  display: flex;
  padding-top: 90px;

  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    display: block;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_TABLET_MIN}) and (max-width: ${CommonConstants.MIN_WIDTH_TABLET_MAX}) {
    display: block;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP}) and (max-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XL}) {
    display: block;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XL}) {
    /* padding: 0px 40px 0px 40px; */
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XXL}) {
    /* padding: 0px 40px 0px 40px; */
  }
`;

const FooterContactUs = styled.div`
  align-self: center;
  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    align-items: center;
    text-align: center;
    justify-content: center;
    justify-items: center;
    margin-bottom: 20px;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_TABLET_MIN}) and (max-width: ${CommonConstants.MIN_WIDTH_TABLET_MAX}) {
    align-items: center;
    text-align: center;
    justify-content: center;
    justify-items: center;
    margin-bottom: 20px;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP}) and (max-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XL}) {
    align-items: center;
    text-align: center;
    justify-content: center;
    justify-items: center;
    margin-bottom: 20px;
  }
`;

const FooterContactUsTitle = styled(SiteTextH1)`
  color: #fff;
`;

const FooterContactUsText = styled(SiteTextH3)`
  font-weight: normal;
  color: #ffecd1;
`;

const FooterSocial = styled.div`
  align-items: center;
  text-align: center;
  justify-content: center;
  justify-items: center;
  column-gap: 30px;
  display: flex;
  flex-grow: 1;
  /* padding: 20px; */
  /* padding-left: 250px; */

  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    padding-left: 0px;
    margin-bottom: 20px;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_TABLET_MIN}) and (max-width: ${CommonConstants.MIN_WIDTH_TABLET_MAX}) {
    padding-left: 0px;
    margin-bottom: 20px;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP}) and (max-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XL}) {
    padding-left: 0px;
    margin-bottom: 20px;
  }
`;

const FooterSocialICon = styled.img`
  cursor: pointer;
  width: 45px;
  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    width: 30px;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_TABLET_MIN}) and (max-width: ${CommonConstants.MIN_WIDTH_TABLET_MAX}) {
    width: 30px;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP}) and (max-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XL}) {
    width: 30px;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XL}) {
    width: 30px;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XXL}) {
    width: 42px;
  }
`;

const FooterSocialFacebook = styled(FooterSocialICon)``;
const FooterSocialTwitter = styled(FooterSocialICon)``;
const FooterSocialIG = styled(FooterSocialICon)``;
const FooterSocialMedium = styled(FooterSocialICon)``;
const FooterSocialYoutube = styled(FooterSocialICon)``;
const FooterSocialDiscord = styled(FooterSocialICon)``;
const FooterSocialTelegram = styled(FooterSocialICon)``;

const FooterAppDownloadContainer = styled.div`
  align-items: center;
  text-align: center;
  justify-content: center;
  justify-items: center;
  margin-top: 20px;

  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    padding-left: 0px;
    padding-bottom: 30px;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_TABLET_MIN}) and (max-width: ${CommonConstants.MIN_WIDTH_TABLET_MAX}) {
    padding-left: 0px;
    padding-bottom: 30px;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP}) and (max-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XL}) {
    padding-left: 0px;
    padding-bottom: 30px;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XL}) {
    padding-left: 10px;
    margin-top: 20px;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XXL}) {
  }
`;

const FooterAppDownload = styled.img`
  cursor: pointer;
  height: 45px;
  margin: 10px;
`;

const FooterAppDownloadAndroid = styled(FooterAppDownload)``;
const FooterAppDownloadIOS = styled(FooterAppDownload)``;
const FooterAppDownloadWeb = styled(FooterAppDownload)``;

export default {
  Footer: Footer,
  FooterContainer: FooterContainer,

  FooterContactUs: FooterContactUs,
  FooterContactUsTitle: FooterContactUsTitle,
  FooterContactUsText: FooterContactUsText,

  FooterSocial: FooterSocial,
  FooterSocialFacebook: FooterSocialFacebook,
  FooterSocialTwitter: FooterSocialTwitter,
  FooterSocialIG: FooterSocialIG,
  FooterSocialYoutube: FooterSocialYoutube,
  FooterSocialMedium: FooterSocialMedium,
  FooterSocialDiscord: FooterSocialDiscord,
  FooterSocialTelegram: FooterSocialTelegram,

  FooterAppDownloadContainer: FooterAppDownloadContainer,
  FooterAppDownloadAndroid: FooterAppDownloadAndroid,
  FooterAppDownloadIOS: FooterAppDownloadIOS,
  FooterAppDownloadWeb: FooterAppDownloadWeb,
};
