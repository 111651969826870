import React, { useState, useEffect } from "react";
import styled from "styled-components";
import _ from "lodash";
import GridViewRoundedIcon from "@mui/icons-material/GridViewRounded";
import ViewListRoundedIcon from "@mui/icons-material/ViewListRounded";
import MarketplaceFilterTap from "./components/MarketplaceFilterTap/MarketplaceFilterTap";
import MarketplaceHeaderTap from "./components/MarketplaceHeaderTap/MarketplaceHeaderTap";
import MarketplaceHeaderTapMobile from "./components/MarketplaceHeaderTapMobile/MarketplaceHeaderTapMobile";
import MarketplaceLeftMenu from "./components/MarketplaceLeftMenu/MarketplaceLeftMenu";
import {
  MarketplaceDicerPage,
  MarketplaceLeftContainer,
  MarketplaceTapSectionIconGridDicerContainer,
  MarketplaceTapSectionOrderItem,
} from "./resources/style/style";
import SiteButtonGroupRadio from "../../../../resources/components/SiteButton/SiteButtonGroupRadio";
import MarketplaceFilter from "./components/MarketplaceFilter/MarketplaceFilter";
import MarketplaceSection from "./components/MarketplaceSection/MarketplaceSection";
import MarketplaceSectionTap from "./components/MarketplaceSectionTap/MarketplaceSectionTap";
import SiteSelect from "../../../../resources/components/SiteSelect/SiteSelect";
import MarketplaceDicerItem from "./components/MarketplaceDicerItem/MarketplaceDicerItem";

import MockupMarketplaceGeneralFilter from "./mockup/marketplaceGeneralFilter.mockup.json";
import MockupMarketplaceStatsFilter from "./mockup/marketplaceStatsFilter.mockup.json";
import MarketplaceCardsFilter from "./mockup/marketplaceCardsFilter.mockup.json";
import MarketplaceEquipmentFilter from "./mockup/marketplaceEquipmentFilter.mockup.json";
import MarketplaceConsumableFilter from "./mockup/marketplaceConsumableFilter.mockup.json";
import MarketplaceEtcFilter from "./mockup/marketplaceEtcFilter.mockup.json";
import MockupMarketplaceDicer from "./mockup/marketplaceDicer.mockup.json";
import MockupMarketplaceEquipment from "./mockup/marketplaceEquipment.mockup.json";
import MockupMarketplaceConsumable from "./mockup/marketplaceConsumable.mockup.json";
import MockupMarketplaceEtc from "./mockup/marketplaceEtc.mockup.json";
import MarketplaceDialog from "./components/MarketplaceDialog/MarketplaceDialog";
import SiteButtonGroupRadioIcon from "../../../../resources/components/SiteButton/SiteButtonGroupRadioIcon";

import DicerFilter from "../../../../resources/model/Marketplace/DicerFilter.js";
import DicerSearchResult from "../../../../resources/model/Marketplace/DicerSearchResult.js";

import IconDicer from "../../../../resources/images/4-Marketplace/Icon-Dicer.png";
import IconEquipment from "../../../../resources/images/4-Marketplace/Icon-Equipment.png";

const DemoText = styled.div`
  font-size: 40px;
  color: white;
  text-align: center;
`;

function MarketplaceDicer() {
  /**
   * @type {String, Function]}
   */
  const [titlePageMarketplaceDicer, setTitlePageMarketplaceDicer] =
    useState("");
  /**
   * @type {String[], Function]}
   */
  const [tapFilterList, setTapFilterList] = useState([
    "General",
    "Stats",
    "Cards",
  ]);

  /**
   * @type {Number, Function]}
   */
  const [activeFilterTap, setActiveFilterTap] = useState(0);

  /**
   * @type {Number, Function]}
   */
  const [activeSectionDicerTap, setActiveSectionDicerTap] = useState(0);

  /**
   * @type {String[], Function]}
   */
  const [tapSectionDicer, setTapSectionDicer] = useState([
    "All",
    "For Sale",
    "Sold",
  ]);

  /**
   * @type {React.Component[], Function]}
   */
  const [tapSectionIconGridDicer, setTapSectionIconGridDicer] = useState([
    <GridViewRoundedIcon />,
    <ViewListRoundedIcon />,
  ]);

  /**
   * @type {[Number, Function]}
   */
  const [activeSectionIconGridDicerTap, setActiveSectionIconGridDicerTap] =
    useState(0);

  /**
   * @type {[DicerFilter[], Function]}
   */
  const [generalFilterList, setGeneralFilterList] = useState([]);

  /**
   * @type {[DicerFilter[], Function]}
   */
  const [statsFilterList, setStatsFilterList] = useState([]);

  /**
   * @type {[DicerFilter[], Function]}
   */
  const [cardsFilterList, setCardsFilterList] = useState([]);

  const [equipmentFilterList, setEquipmentFilterList] = useState([]);
  const [consumableFilterList, setConsumableFilterList] = useState([]);
  const [etcFilterList, setEtcFilterList] = useState([]);

  /**
   * @type {[DicerSearchResult[], Function]}
   */
  const [originalMarketplaceDicerList, setOriginalMarketplaceDicerList] =
    useState([]);

  const [
    originalMarketplaceEquipmentList,
    setOriginalMarketplaceEquipmentList,
  ] = useState([]);

  const [
    originalMarketplaceConsumableList,
    setOriginalMarketplaceConsumableList,
  ] = useState([]);

  const [originalMarketplaceEtcList, setOriginalMarketplaceEtcList] = useState(
    []
  );

  /**
   * @type {[DicerSearchResult[], Function]}
   */
  const [marketplaceDicerList, setMarketplaceDicerList] = useState([]);

  const [marketplaceEquipmentList, setMarketplaceEquipmentList] = useState([]);
  const [marketplaceConsumableList, setMarketplaceConsumableList] = useState(
    []
  );
  const [marketplaceEtcList, setMarketplaceEtcList] = useState([]);

  /**
   * @type {[Boolean, Function]}
   */
  const [showDialog, setShowDialog] = useState(false);

  /**
   * @type {[DicerSearchResult, Function]}
   */
  const [selectedItem, setSelectedItem] = useState(null);

  /**
   * @type {[Boolean, Function]}
   */
  const [showMenuLeftFoeMobile, setShowMenuLeftFoeMobile] = useState(false);

  /**
   * @type {[String, Function]}
   */
  const [valueSortItem, setValueSortItem] = useState("asc");

  /**
   * @type {[Number, Function]}
   */
  const [currentPageShowDicerItem, setCurrentPageShowDicerItem] = useState(1);

  /**
   * @type {[Number, Function]}
   */
  const [indexActiveTapMarketplaceTap, setindexActiveTapMarketplaceTap] =
    useState(0);

  /**
   * @type {Object[]}
   */
  const dataMarketplaceHeaderTap = [
    { name: "Dicer", icon: IconDicer, isComingSoon: false },
    { name: "Equipment", icon: IconEquipment, isComingSoon: false },
    { name: "Consumable", icon: IconDicer, isComingSoon: false },
    { name: "Etc.", icon: IconDicer, isComingSoon: false },
  ];

  /**
   * @type {Object[]}
   */
  const optionSortItem = [
    { value: "asc", display: "Lowest Price" },
    { value: "desc", display: "Higher Price" },
  ];

  useEffect(() => {
    // asynchronous call parallel apis

    fetchGeneralFilterList();
    fetchStatsFilterList();
    fetchCardsFilterList();
    fetchEquipmentFilterList();
    fetchConsumableFilterList();
    fetchEtcFilterList();

    fetchMarketplaceDicerList();
    fetchMarketplaceEquipmentList();
    fetchMarketplaceConsumableList();
    fetchMarketplaceEtcList();
    setTitlePageMarketplaceDicer("XXXXX Dicer");
  }, []);

  const fetchGeneralFilterList = async () => {
    //TODO: fetch data from api
    setGeneralFilterList(MockupMarketplaceGeneralFilter);
  };
  const fetchStatsFilterList = async () => {
    //TODO: fetch data from api
    setStatsFilterList(MockupMarketplaceStatsFilter);
  };
  const fetchCardsFilterList = async () => {
    //TODO: fetch data from api
    setCardsFilterList(MarketplaceCardsFilter);
  };
  const fetchEquipmentFilterList = async () => {
    //TODO: fetch data from api
    setEquipmentFilterList(MarketplaceEquipmentFilter);
  };
  const fetchConsumableFilterList = async () => {
    //TODO: fetch data from api
    setConsumableFilterList(MarketplaceConsumableFilter);
  };
  const fetchEtcFilterList = async () => {
    //TODO: fetch data from api
    setEtcFilterList(MarketplaceEtcFilter);
  };

  const fetchMarketplaceDicerList = async (
    isUseOriginalData,
    newSort,
    newActiveTap
  ) => {
    //TODO: fetch data from api

    let rawOriginalMarketplaceDicerList = isUseOriginalData
      ? [...originalMarketplaceDicerList]
      : [...MockupMarketplaceDicer];

    let activeSectionTap;
    if (newActiveTap || newActiveTap === 0) {
      activeSectionTap = newActiveTap;
    } else {
      activeSectionTap = activeSectionDicerTap;
    }

    if (activeSectionTap == 0) {
      rawOriginalMarketplaceDicerList = [...rawOriginalMarketplaceDicerList];
    } else if (activeSectionTap == 1) {
      rawOriginalMarketplaceDicerList = [
        ...rawOriginalMarketplaceDicerList.filter(
          (item) => item.tag === "sale"
        ),
      ];
    } else if (activeSectionTap == 2) {
      rawOriginalMarketplaceDicerList = [
        ...rawOriginalMarketplaceDicerList.filter(
          (item) => item.tag === "sold"
        ),
      ];
    }

    if (newSort) {
      let rawMockupMarketplaceDicer = _.orderBy(
        [...rawOriginalMarketplaceDicerList],
        ["price"],
        [newSort]
      );
      setMarketplaceDicerList(rawMockupMarketplaceDicer);
    } else {
      let rawMockupMarketplaceDicer = _.orderBy(
        [...rawOriginalMarketplaceDicerList],
        ["price"],
        [valueSortItem]
      );
      setOriginalMarketplaceDicerList(MockupMarketplaceDicer);
      setMarketplaceDicerList(rawMockupMarketplaceDicer);
    }
  };

  const fetchMarketplaceEquipmentList = async (
    isUseOriginalData,
    newSort,
    newActiveTap
  ) => {
    //TODO: fetch data from api

    let rawOriginalMarketplaceEquipmentList = isUseOriginalData
      ? [...originalMarketplaceEquipmentList]
      : [...MockupMarketplaceEquipment];

    let activeSectionTap;
    if (newActiveTap || newActiveTap === 0) {
      activeSectionTap = newActiveTap;
    } else {
      activeSectionTap = activeSectionDicerTap;
    }

    if (activeSectionTap == 0) {
      rawOriginalMarketplaceEquipmentList = [
        ...rawOriginalMarketplaceEquipmentList,
      ];
    } else if (activeSectionTap == 1) {
      rawOriginalMarketplaceEquipmentList = [
        ...rawOriginalMarketplaceEquipmentList.filter(
          (item) => item.tag === "sale"
        ),
      ];
    } else if (activeSectionTap == 2) {
      rawOriginalMarketplaceEquipmentList = [
        ...rawOriginalMarketplaceEquipmentList.filter(
          (item) => item.tag === "sold"
        ),
      ];
    }

    if (newSort) {
      let rawMockupMarketplaceEquipment = _.orderBy(
        [...rawOriginalMarketplaceEquipmentList],
        ["price"],
        [newSort]
      );
      setMarketplaceEquipmentList(rawMockupMarketplaceEquipment);
    } else {
      let rawMockupMarketplaceEquipment = _.orderBy(
        [...rawOriginalMarketplaceEquipmentList],
        ["price"],
        [valueSortItem]
      );
      setOriginalMarketplaceEquipmentList(MockupMarketplaceEquipment);
      setMarketplaceEquipmentList(rawMockupMarketplaceEquipment);
    }
  };

  const fetchMarketplaceConsumableList = async (
    isUseOriginalData,
    newSort,
    newActiveTap
  ) => {
    //TODO: fetch data from api

    let rawOriginalMarketplaceConsumableList = isUseOriginalData
      ? [...originalMarketplaceConsumableList]
      : [...MockupMarketplaceConsumable];

    let activeSectionTap;
    if (newActiveTap || newActiveTap === 0) {
      activeSectionTap = newActiveTap;
    } else {
      activeSectionTap = activeSectionDicerTap;
    }

    if (activeSectionTap == 0) {
      rawOriginalMarketplaceConsumableList = [
        ...rawOriginalMarketplaceConsumableList,
      ];
    } else if (activeSectionTap == 1) {
      rawOriginalMarketplaceConsumableList = [
        ...rawOriginalMarketplaceConsumableList.filter(
          (item) => item.tag === "sale"
        ),
      ];
    } else if (activeSectionTap == 2) {
      rawOriginalMarketplaceConsumableList = [
        ...rawOriginalMarketplaceConsumableList.filter(
          (item) => item.tag === "sold"
        ),
      ];
    }

    if (newSort) {
      let rawMockupMarketplaceConsumable = _.orderBy(
        [...rawOriginalMarketplaceConsumableList],
        ["price"],
        [newSort]
      );
      setMarketplaceConsumableList(rawMockupMarketplaceConsumable);
    } else {
      let rawMockupMarketplaceConsumable = _.orderBy(
        [...rawOriginalMarketplaceConsumableList],
        ["price"],
        [valueSortItem]
      );
      setOriginalMarketplaceConsumableList(MockupMarketplaceConsumable);
      setMarketplaceConsumableList(rawMockupMarketplaceConsumable);
    }
  };

  const fetchMarketplaceEtcList = async (
    isUseOriginalData,
    newSort,
    newActiveTap
  ) => {
    //TODO: fetch data from api

    let rawOriginalMarketplaceEtcList = isUseOriginalData
      ? [...originalMarketplaceEtcList]
      : [...MockupMarketplaceEtc];

    let activeSectionTap;
    if (newActiveTap || newActiveTap === 0) {
      activeSectionTap = newActiveTap;
    } else {
      activeSectionTap = activeSectionDicerTap;
    }

    if (activeSectionTap == 0) {
      rawOriginalMarketplaceEtcList = [...rawOriginalMarketplaceEtcList];
    } else if (activeSectionTap == 1) {
      rawOriginalMarketplaceEtcList = [
        ...rawOriginalMarketplaceEtcList.filter((item) => item.tag === "sale"),
      ];
    } else if (activeSectionTap == 2) {
      rawOriginalMarketplaceEtcList = [
        ...rawOriginalMarketplaceEtcList.filter((item) => item.tag === "sold"),
      ];
    }

    if (newSort) {
      let rawMockupMarketplaceEtc = _.orderBy(
        [...rawOriginalMarketplaceEtcList],
        ["price"],
        [newSort]
      );
      setMarketplaceEtcList(rawMockupMarketplaceEtc);
    } else {
      let rawMockupMarketplaceEtc = _.orderBy(
        [...rawOriginalMarketplaceEtcList],
        ["price"],
        [valueSortItem]
      );
      setOriginalMarketplaceEtcList(MockupMarketplaceEtc);
      setMarketplaceEtcList(rawMockupMarketplaceEtc);
    }
  };

  /**
   * @type {Function}
   * @param {string} groupId
   * @param {DicerFilter} groupItem
   */
  const onCheckboxChange = (groupId, groupItem) => {
    // console.log("onCheckboxChange");

    let tempGeneralFilterList = [...getFilerData()];

    let groupIndex = tempGeneralFilterList.findIndex(
      (item) => item.groupId === groupId
    );

    if (groupIndex > -1) {
      let subListIndex = tempGeneralFilterList[groupIndex].list.findIndex(
        (item) => item.value === groupItem.value
      );
      if (subListIndex > -1) {
        tempGeneralFilterList[groupIndex].list[subListIndex].checked =
          !tempGeneralFilterList[groupIndex].list[subListIndex].checked;
        setFilerData(tempGeneralFilterList);
      }
    }
  };

  /**
   * @type {Function}
   * @param {string} groupId
   * @param {DicerFilter} groupItem
   * @param {string} newValue
   */
  const onSelectChange = (groupId, groupItem, newValue) => {
    let tempGeneralFilterList = [...getFilerData()];

    let groupIndex = tempGeneralFilterList.findIndex(
      (item) => item.groupId === groupId
    );

    if (groupIndex > -1) {
      let subListIndex = tempGeneralFilterList[groupIndex].list.findIndex(
        (item) => item.itemId === groupItem.itemId
      );

      if (subListIndex > -1) {
        tempGeneralFilterList[groupIndex].list[subListIndex].value = newValue;

        setFilerData(tempGeneralFilterList);
      }
    }
  };

  /**
   * @type {Function}
   * @param {string} groupId
   * @param {Number[]} newListValue
   */
  const onSlideChange = (groupId, newListValue) => {
    let tempGeneralFilterList = [...getFilerData()];

    let groupIndex = tempGeneralFilterList.findIndex(
      (item) => item.groupId === groupId
    );

    if (groupIndex > -1) {
      tempGeneralFilterList[groupIndex].value = newListValue;
      setFilerData(tempGeneralFilterList);
    }
  };

  /**
   * @type {Function}
   * @param {DicerFilter[]} newDataList
   */
  const setFilerData = (newDataList) => {
    if (indexActiveTapMarketplaceTap === 0) {
      if (activeFilterTap == 0) {
        setGeneralFilterList(newDataList);
      } else if (activeFilterTap == 1) {
        setStatsFilterList(newDataList);
      } else {
        setCardsFilterList(newDataList);
      }
    } else if (indexActiveTapMarketplaceTap === 1) {
      setEquipmentFilterList(newDataList);
    } else if (indexActiveTapMarketplaceTap === 2) {
      setConsumableFilterList(newDataList);
    } else if (indexActiveTapMarketplaceTap === 3) {
      setEtcFilterList(newDataList);
    }
  };

  /**
   * @type {Function}
   */
  const getFilerData = () => {
    if (indexActiveTapMarketplaceTap === 0) {
      if (activeFilterTap == 0) {
        return generalFilterList;
      } else if (activeFilterTap == 1) {
        return statsFilterList;
      } else {
        return cardsFilterList;
      }
    } else if (indexActiveTapMarketplaceTap === 1) {
      return equipmentFilterList;
    } else if (indexActiveTapMarketplaceTap === 2) {
      return consumableFilterList;
    } else if (indexActiveTapMarketplaceTap === 3) {
      return etcFilterList;
    } else {
      return [];
    }
  };

  /**
   * @type {Function}
   * @param {DicerSearchResult[]} obj
   */
  const onClickDicerItem = (obj) => {
    setSelectedItem(obj);
    setShowDialog(true);
  };

  /**
   * @type {Function}
   * @param {Object} e
   */
  const onClickFilter = (e) => {
    let rawShowMenuLeftFoeMobile = !showMenuLeftFoeMobile;
    setShowMenuLeftFoeMobile(rawShowMenuLeftFoeMobile);
  };

  /**
   * @type {Function}
   * @param {Object} e
   */
  const onChangeSelectionSortItem = (e) => {
    let newValue = e.target.value;

    if (indexActiveTapMarketplaceTap === 0) {
      fetchMarketplaceDicerList(true, newValue);
    } else if (indexActiveTapMarketplaceTap === 1) {
      fetchMarketplaceEquipmentList(true, newValue);
    } else if (indexActiveTapMarketplaceTap === 2) {
      fetchMarketplaceConsumableList(true, newValue);
    } else if (indexActiveTapMarketplaceTap === 3) {
      fetchMarketplaceEtcList(true, newValue);
    }

    setCurrentPageShowDicerItem(1);
    setValueSortItem(newValue);
  };

  /**
   * @type {Function}
   * @param {Number} index
   */
  const onChangeActiveSectionDicerTap = (index) => {
    if (indexActiveTapMarketplaceTap === 0) {
      fetchMarketplaceDicerList(true, null, index);
    } else if (indexActiveTapMarketplaceTap === 1) {
      fetchMarketplaceEquipmentList(true, null, index);
    } else if (indexActiveTapMarketplaceTap === 2) {
      fetchMarketplaceConsumableList(true, null, index);
    } else if (indexActiveTapMarketplaceTap === 3) {
      fetchMarketplaceEtcList(true, null, index);
    }

    setCurrentPageShowDicerItem(1);
    setActiveSectionDicerTap(index);
  };

  /**
   * @type {Function}
   * @param {Number} index
   */
  const onClickMarketplaceTap = (e, index) => {
    if (index === 0) {
      setTitlePageMarketplaceDicer("XXXXX Dicer");
    } else if (index === 1) {
      setTitlePageMarketplaceDicer("XXXXX Item");
    }
    setindexActiveTapMarketplaceTap(index);
  };

  /**
   * @type {Function}
   */
  const _renderRadioIconGrid = () => {
    return (
      <SiteButtonGroupRadioIcon
        list={tapSectionIconGridDicer}
        active={activeSectionIconGridDicerTap}
        setActiveOnClick={setActiveSectionIconGridDicerTap}
      />
    );
  };

  const _renderLeftMenuOfFilter = () => {

    
    if (indexActiveTapMarketplaceTap === 0) {
      return (
        <MarketplaceLeftMenu mobileShow={showMenuLeftFoeMobile}>
          <MarketplaceFilterTap titleTxt="Filter (0)" clearTxt="Clear filter">
            <SiteButtonGroupRadio
              list={tapFilterList}
              active={activeFilterTap}
              setActiveOnClick={setActiveFilterTap}
              style={{ justifyContent: "center" }}
            />
          </MarketplaceFilterTap>
          {getFilerData().length !== 0 ? (
            <MarketplaceFilter
              filter={getFilerData()}
              onCheckboxChange={onCheckboxChange}
              onSelectChange={onSelectChange}
              onSlideChange={onSlideChange}
            ></MarketplaceFilter>
          ) : null}
        </MarketplaceLeftMenu>
      );
    } else if (indexActiveTapMarketplaceTap === 1) {
      return (
        <MarketplaceLeftMenu mobileShow={showMenuLeftFoeMobile}>
          
          {getFilerData().length !== 0 ? (
            <MarketplaceFilter
              filter={getFilerData()}
              onCheckboxChange={onCheckboxChange}
              onSelectChange={onSelectChange}
              onSlideChange={onSlideChange}
            ></MarketplaceFilter>
          ) : null}
        </MarketplaceLeftMenu>
      );
    } else if (indexActiveTapMarketplaceTap === 2) {
      return (
        <MarketplaceLeftMenu mobileShow={showMenuLeftFoeMobile}>
          
          {getFilerData().length !== 0 ? (
            <MarketplaceFilter
              filter={getFilerData()}
              onCheckboxChange={onCheckboxChange}
              onSelectChange={onSelectChange}
              onSlideChange={onSlideChange}
            ></MarketplaceFilter>
          ) : null}
        </MarketplaceLeftMenu>
      );
    } else if (indexActiveTapMarketplaceTap === 3) {
      return (
        <MarketplaceLeftMenu mobileShow={showMenuLeftFoeMobile}>
          
          {getFilerData().length !== 0 ? (
            <MarketplaceFilter
              filter={getFilerData()}
              onCheckboxChange={onCheckboxChange}
              onSelectChange={onSelectChange}
              onSlideChange={onSlideChange}
            ></MarketplaceFilter>
          ) : null}
        </MarketplaceLeftMenu>
      );
    }
  };

  const getDataItemOfMarketplace = () => {
    if (indexActiveTapMarketplaceTap === 0) {
      return marketplaceDicerList;
    } else if (indexActiveTapMarketplaceTap === 1) {
      return marketplaceEquipmentList;
    } else if (indexActiveTapMarketplaceTap === 2) {
      return marketplaceConsumableList;
    } else if (indexActiveTapMarketplaceTap === 3) {
      return marketplaceEtcList;
    }
  };

  return (
    <MarketplaceDicerPage id="dk-website-marketplace-page-dicer">
      <MarketplaceHeaderTap
        data={dataMarketplaceHeaderTap}
        indexActive={indexActiveTapMarketplaceTap}
        onClickItem={onClickMarketplaceTap}
      />
      <DemoText>DEMO DATA</DemoText>
      <MarketplaceHeaderTapMobile
        onClickFilter={onClickFilter}
        dataSource={dataMarketplaceHeaderTap}
        indexActive={indexActiveTapMarketplaceTap}
        onChange={(newValue) => {
          onClickMarketplaceTap(null, newValue);
        }}
      />
      <MarketplaceLeftContainer>
        {_renderLeftMenuOfFilter()}

        <MarketplaceSection>
          <MarketplaceSectionTap
            titleTxt={titlePageMarketplaceDicer}
            rightContent={_renderRadioIconGrid()}
          >
            <div style={{ flexGrow: 1 }}>
              <SiteButtonGroupRadio
                list={tapSectionDicer}
                active={activeSectionDicerTap}
                setActiveOnClick={onChangeActiveSectionDicerTap}
              />
            </div>
            <MarketplaceTapSectionOrderItem>
              <SiteSelect
                source={optionSortItem}
                value={valueSortItem}
                onChange={onChangeSelectionSortItem}
              />
              <MarketplaceTapSectionIconGridDicerContainer>
                {_renderRadioIconGrid()}
              </MarketplaceTapSectionIconGridDicerContainer>
            </MarketplaceTapSectionOrderItem>
          </MarketplaceSectionTap>
          <MarketplaceDicerItem
            listItem={getDataItemOfMarketplace()}
            viewType={activeSectionIconGridDicerTap}
            onClickItem={onClickDicerItem}
            currentPage={currentPageShowDicerItem}
            setCurrentPage={setCurrentPageShowDicerItem}
            perPage={20}
            indexActiveTapMarketplaceTap={indexActiveTapMarketplaceTap}
          ></MarketplaceDicerItem>
        </MarketplaceSection>
      </MarketplaceLeftContainer>

      <MarketplaceDialog
        open={showDialog}
        mode={selectedItem?.type}
        onClose={() => {
          setShowDialog(false);
        }}
        dicerId={selectedItem?.id}
      ></MarketplaceDialog>
    </MarketplaceDicerPage>
  );
}

export default MarketplaceDicer;
