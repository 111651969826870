import React from "react";
import { LeftMenu } from "./style/style";

function SiteLeftMenu(props) {
  const { children, ...rest } = props;

  return <LeftMenu {...rest}>{children}</LeftMenu>;
}

export default SiteLeftMenu;
