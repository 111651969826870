import React, { useState, useEffect } from "react";
import style from "./style/style";
import Cursor from "../../images/1-Home/Cursor-Roadmap.png";

const StyleSitePagination = style.SitePagination;
const StyleSitePaginationCursorLeft = style.SitePaginationCursorLeft;
const StyleSitePaginationCursorRight = style.SitePaginationCursorRight;
const StyleSitePaginationContentContainer =
  style.SitePaginationContentContainer;
const StyleSitePaginationInput = style.SitePaginationInput;

function SitePagination(props) {
  const { currentPage, totalPage, onChangePage, ...rest } = props;

  const [valueInput, setValueInput] = useState(currentPage);

  useEffect(() => {
    setValueInput(currentPage);
  }, []);

  const onBlurInputPagination = (e) => {
    let value = e.target.value;
    if (isNaN(value) || value < 1 || value > totalPage) {
      setValueInput(currentPage);
    } else {
      if (valueInput !== currentPage) {
        onChangePage(e, valueInput, currentPage);
      }
    }
  };

  const onKeyUpInputPagination = (e) => {
    // Number 13 is the "Enter" key on the keyboard
    if (e.keyCode === 13) {
      let value = e.target.value;
      if (isNaN(value) || value < 1 || value > totalPage) {
        setValueInput(currentPage);
      } else {
        if (valueInput !== currentPage) {
          onChangePage(e, valueInput, currentPage);
        }
      }
    }
  };

  const onChangeInputPagination = (e) => {
    let value = e.target.value;
    // if (!isNaN(value) && value > 1 && value < totalPage) {
    setValueInput(value);
    // }
  };

  const onClickCursorLeftPagination = (e) => {
    let value = valueInput - 1;
    if (value >= 1) {
      onChangePage(e, value, currentPage);
      setValueInput(value);
    }
  };

  const onClickCursorRightPagination = (e) => {
    let value = valueInput + 1;
    if (value <= totalPage) {
      onChangePage(e, value, currentPage);
      setValueInput(value);
    }
  };

  return (
    <StyleSitePagination {...rest}>
      <StyleSitePaginationCursorLeft
        src={Cursor}
        onClick={onClickCursorLeftPagination}
      />
      <StyleSitePaginationContentContainer>
        Page{" "}
        <StyleSitePaginationInput
          value={valueInput}
          onBlur={onBlurInputPagination}
          onKeyUp={onKeyUpInputPagination}
          onChange={onChangeInputPagination}
        />{" "}
        of {totalPage}
      </StyleSitePaginationContentContainer>
      <StyleSitePaginationCursorRight
        src={Cursor}
        onClick={onClickCursorRightPagination}
      />
    </StyleSitePagination>
  );
}

export default SitePagination;
