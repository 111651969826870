import React from "react";
import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  NativeSelect,
  Select,
  Typography,
  withStyles,
} from "@material-ui/core";
import InputBase from "@material-ui/core/InputBase";
import IconDicer from "../../images/4-Marketplace/Icon-Dicer.png";
import style from "./style/style";

function SiteSelectImage(props) {
  const { source, value, placeholder, onChange, width } = props;

  const StyleSiteSelectImage = style.SiteSelectImage;
  const StyleSiteSelectImageBootstrapInput =
    style.SiteSelectImageBootstrapInput;
  const StyleSiteSelectImageIcon = style.SiteSelectImageIcon;
  const StyleMenuItemIcon = style.MenuItemIcon;
  const StyleMenuItemText = style.MenuItemText;

  const _renderMenuItem = () => {
    return source.map((item, index) => (
      <MenuItem value={item.value} key={"SiteSelectImage" + index}>
        <ListItemIcon style={{ minWidth: "25px", paddingLeft: "8px" }}>
          <StyleMenuItemIcon src={item.image} />
        </ListItemIcon>
        <ListItemText
          style={{ marginTop: "0px", marginBottom: "0px" }}
          primary={<StyleMenuItemText>{item.display}</StyleMenuItemText>}
        />
      </MenuItem>
    ));
  };

  return (
    <StyleSiteSelectImage
      id="demo-customized-select-native"
      value={value}
      onChange={onChange}
      input={<StyleSiteSelectImageBootstrapInput />}
      IconComponent={StyleSiteSelectImageIcon}
      width={width}
    >
      {_renderMenuItem()}
    </StyleSiteSelectImage>
  );
}

export default SiteSelectImage;
