import styled from "styled-components";
import React from "react";
import SiteButtonBrownDark from "./SiteButtonBrownDark";
import SiteButtonBrownLight from "./SiteButtonBrownLight";
import style from "./style/style";

function SiteButtonGroupRadioIcon(props) {
  const { list, active, setActiveOnClick } = props;

  const StyleSiteButtonGroupRadioIcon = style.SiteButtonGroupRadioIcon;

  const BtnSiteButtonBrownDark = styled(SiteButtonBrownDark)`
    font-size: 0.8rem !important;
    margin-right: -3px !important;
    border-radius: 5px !important;
    padding: 3px !important;
  `;
  const BtnSiteButtonBrownLight = styled(SiteButtonBrownLight)`
    font-size: 0.8rem !important;
    margin-right: -3px !important;
    border-radius: 5px !important;
    padding: 3px !important;
  `;

  const _renderButtonGroup = () => {
    return list.map((item, index) => {
      if (active == index) {
        return (
          <BtnSiteButtonBrownLight
            onClick={() => setActiveOnClick(index)}
            key={index}
          >
            {item}
          </BtnSiteButtonBrownLight>
        );
      } else {
        return (
          <BtnSiteButtonBrownDark
            onClick={() => setActiveOnClick(index)}
            key={index}
          >
            {item}
          </BtnSiteButtonBrownDark>
        );
      }
    });
  };

  return (
    <StyleSiteButtonGroupRadioIcon>
      {_renderButtonGroup()}
    </StyleSiteButtonGroupRadioIcon>
  );
}

export default SiteButtonGroupRadioIcon;
