import styled from "styled-components";
import CommonConstants from "../../../../../../resources/constants/CommonConstants";

export const MarketplaceDicerItemPage = styled.div `
  position: relative;
  width: 100%;
  z-index: 2;
  margin-bottom: 7rem;
  min-height: 42vh;
`;

export const MarketplaceDicerItemContent = styled.div `
  border: 1px solid ${CommonConstants.COLOR_ACTIVE};
  border-radius: 10px;
  background: #1c0b09;
  position: relative;
  /* width: 100%; */
  text-align: left;
  padding-left: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 15px;
  cursor: pointer;
  
`;

export const MarketplaceDicerItemContentTitle = styled.div `
  color: #fff;
  font-size: 1rem;
  font-weight: bold;
  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    font-size: 0.7rem;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_TABLET_MIN}) and (max-width: ${CommonConstants.MIN_WIDTH_TABLET_MAX}) {
    font-size: 1rem;
  }
`;

export const MarketplaceDicerItemContentSubTitle = styled.div `
  color: #ffe3c7;
  font-size: 0.8rem;
  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    font-size: 0.6rem;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_TABLET_MIN}) and (max-width: ${CommonConstants.MIN_WIDTH_TABLET_MAX}) {
    font-size: 0.8rem;
  }
`;

export const MarketplaceDicerItemContentImageContainer = styled.div `
  position: relative;
  width: 100%;
  text-align: center;
`;

export const MarketplaceDicerItemContentImageContainerMobile = styled.div `
  position: relative;
  /* width: 30%; */
  text-align: center;
`;

export const MarketplaceDicerItemContentImage = styled.img `
  margin-top: 20px;
  margin-bottom: 15px;
  max-height: 150px;
  height: 150px;
  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    max-height: 100px;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_TABLET_MIN}) and (max-width: ${CommonConstants.MIN_WIDTH_TABLET_MAX}) {
    max-height: 100px;
  }
`;

export const MarketplaceDicerItemContentNumberRoman = styled.div `
  color: ${CommonConstants.COLOR_ACTIVE};
  font-size: 0.8rem;
`;

export const MarketplaceEquipmentDetailItemContent = styled.div`
color: #FFFFFF;
font-size: 0.8rem;
`;

export const MarketplaceDicerItemContentMoney = styled.div `
  position: relative;
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: center;
  align-items: center;
  line-height: 2rem;
  column-gap: 10px;
`;

export const MarketplaceDicerItemContentMoneyIcon = styled.img `
  max-height: 2rem;
  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
  max-height: 1.5rem;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_TABLET_MIN}) and (max-width: ${CommonConstants.MIN_WIDTH_TABLET_MAX}) {
  max-height: 1.5rem;
  
  }
`;

export const MarketplaceDicerItemContentMoneyNumber = styled.div `
  color: #fff;
  font-size: 1.2rem;
  font-weight: bold;
`;

export const MarketplaceDicerItemContentMoneyDolla = styled.div `
  color: #ffe3c7;
  font-size: 0.8rem;
`;

export const MarketplaceDicerItemContentListView = styled.div `
  border: 1px solid ${CommonConstants.COLOR_ACTIVE};
  border-radius: 10px;
  background: #1c0b09;
  position: relative;
  /* width: 100%; */
  text-align: left;
  padding-left: 40px;
  padding-top: 25px;
  padding-bottom: 25px;
  padding-right: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    display: none;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_TABLET_MIN}) and (max-width: ${CommonConstants.MIN_WIDTH_TABLET_MAX}) {
    display: none;
  }
`;

export const MarketplaceDicerItemContentListViewMobile = styled.div `
  border: 1px solid ${CommonConstants.COLOR_ACTIVE};
  border-radius: 10px;
  background: #1c0b09;
  text-align: left;
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 10px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP}) and (max-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XL}) {
    display: none;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XL}) {
    display: none;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XXL}) {
    display: none;
  }
`;

export const MarketplaceDicerItemListViewMobile = styled.div `
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`;

export const MarketplaceDicerItemContentContainerListView = styled.div `
  display: block;
`;

export const MarketplaceDicerItemContentTextTopicListView = styled.span `
  color: ${CommonConstants.COLOR_ACTIVE};
  font-size: 1.2rem;
  margin-right: 20px;
  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    font-size: 0.8rem;
    margin-right: 10px;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_TABLET_MIN}) and (max-width: ${CommonConstants.MIN_WIDTH_TABLET_MAX}) {
    font-size: 1rem;
  }
`;

export const MarketplaceDicerItemContentTextListView = styled.span `
  color: #fff;
  font-size: 1.2rem;
  font-weight: bold;
  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    font-size: 0.8rem;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_TABLET_MIN}) and (max-width: ${CommonConstants.MIN_WIDTH_TABLET_MAX}) {
    font-size: 1rem;
  }
`;

export const MarketplaceDicerItemContentMoneyIconListView = styled.img `
  max-height: 1.7rem;
  margin-right: 10px;
`;

export const MarketplaceDicerItemContentMoneyNumberListView = styled.div `
  color: #fff;
  font-size: 2rem;
  font-weight: bold;
`;

export const MarketplaceDicerItemContentImageListView = styled.img `
  max-height: 100px;
`;