import styled from "styled-components";
import CommonConstants from "../../../../../../resources/constants/CommonConstants";

export const MarketplaceSectionTapPage = styled.div`
  position: relative;
  width: 100%;
  z-index: 2;
`;

export const MarketplaceSectionTapContent = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;

  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    flex-direction: column;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_TABLET_MIN}) and (max-width: ${CommonConstants.MIN_WIDTH_TABLET_MAX}) {
    flex-direction: column;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP}) and (max-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XL}) {
    flex-direction: row;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XL}) {
    flex-direction: row;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XXL}) {
    flex-direction: row;
  }
`;

export const MarketplaceSectionTapText = styled.div`
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
  flex-grow: 1;
  align-self: stretch;
  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    font-size: 1.5rem;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_TABLET_MIN}) and (max-width: ${CommonConstants.MIN_WIDTH_TABLET_MAX}) {
    font-size: 1.5rem;
  }
`;

export const MarketplaceSectionTapTitle = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
`;

export const MarketplaceSectionTapRight = styled.div`
  float: right;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP}) and (max-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XL}) {
    display: none;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XL}) {
    display: none;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XXL}) {
    display: none;
  }
`;
