import React from "react";
import styled from "styled-components";

function Error404() {
  return <Error404Page id="dk-website-error404-page">error404</Error404Page>;
}

export default Error404;

const Error404Page = styled.div`
  position: relative;
  z-index: 2;
  height: 100%;
  width: 100%;
`;
