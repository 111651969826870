import React, { useState } from "react";
import styled from "styled-components";
import CommonConstants from "../../resources/constants/CommonConstants";
import DicerConstants from "../../resources/constants/DicerConstants";
import BuyDicer from "./components/BuyDicer";
import DicerLeftMenu from "./components/DicerLeftMenu";
import DicerMiniMenu from "./components/DicerMiniMenu";
import MyDicer from "./components/MyDicer";

const DemoText = styled.div`
  font-size: 40px;
  color: white;
  text-align: center;
  padding-top: 40px;
`

function Dicer() {
  const [activeMenu, setActiveMenu] = useState(DicerConstants.MENU.BUY_DICER);

  const renderContentByMenu = () => {
    if (activeMenu === DicerConstants.MENU.BUY_DICER) {
      return <BuyDicer />;
    } else if (activeMenu === DicerConstants.MENU.MY_DICER) {
      return <MyDicer />;
    } else {
      return null;
    }
  };

  return (
    <Container>
      <DicerLeftMenu activeMenu={activeMenu} onMenuChange={setActiveMenu} />
      <Section>
        <DemoText>DEMO DATA</DemoText>
        <Content>
          <DicerMiniMenu activeMenu={activeMenu} onMenuChange={setActiveMenu} />
          {renderContentByMenu()}
        </Content>
      </Section>
    </Container>
  );
}

export default Dicer;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  z-index: 2;
`;

const Section = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: 290px;
  padding-top: 4rem;
  overflow-x: hidden;
  min-height: 100vh;

  @media (max-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XL}) {
    margin-left: 0;
  }
`;

const Content = styled.div`
  padding: 2rem;

  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    padding: 1rem;
  }
`;
