import HomePage from "../../pages/Home/Home";
import FarmPage from "../../pages/Farm/Farm";
import MarketplacePage from "../../pages/Marketplace/Marketplace";
import OverviewMarketPage from "../../pages/OverviewMarket/OverviewMarket";
import DicerPage from "../../pages/Dicer/Dicer";

export default {
  MENU_BASE_PATH: "/dk",
  MENU_LIST: [
    {
      MENU_ITEM: "Home",
      MENU_COMPONENT: HomePage,
      MENU_ROUTER: "/",
      MENU_DISCRETION: "",
      MENU_ACTIVE: true,
      MENU_NEWPAGE: false,
    },
    {
      MENU_ITEM: "Whitepaper",
      MENU_COMPONENT: FarmPage,
      MENU_ROUTER: "https://dicekingdomgame.gitbook.io/dice-kingdom/",
      MENU_DISCRETION: "",
      MENU_ACTIVE: true,
      MENU_NEWPAGE: true,
      MENU_SUB: [
        {
          SUB_NAME: "English",
          SUB_LINK: "https://google.com"
        },
        {
          SUB_NAME: "ภาษาไทย",
          SUB_LINK: "https://google.com"
        }
      ]
    },
    {
      MENU_ITEM: "WP-Eng",
      MENU_COMPONENT: FarmPage,
      MENU_ROUTER: "https://dicekingdomgame.gitbook.io/dice-kingdom/",
      MENU_DISCRETION: "",
      MENU_ACTIVE: true,
      MENU_NEWPAGE: true,
    },
    {
      MENU_ITEM: "WP-Thai",
      MENU_COMPONENT: DicerPage,
      MENU_ROUTER: "https://dicekingdomgame.gitbook.io/dice-kingdom-th/",
      MENU_DISCRETION: "Coming soon",
      MENU_ACTIVE: true,
      MENU_NEWPAGE: true,
    },
    {
      MENU_ITEM: "Marketplace",
      MENU_COMPONENT: DicerPage,
      MENU_ROUTER: "https://www.megaland.io/dicekingdom",
      MENU_DISCRETION: "",
      MENU_ACTIVE: true,
      MENU_NEWPAGE: true,
    },
    
  ],
};
