const FarmConstants = {
  FILTER_STATUS: {
    LIVE: "live",
    FINISHED: "finished",
  },
  CONTENT_MODE: {
    STAKE: "stake",
    WALLET: "wallet",
  },
  STAKE_ACTION: {
    DEPOSIT: "Deposit",
    WITHDRAW: "Withdraw",
  },
};

export default FarmConstants;
