import React from "react";
import style from "./style/style";

const StyleSiteCheckboxColor = style.SiteCheckboxColor;
const StyleSiteCheckboxColorContainer = style.SiteCheckboxColorContainer;

function SiteCheckboxColor(props) {
  const { color, checked, onChange } = props;

  return (
    <StyleSiteCheckboxColorContainer color={color} onClick={onChange}>
      <StyleSiteCheckboxColor checked={checked} onChange={onChange} />
    </StyleSiteCheckboxColorContainer>
  );
}

export default SiteCheckboxColor;
