import React from "react";
import style from "./style/style";

const StyleSiteSlider = style.SiteSlider;

function SiteSlider(props) {
  const { value, min, max, onChange, ...rest } = props;

  return (
    <StyleSiteSlider
      value={value}
      valueLabelDisplay="on"
      marks
      min={min}
      max={max}
      {...rest}
      onChange={(e, value) => {
        onChange(e, value);
      }}
    />
  );
}

export default SiteSlider;
