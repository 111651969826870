import styled from "styled-components";
import CommonConstants from "../../../../resources/constants/CommonConstants";

export const ConHomeFooter = styled.div `
  margin-bottom: -30px;
  text-align: center;
  align-items: center;
  justify-content: center;
  align-content: center;
  position: relative;
  width: 100%;
  z-index: 1;

  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    margin-top: 30px;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_TABLET_MIN}) and (max-width: ${CommonConstants.MIN_WIDTH_TABLET_MAX}) {
    margin-top: 30px;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP}) and (max-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XL}) {
    margin-top: 30px;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XL}) {
    margin-top: 150px;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XXL}) {
    margin-top: 150px;
  }
`;

export const ImageHomeFooter = styled.img `
  max-width: 90%;
  max-height: 245px;
`;