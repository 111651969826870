import React, { useState, useEffect } from "react";
import {
  MarketplaceDialogBuyButton,
  MarketplaceDialogBuyContent,
  MarketplaceDialogBuyDiscount,
  MarketplaceDialogBuyIcon,
  MarketplaceDialogBuyPrice,
  MarketplaceDialogBuyPriceContainer,
  MarketplaceDialogContent,
  MarketplaceDialogDetailContainer,
  MarketplaceDialogDetailOwner,
  MarketplaceDialogDetailTitle,
  MarketplaceDialogDicerImage,
  MarketplaceDialogDicerImageContainer,
  MarketplaceDialogSectionOne,
  MarketplaceDialogSectionTwo,
} from "../MarketplaceDialog.component";
import CharacterBearImageResource from "../../../../../../../resources/images/1-Home/Character-Bear.png";
import CapsuleImageResource from "../../../../../../../resources/images/4-Marketplace/Capsule.png";
import BuyIconImageResource from "../../../../../../../resources/images/4-Marketplace/Capsule.png";
import MarketplaceDialogCloning from "../DicerDetail/MarketplaceDialogCloning";
import MarketplaceDialogCapsuleDetailMockup from "../../../mockup/marketplaceDialogCapsuleDetail.mockup.json";
import MarketplaceDialogSaleHistory from "../DicerDetail/MarketplaceDialogSaleHistory";
import CapsuleDetail from "../../../../../../../resources/model/Marketplace/CapsuleDetail";
import MarketplaceDialogAboutCapsule from "./MarketplaceDialogAboutCapsule";

function MarketplaceDialogCapsuleDetail({ dicerId }) {
  /**
   * @type [CapsuleDetail, Function]
   */
  const [capsuleDetails, setCapsuleDetails] = useState(null);

  useEffect(() => {
    // fetch data
    fetchDicerDetails(dicerId);
  }, []);

  const fetchDicerDetails = async (dicerId) => {
    //TODO : fetch data from api
    setCapsuleDetails(MarketplaceDialogCapsuleDetailMockup);
  };

  return (
    <MarketplaceDialogContent>
      <MarketplaceDialogSectionOne>
        <MarketplaceDialogDicerImageContainer>
          <MarketplaceDialogDicerImage src={CapsuleImageResource} />
        </MarketplaceDialogDicerImageContainer>
        <MarketplaceDialogBuyContent>
          <MarketplaceDialogBuyIcon src={BuyIconImageResource} />
          <MarketplaceDialogBuyPriceContainer>
            <MarketplaceDialogBuyPrice>{capsuleDetails?.price}</MarketplaceDialogBuyPrice>
            <MarketplaceDialogBuyDiscount>
              -${capsuleDetails?.discount}
            </MarketplaceDialogBuyDiscount>
          </MarketplaceDialogBuyPriceContainer>
          <MarketplaceDialogBuyButton>Buy</MarketplaceDialogBuyButton>
        </MarketplaceDialogBuyContent>
      </MarketplaceDialogSectionOne>
      <MarketplaceDialogSectionTwo>
        <MarketplaceDialogDetailContainer>
          <MarketplaceDialogDetailTitle>Dicer #{dicerId}</MarketplaceDialogDetailTitle>
          <MarketplaceDialogDetailOwner>
            Owner: {capsuleDetails?.ownerId}
          </MarketplaceDialogDetailOwner>
        </MarketplaceDialogDetailContainer>
        <MarketplaceDialogAboutCapsule about={capsuleDetails?.about} />
        <MarketplaceDialogCloning
          dicerId={dicerId}
          dicerImage={CharacterBearImageResource}
          items={capsuleDetails?.clones}
          responsive={true}
        />
        <MarketplaceDialogSaleHistory saleHistories={capsuleDetails?.saleHistories} />
      </MarketplaceDialogSectionTwo>
    </MarketplaceDialogContent>
  );
}

export default MarketplaceDialogCapsuleDetail;
