import React, { useState, useRef } from "react";
import FarmItem from "../../../../resources/model/Farm/FarmItem";
import FarmPoolHeader from "../FarmPoolHeader/FarmPoolHeader";
import FarmPoolContent from "../FarmPoolContent/FarmPoolContent";
import { FPC_Container, FPC_MultiplierBadge } from "./FarmPool.component";

/**
 *
 * @param {Props} props
 * @returns {React.FC}
 */
function FarmPool(props) {
  const parentRef = useRef();
  const contentRef = useRef();
  const { data, deposited, status, stakedOnly } = props;
  const [expanded, setExpanded] = useState(false);
  const visible = status === data?.status && (stakedOnly ? data.staked === true : true);

  const handleToggleExpandCollapse = ({ target }) => {
    const isBlockZone = (target) => {
      if (!target) {
        // no target
        return false;
      } else if (
        target.parentNode?.id === "__farm_pool_block_zone" ||
        contentRef.current?.isDialogOpen()
      ) {
        // stop & block
        return true;
      } else if (target.parentNode === parentRef.current) {
        // stop & do expand collaspe
        return false;
      } else {
        // resursive find another parent
        return isBlockZone(target.parentNode);
      }
    };

    if (isBlockZone(target) === false) {
      setExpanded(!expanded);
    }
  };

  return (
    <FPC_Container
      ref={parentRef}
      visible={visible}
      expanded={expanded}
      onClick={handleToggleExpandCollapse}
    >
      <FPC_MultiplierBadge>{data?.multiplyRate}x</FPC_MultiplierBadge>
      <FarmPoolHeader data={data} expanded={expanded} />
      <FarmPoolContent ref={contentRef} data={data} expanded={expanded} deposited={deposited} />
    </FPC_Container>
  );
}

export default FarmPool;

class Props {
  constructor() {
    /**
     * @type {FarmItem}
     */
    this.data = {};
    this.deposited = 0;
    this.status = "";
    this.stakedOnly = false;
  }
}
