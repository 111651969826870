import React from "react";
import styled from "styled-components";
import CommonConstants from "../../../../../../../resources/constants/CommonConstants";
import { DicerDetail_Info } from "../../../../../../../resources/model/Marketplace/DicerDetail";

/**
 *
 * @param {Props} props
 * @returns {React.FC}
 */
function MarketplaceDialogInfo(props) {
  const { info } = props;
  return (
    <Container>
      <Title>Info</Title>
      <Content>
        <Row>
          <FieldGroup>
            <FieldName>Start Money</FieldName>
            <FieldValue>{info?.startMoney?.toLocaleString()}</FieldValue>
          </FieldGroup>
          <FieldGroup>
            <FieldName>Salary</FieldName>
            <FieldValue>{info?.salary?.toLocaleString()}</FieldValue>
          </FieldGroup>
          <FieldGroup>
            <FieldName>Dice Control</FieldName>
            <FieldValue>{info?.diceControl?.toLocaleString()}</FieldValue>
          </FieldGroup>
          <FieldGroup>
            <FieldName>Gauage Control</FieldName>
            <FieldValue>{info?.gaugeControl}%</FieldValue>
          </FieldGroup>
          <FieldGroup>
            <FieldName>Gifted Bonus</FieldName>
            <FieldValue>{info?.giftedBonus}%</FieldValue>
          </FieldGroup>
          <FieldGroup>
            <FieldName>Doubled Bonus</FieldName>
            <FieldValue>{info?.doubledBonus}%</FieldValue>
          </FieldGroup>
          <FieldGroup>
            <FieldName>Mini Games Bonus</FieldName>
            <FieldValue>{info?.miniGamesBonus}%</FieldValue>
          </FieldGroup>
          <FieldGroup>
            <FieldName>Card Chain Bonus</FieldName>
            <FieldValue>{info?.cardChainBonus}%</FieldValue>
          </FieldGroup>
        </Row>
      </Content>
    </Container>
  );
}

export default MarketplaceDialogInfo;

const Container = styled.div`
  margin-top: 1rem;
`;

const Title = styled.div`
  font-weight: bold;
`;

const Content = styled.div`
  background: #af8068;
  border-radius: 0.6rem;
  padding: 1.5rem 2rem;
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;

  @media (max-width: ${CommonConstants.MIN_WIDTH_DESKTOP}) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const FieldGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const FieldName = styled.div`
  color: #4e0904;
  font-size: 0.8rem;
`;

const FieldValue = styled.div`
  color: #ffffff;
  font-weight: bold;
`;

class Props {
  constructor() {
    /**
     * @type {DicerDetail_Info}
     */
    this.info = {};
  }
}
