import { Grid } from "@material-ui/core";
import _ from "lodash";
import React, { useState, useEffect } from "react";
import SiteCheckbox from "../../../../../../resources/components/SiteChechbox/SiteCheckbox";
import {
  MarketplaceFilterPage,
  MarketplaceFilterContent,
  MarketplaceFilterTitle,
  MarketplaceFilterSelectSpan,
  MarketplaceFilterSearchContainer,
  MarketplaceFilterSearchTextField,
  MarketplaceFilterSearchBottomContainer,
  MarketplaceFilterSearchBottomContainerItem,
  MarketplaceFilterSearchBottomContainerItemActive,
  MarketplaceFilterCheckboxImage,
  MarketplaceFilterContentCheckBoxImage,
} from "./MarketplaceFilter.component";
import SiteCheckboxColor from "../../../../../../resources/components/SiteChechbox/SiteCheckboxColor";
import SiteSlider from "../../../../../../resources/components/SiteSlider/SiteSlider";
import SiteSelect from "../../../../../../resources/components/SiteSelect/SiteSelect";
import SiteCheckboxImage from "../../../../../../resources/components/SiteChechbox/SiteCheckboxImage";
import PicSkill2 from "../../../../../../resources/images/4-Marketplace/Pic-Skill2.png";
import SiteButtonBule from "../../../../../../resources/components/SiteButton/SiteButtonBule";

function MarketplaceFilter(props) {
  const [txtSearch, setTxtSearch] = useState([]);
  const [perPageSearch, setPerPageSearch] = useState(1);
  const [totalPageSearch, setTotalPageSearch] = useState(1);
  const { filter, onCheckboxChange, onSelectChange, onSlideChange } = props;

  useEffect(() => {
    // console.log(
    //   "[MarketplaceFilter useEffect] generalFilter => ",
    //   generalFilter
    // );
    // setListFilter(generalFilter);

    let filterChunkTen = _.chunk(filter, 10);
    setTotalPageSearch(filterChunkTen.length);
  });

  const _renderFilter = () => {
    return filter.map((item, index) => {
      if (item.type == "checkbox") {
        return (
          <MarketplaceFilterContent>
            <MarketplaceFilterTitle>{item.groupName}</MarketplaceFilterTitle>
            {_renderContentGridCheckbox(item.groupId, item.list)}
          </MarketplaceFilterContent>
        );
      } else if (item.type == "selection") {
        return (
          <MarketplaceFilterContent>
            <MarketplaceFilterTitle>{item.groupName}</MarketplaceFilterTitle>
            {_renderContentGridSelect(item.groupId, item.list)}
          </MarketplaceFilterContent>
        );
      } else if (item.type == "color-checkbox") {
        return (
          <MarketplaceFilterContent>
            <MarketplaceFilterTitle>{item.groupName}</MarketplaceFilterTitle>
            {_renderContentGridCheckboxColor(item.groupId, item.list)}
          </MarketplaceFilterContent>
        );
      } else if (item.type == "slide") {
        return (
          <MarketplaceFilterContent>
            <MarketplaceFilterTitle>{item.groupName}</MarketplaceFilterTitle>
            {_renderContentSlide(item.groupId, item.value, item.min, item.max)}
          </MarketplaceFilterContent>
        );
      } else if (item.type == "image-checkbox") {
        return (
          <MarketplaceFilterContent>
            <MarketplaceFilterContentCheckBoxImage>
              {_renderSearchCheckBoxImage(item.groupId, item.list)}
            </MarketplaceFilterContentCheckBoxImage>

            {/* <MarketplaceFilterTitle>{item.groupName}</MarketplaceFilterTitle>
            <MarketplaceFilterSearchContainer>
              <MarketplaceFilterSearchTextField
                value={txtSearch}
                placeholder="Search Farms"
                onChange={(target) => {
                  setTxtSearch(target.value);
                }}
              />
              <MarketplaceFilterSearchBottomContainer>
                {_renderPageingBottomSearch(item.list)}
              </MarketplaceFilterSearchBottomContainer>
            </MarketplaceFilterSearchContainer> */}
          </MarketplaceFilterContent>
        );
      }
    });
  };

  const _renderSearchCheckBoxImage = (groupId, list) => {
    return list.map((item, index) => { return(
        <SiteCheckboxImage
          value={item.value}
          checked={item.checked}
          image={PicSkill2}
          onChange={(e) => {
            // e.preventDefault();
            onCheckboxChange(groupId, item);
          }}
        />
    )});

    // let filterChunkTen = _.chunk(list, 10);
    // return filterChunkTen[perPageSearch - 1].map((item, index) => {
    //   return (
    //     <MarketplaceFilterCheckboxImage>
    //       <SiteCheckboxImage
    //         value={item.value}
    //         checked={item.checked}
    //         image={PicSkill2}
    //         onChange={(e) => {
    //           // e.preventDefault();
    //           onCheckboxChange(groupId, item);
    //         }}
    //       />
    //     </MarketplaceFilterCheckboxImage>
    //   );
    // });
  };

  const _renderPageingBottomSearch = (list) => {
    let filterChunkTen = _.chunk(list, 10);

    return (
      <MarketplaceFilterSearchBottomContainer>
        skill{" "}
        {filterChunkTen.map((item, index) => {
          let start = index * 10 + 1;
          let end = start + item.length - 1;
          if (perPageSearch == index + 1) {
            return (
              <MarketplaceFilterSearchBottomContainer>
                <MarketplaceFilterSearchBottomContainerItemActive>
                  {start + " - " + end}
                </MarketplaceFilterSearchBottomContainerItemActive>
                {filterChunkTen.length - 1 == index ? "" : " |"}
              </MarketplaceFilterSearchBottomContainer>
            );
          } else {
            return (
              <MarketplaceFilterSearchBottomContainer>
                <MarketplaceFilterSearchBottomContainerItem
                  onClick={() => {
                    setPerPageSearch(index + 1);
                  }}
                >
                  {start + " - " + end}
                </MarketplaceFilterSearchBottomContainerItem>
                {filterChunkTen.length - 1 == index ? "" : " |"}
              </MarketplaceFilterSearchBottomContainer>
            );
          }
        })}
      </MarketplaceFilterSearchBottomContainer>
    );
  };

  const _renderContentSlide = (groupId, value, min, max) => {
    const marks = [];

    for (let i = min; i <= max; i++) {
      marks.push({
        value: i,
        label: i,
      });
    }

    return (
      <SiteSlider
        value={value}
        min={min}
        max={max}
        marks={marks}
        onChange={(e, newValue) => {
          console.log("[MarketplaceFilter] onChange => ", e, newValue);
          onSlideChange(groupId, newValue);
        }}
      />
    );
  };

  const _renderContentGridSelect = (groupId, list) => {
    let filterChunkTwo = _.chunk(list, 2);

    return filterChunkTwo.map((item, index) => {
      return (
        <Grid container spacing={2}>
          {_renderContentGridItemSelect(groupId, item)}
        </Grid>
      );
    });
  };

  const _renderContentGridItemSelect = (groupId, itemList) => {
    return itemList.map((item, index) => {
      return (
        <Grid item xs={6}>
          <MarketplaceFilterSelectSpan>
            {item.title}
          </MarketplaceFilterSelectSpan>

          <SiteSelect
            source={item.source}
            value={item.value}
            onChange={(e) => {
              console.log("[MarketplaceFilter] e => ", e);
              let newValue = e.target.value;
              onSelectChange(groupId, item, newValue);
            }}
          />
        </Grid>
      );
    });
  };

  const _renderContentGridCheckbox = (groupId, list) => {
    let filterChunkTwo = _.chunk(list, 2);

    return filterChunkTwo.map((item, index) => {
      return (
        <Grid container style={{ textAlign: "left" }}>
          {_renderContentGridCheckboxItem(groupId, item)}
        </Grid>
      );
    });
  };

  const _renderContentGridCheckboxItem = (groupId, itemList) => {
    return itemList.map((item, index) => {
      return (
        <Grid item xs={6}>
          <SiteCheckbox
            value={item.value}
            checked={item.checked}
            onChange={(e) => {
              // e.preventDefault();
              onCheckboxChange(groupId, item);
            }}
          />
        </Grid>
      );
    });
  };

  const _renderContentGridCheckboxColor = (groupId, list) => {
    let filterChunkTwo = _.chunk(list, 5);

    return filterChunkTwo.map((item, index) => {
      return (
        <div style={{ display: "flex" }}>
          {_renderContentGridCheckboxColorItem(groupId, item)}
        </div>
      );
    });
  };

  const _renderContentGridCheckboxColorItem = (groupId, itemList) => {
    return itemList.map((item, index) => {
      return (
        <div style={{ margin: "5px" }}>
          <SiteCheckboxColor
            color={item.value}
            checked={item.checked}
            onChange={(e) => {
              // e.preventDefault();
              onCheckboxChange(groupId, item);
            }}
          ></SiteCheckboxColor>
        </div>
      );
    });
  };

  return (
    <MarketplaceFilterPage id="dk-website-marketplace-page-dicer-filter">
      {_renderFilter()}
      <SiteButtonBule style={{marginTop: "20px"}}>SEARCH</SiteButtonBule>
    </MarketplaceFilterPage>
  );
}

export default MarketplaceFilter;
