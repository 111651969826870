import YouTube from "react-youtube";
import styled from "styled-components";
import CommonConstants from "../../../../resources/constants/CommonConstants";

export const ConHomeGamePlay = styled.div`
  text-align: center;
  align-items: center;
  justify-content: center;
  align-content: center;

  position: relative;
  width: 100%;
`;

export const ImgHomeGamePlay = styled.img`
  padding: 20px 10px 20px 10px;

  z-index: 2;
  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    display: none;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_TABLET_MIN}) and (max-width: ${CommonConstants.MIN_WIDTH_TABLET_MAX}) {
    display: none;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP}) and (max-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XL}) {
    max-width: 90%;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XL}) {
    max-width: 90%;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XXL}) {
    max-width: 63%;
  }
`;

export const VideoHomeGamePlay = styled(YouTube)`
  position: absolute;
  z-index: 3;
  top: 16%;
  left: 25%;
  width: 23.1%;
  height: 33%;
  border: 3px solid#a07751;
  border-radius: 30px;
  padding: 20px;
  background: #2d221e;

  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    display: none;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_TABLET_MIN}) and (max-width: ${CommonConstants.MIN_WIDTH_TABLET_MAX}) {
    display: none;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP}) and (max-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XL}) {
    left: 17%;
    width: 30%;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XL}) {
    left: 17%;
    width: 30%;
  } 

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XXL}) {
    left: 25%;
    width: 23.1%;
  } 

  // @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP_2K}) {
  //   left: 22%;
  //   width: 30%;
  //   top: 17%;
  //   height: 35%;
  //   padding: 20px;
  // }

  // @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP_2800PX}) {
  //   left: 25%;
  //   width: 30%;
  //   top: 17%;
  //   height: 35%;
  //   width: 26.5%;
  //   padding: 20px;
  // }
`;

export const ImgHomeGamePlayMobile = styled.img`
  padding: 20px 10px 20px 10px;
  z-index: 2;

  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    max-width: 90%;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_TABLET_MIN}) and (max-width: ${CommonConstants.MIN_WIDTH_TABLET_MAX}) {
    max-width: 90%;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP}) and (max-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XL}) {
    display: none;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XL}) {
    display: none;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XXL}) {
    display: none;
  }
`;

export const VideoHomeGamePlayMobile = styled(YouTube)`
  position: absolute;
  z-index: 3;
  top: 36%;
  left: 17%;
  width: 61%;
  height: 27%;
  border: 3px solid#a07751;
  border-radius: 30px;
  padding: 20px;
  background: #2d221e;

  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    max-width: 90%;
    padding: 10px;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_TABLET_MIN}) and (max-width: ${CommonConstants.MIN_WIDTH_TABLET_MAX}) {
    max-width: 90%;
    padding: 15px;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP}) and (max-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XL}) {
    display: none;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XL}) {
    display: none;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XXL}) {
    display: none;
  }
`;

export const ConIconDownloadHomeGamePlay = styled.div`
  position: absolute;
  top: 40%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  left: 52%;
  z-index: 3;
  width: 25%;

  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    display: none;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_TABLET_MIN}) and (max-width: ${CommonConstants.MIN_WIDTH_TABLET_MAX}) {
    display: none;
  }
  @media (max-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XL}) {
    left: 60%;
  }
`;

export const IconAppDownloadHomeGamePlay = styled.img`
  cursor: pointer;
  max-height: 40px;
  margin: 10px;
  margin-left: 5px;
  margin-right: 5px;
  /* @media (max-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XL}) {
    max-height: 30px;
  } */
`;
