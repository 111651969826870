import styled from "styled-components";
import CommonConstants from "../../../../resources/constants/CommonConstants";

export const OverviewMarketTitleContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  /* padding: 2rem; */
  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    flex-direction: column;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_TABLET_MIN}) and (max-width: ${CommonConstants.MIN_WIDTH_TABLET_MAX}) {
    flex-direction: column;
  }

`;

export const OverviewMarketTitleText = styled.div`
  color: #ffffff;
  font-size: 3rem;
  font-weight: bold;
  width: 100%;

  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    font-size: 1.5rem;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_TABLET_MIN}) and (max-width: ${CommonConstants.MIN_WIDTH_TABLET_MAX}) {
    font-size: 1.5rem;
  }
`;

export const OverviewMarketTitleTapBtn = styled.div`
  float: right;
  text-align: right;
  justify-content: flex-end;
  align-content: flex-end;
  position: relative;

  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    display: none;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_TABLET_MIN}) and (max-width: ${CommonConstants.MIN_WIDTH_TABLET_MAX}) {
    display: none;
  }
`;

export const OverviewMarketTitleTapBtnMoblie = styled.div`
  display: none;
  margin-top: 0.5rem;
  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    display: block;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_TABLET_MIN}) and (max-width: ${CommonConstants.MIN_WIDTH_TABLET_MAX}) {
    display: block;
  }
`;
