import { Menu, MenuItem } from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import CommonConstants from "../../../resources/constants/CommonConstants";
import DemoPlayConstants from "../../../resources/constants/DemoPlayConstants";
import DemoPlayMenuMaster from "../master/DemoPlayMenuMaster";

/**
 *
 * @param {Props} props
 * @returns {React.FC}
 */
function DemoPlayMiniMenu(props) {
  const { activeMenu, onMenuChange } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const activeMenuData = DemoPlayMenuMaster[activeMenu];

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuChange = (menuName) => {
    if (onMenuChange) {
      onMenuChange(menuName);
      handleMenuClose();
    }
  };

  return (
    <Container>
      <MenuBox onClick={handleMenuClick}>
        <MenuIcon src={activeMenuData?.ICON} />
        <MenuText>{activeMenuData?.NAME}</MenuText>
        <MenuAction className="material-icons">arrow_drop_down</MenuAction>
      </MenuBox>
      <MenuList anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleMenuClose}>
        <MenuListItem onClick={() => handleMenuChange(DemoPlayConstants.MENU.GAME)}>
          Game
        </MenuListItem>
        <MenuListItem onClick={() => handleMenuChange(DemoPlayConstants.MENU.NEWS)}>
          News
        </MenuListItem>
        <MenuListItem onClick={() => handleMenuChange(DemoPlayConstants.MENU.VIDEO)}>
          Video
        </MenuListItem>
        <MenuListItem onClick={() => handleMenuChange(DemoPlayConstants.MENU.LIBRARY)}>
          Library
        </MenuListItem>
      </MenuList>
    </Container>
  );
}

export default DemoPlayMiniMenu;

const Container = styled.div`
  display: none;
  margin-bottom: 2rem;

  @media (max-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XL}) {
    display: block;
  }
`;

const MenuBox = styled.div`
  display: flex;
  flex-direction: row;
  border: 2px solid #ffa300;
  border-radius: 0.6rem;
  background: #1c0c0a;
  width: fit-content;
  padding: 1rem;
  cursor: pointer;
`;

const MenuIcon = styled.img`
  width: 2rem;
  height: 2rem;
  object-fit: contain;
`;

const MenuText = styled.div`
  font-size: 1.25rem;
  font-weight: bold;
  color: #ffa300;
  margin-left: 2rem;
  min-width: 4.5rem;
`;

const MenuAction = styled.span`
  margin-left: 2rem;
  color: #ffa300;
  font-size: 2rem;
`;

const MenuList = styled(Menu)``;

const MenuListItem = styled(MenuItem)`
  width: 14.5rem !important;
`;

class Props {
  constructor() {
    this.activeMenu = "";
    this.onMenuChange = () => {};
  }
}
