import styled from "styled-components";
import CommonConstants from "../../../../../../resources/constants/CommonConstants";
import DialogBgDesktopImage from "../../../../../../resources/images/4-Marketplace/BG-Dicer-Detail.png";
import DialogBgMobileImage from "../../../../../../resources/images/4-Marketplace/BG-Detail-Mobile.png";

export const MarketplaceDialogRoot = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #00000080;
  z-index: 999;
  display: ${(props) => (props.open ? "block" : "none")};
  animation: dialog-fade-in 0.3s ease-out;
  overflow-y: auto;
  overflow-x: hidden;

  @keyframes dialog-fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

export const MarketplaceDialogContainer = styled.div`
  position: relative;
  max-width: 900px;
  background: url(${DialogBgDesktopImage}), linear-gradient(#dbd2ca, #ffcc8f);
  background-size: cover;
  background-position: center center;
  /* background: linear-gradient(#dbd2ca, #ffcc8f); */
  /* border: 2px solid #916437; */
  border-radius: 1rem;
  overflow: hidden;
  padding: 1.5rem 2rem;
  margin: 3rem auto;

  @media (max-width: ${CommonConstants.MIN_WIDTH_DESKTOP}) {
    max-width: 290px;
    background-image: url(${DialogBgMobileImage});
  }
`;

export const MarketplaceDialogTitle = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
`;

export const MarketplaceDialogCloseButton = styled.span`
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  cursor: pointer;
  color: #440703;
`;

export const MarketplaceDialogContent = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 2rem;
  margin-top: 1rem;

  @media (max-width: ${CommonConstants.MIN_WIDTH_DESKTOP}) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
`;

export const MarketplaceDialogSectionOne = styled.div`
  display: grid;
  grid-template-columns: auto;
  justify-content: center;
  grid-auto-rows: min-content;
`;

export const MarketplaceDialogSectionTwo = styled.div`
  display: block;
`;

export const MarketplaceDialogDicerImageContainer = styled.div`
  width: 15rem;
  height: 15rem;
  background: #665854;
  padding: 2.5rem 1.5rem;
  border: 2px solid #af8068;
  border-radius: 0.6rem;
`;

export const MarketplaceDialogItemImageContainer = styled.div`
  width: 15rem;
  height: 15rem;
  background: #665854;
  padding: 2.5rem 1.5rem;
  border: 2px solid #af8068;
  border-radius: 0.6rem;
  position: relative;
`;

export const MarketplaceDialogItemClass = styled.div`
  position: absolute;
  bottom: 0.5rem;
  right: 1rem;
  color: #fadcc1;
  font-size: 1.3rem;
  font-weight: bold;
`;

export const MarketplaceDialogDicerImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const MarketplaceDialogBuyContent = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 0.5rem;
  justify-content: center;
  margin-top: 1rem;
`;

export const MarketplaceDialogBuyIcon = styled.img`
  width: 2.2rem;
  height: 2.2rem;
  object-fit: contain;
`;

export const MarketplaceDialogBuyPriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const MarketplaceDialogBuyPrice = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
`;

export const MarketplaceDialogBuyDiscount = styled.div`
  color: #4e0904;
`;

export const MarketplaceDialogBuyButton = styled.button`
  width: 9rem;
  height: 3rem;
  font-size: 1.3rem;
  font-weight: bold;
  border-radius: 0.6rem;
  border: 2px solid #653127;
  background: #2291ff;
  margin-left: 2rem;
  cursor: pointer;
  color: #4e0904;

  &:focus {
    background: #1d7ad7;
    box-shadow: 0 0 0 4px #00000030;
  }
`;

export const MarketplaceDialogDetailContainer = styled.div``;

export const MarketplaceDialogDetailTitle = styled.div`
  font-size: 1.25rem;
  font-weight: bold;
`;

export const MarketplaceDialogDetailOwner = styled.div`
  color: #4e0904;
`;

export const MarketplaceDialogDetailItemDetail = styled.div`
  font-size: 1.25rem;
  font-weight: bold;
  margin-top: 10px;
`;

export const MarketplaceDialogDetailItemTotalSupply = styled.div`
  font-size: 1.25rem;
  font-weight: bold;
  /* margin-top: 10px; */
`;

export const MarketplaceDialogItemDescription = styled.div`
  padding: 1rem 1.5rem 1rem 1.5rem;
  background-color: #af8068;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-radius: 0.6rem;
  color: #FFFFFF;
`;
