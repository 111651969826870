import styled from "styled-components";
import CommonConstants from "../../../../resources/constants/CommonConstants";
import SiteTextH1 from "../../../../resources/components/Text/SiteTextH1";

export const ConHomeHowToEarn = styled.div`
  text-align: center;
  align-items: center;
  justify-content: center;
  align-content: center;

  width: 100%;
`;

export const ImgHomeHowToEarn = styled.img`
  padding: 20px 10px 20px 10px;
  max-width: 42%;
  max-height: 95px;
  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    max-width: 60%;
    max-height: unset;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_TABLET_MIN}) and (max-width: ${CommonConstants.MIN_WIDTH_TABLET_MAX}) {
    max-width: 60%;
    max-height: unset;
  }
`;

export const HomeHowToEarnContent = styled.div`
  display: flex;
  background: #65656526;
  border: 1px solid #65656526;
  border-radius: 15px;

  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    padding: 10px;
    margin-top: 20px;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_TABLET_MIN}) and (max-width: ${CommonConstants.MIN_WIDTH_TABLET_MAX}) {
    padding: 10px;
    margin-top: 20px;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP}) and (max-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XL}) {
    padding: 20px;
    //margin-top: 50px;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XL}) {
    padding: 20px;
    //margin-top: 50px;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XXL}) {
    padding: 20px;
    //margin-top: 50px;
  }
`;

export const HomeHowToEarnContentText = styled(SiteTextH1)`
  text-align: left;
  color: #ffecd1;
  flex-grow: 1;
  font-weight: unset;
  align-self: center;

  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    padding: 0px;
    margin-top: 15px;
    margin-right: 10px;
    margin-left: 10px;
    margin-bottom: 15px;
    text-align: center;
    font-size: 16px !important;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_TABLET_MIN}) and (max-width: ${CommonConstants.MIN_WIDTH_TABLET_MAX}) {
    padding: 0px;
    margin-top: 15px;
    margin-right: 10px;
    margin-left: 10px;
    margin-bottom: 15px;
    text-align: center;
    font-size: 16px !important;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP}) and (max-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XL}) {
    padding: 20px;
    //margin-top: 50px;
    margin-right: 20px;
    margin-left: 50px;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XL}) {
    padding: 20px;
    //margin-top: 50px;
    margin-right: 20px;
    margin-left: 50px;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XXL}) {
    padding: 20px;
    //margin-top: 50px;
    margin-right: 20px;
    margin-left: 50px;
  }
`;

export const HomeHowToEarnContentImg = styled.img`
  margin-top: -80px;
  margin-right: 50px;

  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    display: none;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_TABLET_MIN}) and (max-width: ${CommonConstants.MIN_WIDTH_TABLET_MAX}) {
    display: none;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP}) and (max-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XL}) {
    display: none;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XL}) {
    height: 280px;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XXL}) {
    height: 280px;
  }
`;

export const HomeHowToEarnContentImgMobile = styled.img`
  margin-top: 20px;

  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    max-width: 50%;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_TABLET_MIN}) and (max-width: ${CommonConstants.MIN_WIDTH_TABLET_MAX}) {
    max-width: 50%;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP}) and (max-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XL}) {
    max-width: 50%;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XL}) {
    display: none;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XXL}) {
    display: none;
  }
`;
