import styled from "styled-components";
import CommonConstants from "../../../../resources/constants/CommonConstants";
import {
  HOME_RAY_ANIM
} from "../../resources/style/style";

export const ConHomeBanner = styled.div `
  text-align: center;
  align-items: center;
  justify-content: center;
  align-content: center;
  position: relative;
`;

export const ImageBannerBG = styled.img `
  width: 100%;
`;

export const ImageBannerCharacter = styled.img `
  position: absolute;
  width: 20.5%;
  left: 38%;
  top: 20%;
  z-index: 2;
`;

export const ImageBannerLogo = styled.img `
  position: absolute;
  // width: 38.5%;
  width: 26.5%;
  left: 36.5%;
  top: 40%;
  z-index: 3;
`;

export const ImgRaylightHomeBanner = styled.img `
  position: absolute;

  z-index: 1;
  -webkit-animation: ${HOME_RAY_ANIM} 40s linear infinite;
  animation: ${HOME_RAY_ANIM} 40s linear infinite;

  width: 42%;
  left: 28%;
  top: 0%;
`;


export const PlayNowButton = styled.img `
  position: absolute;
  width: 35.5%;
  left: 32%;
  top: 70%;
  z-index: 3;
`;