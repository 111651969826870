import React from "react";
import Grid from "@material-ui/core/Grid";
import _ from "lodash";
import {
  MarketplaceDicerItemPage,
  MarketplaceDicerItemContent,
  MarketplaceDicerItemContentTitle,
  MarketplaceDicerItemContentSubTitle,
  MarketplaceDicerItemContentImageContainer,
  MarketplaceDicerItemContentImage,
  MarketplaceDicerItemContentNumberRoman,
  MarketplaceDicerItemContentMoney,
  MarketplaceDicerItemContentMoneyIcon,
  MarketplaceDicerItemContentMoneyNumber,
  MarketplaceDicerItemContentMoneyDolla,
  MarketplaceDicerItemContentListView,
  MarketplaceDicerItemContentContainerListView,
  MarketplaceDicerItemContentTextTopicListView,
  MarketplaceDicerItemContentTextListView,
  MarketplaceDicerItemContentMoneyIconListView,
  MarketplaceDicerItemContentMoneyNumberListView,
  MarketplaceDicerItemContentImageListView,
  MarketplaceDicerItemContentListViewMobile,
  MarketplaceDicerItemContentImageContainerMobile,
  MarketplaceDicerItemListViewMobile,
  MarketplaceEquipmentDetailItemContent,
} from "./MarketplaceDicerItem.component";
import CharacterBear from "../../../../../../resources/images/3-Dicer/coala.png";
// import CharacterBear from "../../../../../../resources/images/1-Home/Character-Bear.png";
import CursorRoadmap from "../../../../../../resources/images/4-Marketplace/busd.png";
// import CursorRoadmap from "../../../../../../resources/images/1-Home/Cursor-Roadmap.png";
import Capsule from "../../../../../../resources/images/4-Marketplace/Capsule.png";
import SitePagination from "../../../../../../resources/components/SitePagination/SitePagination";

import DicerSearchResult from "../../../../../../resources/model/Marketplace/DicerSearchResult.js";

function MarketplaceDicerItem(props) {
  /**
   * @type {[DicerSearchResult[], Number, Number, Function, Number, Function]}
   */
  const {
    listItem = [],
    viewType,
    perPage,
    onClickItem,
    currentPage,
    setCurrentPage,
    indexActiveTapMarketplaceTap,
  } = props;
  /**
   * @type {Function}
   * @returns {React.Component}
   */

  const _renderSubTitleMarketplaceDicerItemContent = (item) => {
    if (indexActiveTapMarketplaceTap === 0) {
      if (item?.type == "dicer") {
        return `Clone: ${item.clone} Cards: ${item.skill}`;
      } else {
        return <div>&nbsp;</div>;
      }
    } else if (indexActiveTapMarketplaceTap === 1) {
      return `Calss ${item.class}`;
    } else if (indexActiveTapMarketplaceTap === 2) {
      return `Calss ${item.class}`;
    } else if (indexActiveTapMarketplaceTap === 3) {
      return `Calss ${item.class}`;
    } else {
      return ``;
    }
  };

  /**
   * @type {Function}
   * @returns {React.Component}
   */
  const _renderTitleMarketplaceDicerItemContent = (item) => {
    if (indexActiveTapMarketplaceTap === 0) {
      return (
        <MarketplaceDicerItemContentTitle>
          Dicer #{item.id}
        </MarketplaceDicerItemContentTitle>
      );
    } else if (indexActiveTapMarketplaceTap === 1) {
      return (
        <MarketplaceDicerItemContentTitle>
          {item.equip} #{item.id}
        </MarketplaceDicerItemContentTitle>
      );
    } else if (indexActiveTapMarketplaceTap === 2) {
      return (
        <MarketplaceDicerItemContentTitle>
          {item.equip} #{item.id}
        </MarketplaceDicerItemContentTitle>
      );
    } else if (indexActiveTapMarketplaceTap === 3) {
      return (
        <MarketplaceDicerItemContentTitle>
          {item.equip} #{item.id}
        </MarketplaceDicerItemContentTitle>
      );
    } else {
      return null;
    }
  };

  /**
   * @type {Function}
   * @returns {React.Component}
   */
  const _renderDetailMarketplaceDicerItemContent = (item) => {
    if (indexActiveTapMarketplaceTap === 0) {
      return (
        <MarketplaceDicerItemContentNumberRoman>
          {item.parts.map((partItem) => {
            return partItem + " ";
          })}
        </MarketplaceDicerItemContentNumberRoman>
      );
    } else if (indexActiveTapMarketplaceTap === 1) {
      return (
        <MarketplaceEquipmentDetailItemContent>
          {item.detail}
        </MarketplaceEquipmentDetailItemContent>
      );
    } else if (indexActiveTapMarketplaceTap === 2) {
      return (
        <MarketplaceEquipmentDetailItemContent>
          {item.detail}
        </MarketplaceEquipmentDetailItemContent>
      );
    } else if (indexActiveTapMarketplaceTap === 3) {
      return (
        <MarketplaceEquipmentDetailItemContent>
          {item.detail}
        </MarketplaceEquipmentDetailItemContent>
      );
    } else {
      return null;
    }
  };

  /**
   * @type {Function}
   * @returns {React.Component}
   */
  const _reanderItemGridView = () => {
    let list = _.chunk([...listItem], perPage)[currentPage - 1];
    list = list ? list : [];

    return list.map((item, index) => {
      return (
        <Grid
          item
          xl={2}
          xs={6}
          lg={3}
          md={4}
          sm={6}
          key={"_reanderItemGridView" + index}
        >
          <MarketplaceDicerItemContent
            onClick={() => {
              onClickItem(item);
            }}
          >
            {_renderTitleMarketplaceDicerItemContent(item)}

            <MarketplaceDicerItemContentSubTitle>
              {_renderSubTitleMarketplaceDicerItemContent(item)}
            </MarketplaceDicerItemContentSubTitle>

            <MarketplaceDicerItemContentImageContainer>
              <MarketplaceDicerItemContentImage
                src={
                  item.image
                    ? item.image
                    : item.type == "dicer"
                    ? CharacterBear
                    : Capsule
                }
              />
              {_renderDetailMarketplaceDicerItemContent(item)}
            </MarketplaceDicerItemContentImageContainer>
            <MarketplaceDicerItemContentMoney>
              <MarketplaceDicerItemContentMoneyIcon src={CursorRoadmap} />
              <MarketplaceDicerItemContentMoneyNumber>
                {item.price}
              </MarketplaceDicerItemContentMoneyNumber>
              <MarketplaceDicerItemContentMoneyDolla>
                ~${item.priceUS}
              </MarketplaceDicerItemContentMoneyDolla>
            </MarketplaceDicerItemContentMoney>
          </MarketplaceDicerItemContent>
        </Grid>
      );
    });
  };

  /**
   * @type {Function}
   * @returns {React.Component}
   */
  const _reanderItemListView = () => {
    let list = _.chunk([...listItem], perPage)[currentPage - 1];
    list = list ? list : [];

    return list.map((item, index) => {
      return (
        <Grid item xs={12} key={"_reanderItemListView" + index}>
          <MarketplaceDicerItemContentListView
            onClick={() => {
              onClickItem(item);
            }}
          >
            <MarketplaceDicerItemContentContainerListView>
              <MarketplaceDicerItemContentImageListView
                src={
                  item.image
                    ? item.image
                    : item.type === "dicer"
                    ? CharacterBear
                    : Capsule
                }
              />
              {/* <MarketplaceDicerItemContentNumberRoman>
                {item.parts.map((partItem) => {
                  return partItem + " ";
                })}
              </MarketplaceDicerItemContentNumberRoman> */}

              {_renderDetailMarketplaceDicerItemContent(item)}
            </MarketplaceDicerItemContentContainerListView>

            <MarketplaceDicerItemContentContainerListView>
              <MarketplaceDicerItemContentTitle>
                Dicer #{item.id}
              </MarketplaceDicerItemContentTitle>

              {item?.type !== "capsule" ? (
                <MarketplaceDicerItemContentSubTitle>
                  Clone: {item.clone}
                </MarketplaceDicerItemContentSubTitle>
              ) : null}

              {item?.type !== "capsule" ? (
                <MarketplaceDicerItemContentSubTitle>
                  Cards: {item.skill}
                </MarketplaceDicerItemContentSubTitle>
              ) : null}
            </MarketplaceDicerItemContentContainerListView>

            <MarketplaceDicerItemContentContainerListView>
              <MarketplaceDicerItemContentContainerListView>
                <MarketplaceDicerItemContentTextTopicListView>
                  Wealth:
                </MarketplaceDicerItemContentTextTopicListView>
                <MarketplaceDicerItemContentTextListView>
                  {item.wealth}
                </MarketplaceDicerItemContentTextListView>
              </MarketplaceDicerItemContentContainerListView>

              <MarketplaceDicerItemContentContainerListView>
                <MarketplaceDicerItemContentTextTopicListView>
                  Manage:
                </MarketplaceDicerItemContentTextTopicListView>
                <MarketplaceDicerItemContentTextListView>
                  {item.wealth}
                </MarketplaceDicerItemContentTextListView>
              </MarketplaceDicerItemContentContainerListView>
            </MarketplaceDicerItemContentContainerListView>

            <MarketplaceDicerItemContentContainerListView>
              <MarketplaceDicerItemContentContainerListView>
                <MarketplaceDicerItemContentTextTopicListView>
                  Devotion:
                </MarketplaceDicerItemContentTextTopicListView>
                <MarketplaceDicerItemContentTextListView>
                  {item.devotion}
                </MarketplaceDicerItemContentTextListView>
              </MarketplaceDicerItemContentContainerListView>

              <MarketplaceDicerItemContentContainerListView>
                <MarketplaceDicerItemContentTextTopicListView>
                  Luck:
                </MarketplaceDicerItemContentTextTopicListView>
                <MarketplaceDicerItemContentTextListView>
                  {item.luck}
                </MarketplaceDicerItemContentTextListView>
              </MarketplaceDicerItemContentContainerListView>
            </MarketplaceDicerItemContentContainerListView>

            <MarketplaceDicerItemContentContainerListView>
              <MarketplaceDicerItemContentContainerListView>
                <MarketplaceDicerItemContentTextTopicListView>
                  Charm:
                </MarketplaceDicerItemContentTextTopicListView>
                <MarketplaceDicerItemContentTextListView>
                  {item.charm}
                </MarketplaceDicerItemContentTextListView>
              </MarketplaceDicerItemContentContainerListView>

              <MarketplaceDicerItemContentContainerListView>
                <MarketplaceDicerItemContentTextTopicListView>
                  Cards:
                </MarketplaceDicerItemContentTextTopicListView>
                <MarketplaceDicerItemContentTextListView>
                  {item.skill}
                </MarketplaceDicerItemContentTextListView>
              </MarketplaceDicerItemContentContainerListView>
            </MarketplaceDicerItemContentContainerListView>

            <MarketplaceDicerItemContentContainerListView>
              <div style={{ display: "flex", alignItems: "center" }}>
                <MarketplaceDicerItemContentMoneyIconListView
                  src={CursorRoadmap}
                />
                <MarketplaceDicerItemContentMoneyNumberListView>
                  {item.price}
                </MarketplaceDicerItemContentMoneyNumberListView>
              </div>

              <MarketplaceDicerItemContentMoneyDolla>
                ~${item.priceUS}
              </MarketplaceDicerItemContentMoneyDolla>
            </MarketplaceDicerItemContentContainerListView>
          </MarketplaceDicerItemContentListView>

          <MarketplaceDicerItemContentListViewMobile
            onClick={() => {
              onClickItem(item);
            }}
          >
            <MarketplaceDicerItemListViewMobile style={{ minWidth: "100px" }}>
              <MarketplaceDicerItemContentTitle>
                Dicer #{item.id}
              </MarketplaceDicerItemContentTitle>

              {item?.type !== "capsule" ? (
                <MarketplaceDicerItemContentSubTitle>
                  Clone: {item.clone} Cards: {item.skill}
                </MarketplaceDicerItemContentSubTitle>
              ) : null}

              <MarketplaceDicerItemContentImageContainerMobile>
                <MarketplaceDicerItemContentImage
                  src={
                    item.image
                      ? item.image
                      : item.type == "dicer"
                      ? CharacterBear
                      : Capsule
                  }
                />
                {/* <MarketplaceDicerItemContentNumberRoman>
                  {item.parts.map((partItem) => {
                    return partItem + " ";
                  })}
                </MarketplaceDicerItemContentNumberRoman> */}

                {_renderDetailMarketplaceDicerItemContent(item)}
              </MarketplaceDicerItemContentImageContainerMobile>
            </MarketplaceDicerItemListViewMobile>

            <MarketplaceDicerItemListViewMobile
              style={{ justifyContent: "center" }}
            >
              <Grid container style={{ textAlign: "left" }} spacing={(1, 0)}>
                <Grid item xs={6}>
                  <MarketplaceDicerItemContentContainerListView>
                    <MarketplaceDicerItemContentTextTopicListView>
                      Wealth:
                    </MarketplaceDicerItemContentTextTopicListView>
                    <MarketplaceDicerItemContentTextListView>
                      {item.wealth}
                    </MarketplaceDicerItemContentTextListView>
                  </MarketplaceDicerItemContentContainerListView>
                </Grid>
                <Grid item xs={6}>
                  <MarketplaceDicerItemContentContainerListView>
                    <MarketplaceDicerItemContentTextTopicListView>
                      Manage:
                    </MarketplaceDicerItemContentTextTopicListView>
                    <MarketplaceDicerItemContentTextListView>
                      {item.wealth}
                    </MarketplaceDicerItemContentTextListView>
                  </MarketplaceDicerItemContentContainerListView>
                </Grid>
                <Grid item xs={6}>
                  <MarketplaceDicerItemContentContainerListView>
                    <MarketplaceDicerItemContentTextTopicListView>
                      Devotion:
                    </MarketplaceDicerItemContentTextTopicListView>
                    <MarketplaceDicerItemContentTextListView>
                      {item.devotion}
                    </MarketplaceDicerItemContentTextListView>
                  </MarketplaceDicerItemContentContainerListView>
                </Grid>
                <Grid item xs={6}>
                  <MarketplaceDicerItemContentContainerListView>
                    <MarketplaceDicerItemContentTextTopicListView>
                      Luck:
                    </MarketplaceDicerItemContentTextTopicListView>
                    <MarketplaceDicerItemContentTextListView>
                      {item.luck}
                    </MarketplaceDicerItemContentTextListView>
                  </MarketplaceDicerItemContentContainerListView>
                </Grid>
                <Grid item xs={6}>
                  <MarketplaceDicerItemContentContainerListView>
                    <MarketplaceDicerItemContentTextTopicListView>
                      Charm:
                    </MarketplaceDicerItemContentTextTopicListView>
                    <MarketplaceDicerItemContentTextListView>
                      {item.charm}
                    </MarketplaceDicerItemContentTextListView>
                  </MarketplaceDicerItemContentContainerListView>
                </Grid>
                <Grid item xs={6}>
                  <MarketplaceDicerItemContentContainerListView>
                    <MarketplaceDicerItemContentTextTopicListView>
                      Cards:
                    </MarketplaceDicerItemContentTextTopicListView>
                    <MarketplaceDicerItemContentTextListView>
                      {item.skill}
                    </MarketplaceDicerItemContentTextListView>
                  </MarketplaceDicerItemContentContainerListView>
                </Grid>
              </Grid>
              <MarketplaceDicerItemContentContainerListView
                style={{ textAlign: "center" }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    textAlign: "center",
                    justifyContent: "center",
                  }}
                >
                  <MarketplaceDicerItemContentMoneyIconListView
                    src={CursorRoadmap}
                  />
                  <MarketplaceDicerItemContentMoneyNumberListView>
                    {item.price}
                  </MarketplaceDicerItemContentMoneyNumberListView>
                </div>

                <MarketplaceDicerItemContentMoneyDolla>
                  ~${item.priceUS}
                </MarketplaceDicerItemContentMoneyDolla>
              </MarketplaceDicerItemContentContainerListView>
            </MarketplaceDicerItemListViewMobile>
          </MarketplaceDicerItemContentListViewMobile>
        </Grid>
      );
    });
  };

  /**
   * @type {Function}
   * @returns {React.Component}
   */
  const _renderPerViewType = () => {
    console.log("_renderPerViewType viewType => ", viewType);
    if (viewType == 0) {
      return _reanderItemGridView();
    } else {
      return _reanderItemListView();
    }
  };

  /**
   * @type {Function}
   * @param {Object} event
   * @param {Number} newCurrentPage
   * @param {Number} oldCurrentPage
   */
  const onChangePageOfListItem = (event, newCurrentPage, oldCurrentPage) => {
    setCurrentPage(newCurrentPage);
  };

  return (
    <MarketplaceDicerItemPage id="dk-website-marketplace-page-dicer-section-item">
      <Grid container style={{ textAlign: "left" }} spacing={3}>
        {_renderPerViewType()}
      </Grid>
      <SitePagination
        currentPage={currentPage}
        totalPage={Math.ceil(listItem.length / perPage)}
        onChangePage={onChangePageOfListItem}
      />
    </MarketplaceDicerItemPage>
  );
}

export default MarketplaceDicerItem;
