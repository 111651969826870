import React from "react";
import PaperHome from "../../../../resources/images/1-Home/Paper-Home.png";
import PaperHomeMobile from "../../../../resources/images/1-Home/Paper-Home-mobile.png";
import ButtonGooglePlay from "../../../../resources/images/1-Home/Button-GooglePlay-Comingsoon.png";
import ButtonAppStore from "../../../../resources/images/1-Home/Button-Appstore-comingsoon.png";
import ButtonWeb from "../../../../resources/images/1-Home/Button-web.png";
import URLConstants from "../../../../resources/constants/URLConstants";
import {
  ConHomeGamePlay,
  ImgHomeGamePlay,
  ImgHomeGamePlayMobile,
  VideoHomeGamePlay,
  VideoHomeGamePlayMobile,
  ConIconDownloadHomeGamePlay,
  IconAppDownloadHomeGamePlay,
} from "./HomeGamePlay.component";

function HomeGamePlay() {
  const optsYoutube = {
    playerVars: {
      // autoplay: 1,
    },
  };

  const _onReady = (event) => {
    // console.log(event.target)
    // event.target.pauseVideo();
  };

  const androidPlay = () => {
    window.open('https://play.google.com/store/apps/details?id=com.dreamdice.dicekingdom&fbclid=IwZXh0bgNhZW0CMTAAAR0WWQyj9veQphXZw-7relSo3Fl_NoptaPWa-LUPDE8Ja7ynf3J5Qws8epQ_aem_ATbWUfMIoJsFd5tBsnOYijNiOfoOw2VpOJ7aOb2wSinkj1IXTkQJCTFc3d3h41mIgC1lQNv4rUXJpKDa_ASaRMHW') 
  }

  const applePlay = () => {
    window.open('https://apps.apple.com/th/app/dice-kingdom/id1136786743?l=th')
  }


  return (
    <ConHomeGamePlay id="dk-website-home-page-game-play">
      <ImgHomeGamePlay src={PaperHome}></ImgHomeGamePlay>
      <ImgHomeGamePlayMobile src={PaperHomeMobile}></ImgHomeGamePlayMobile>
      <VideoHomeGamePlay
        videoId={URLConstants.URL_VIDEO_HOME}
        opts={optsYoutube}
        onReady={_onReady}
      ></VideoHomeGamePlay>
      <VideoHomeGamePlayMobile
        videoId={URLConstants.URL_VIDEO_HOME}
        opts={optsYoutube}
        onReady={_onReady}
      ></VideoHomeGamePlayMobile>
      <ConIconDownloadHomeGamePlay>
        <a href="https://play.dicekingdom.com">
        <IconAppDownloadHomeGamePlay src={ButtonWeb}/>
        </a>
        <IconAppDownloadHomeGamePlay src={ButtonAppStore} onClick={applePlay} />
        <IconAppDownloadHomeGamePlay src={ButtonGooglePlay} onClick={androidPlay} />
      </ConIconDownloadHomeGamePlay>
    </ConHomeGamePlay>
  );
}

export default HomeGamePlay;
