import React from "react";
import styled from "styled-components";
import CommonConstants from "../../../../../../../resources/constants/CommonConstants";
import { DicerDetail_Skill } from "../../../../../../../resources/model/Marketplace/DicerDetail";
import SkillImageResource from "../../../../../../../resources/images/4-Marketplace/Pic-Skill2.png";

/**
 *
 * @param {Props} props
 * @returns {React.FC}
 */
function MarketplaceDialogSkill(props) {
  const { skills = [] } = props;
  return (
    <Container>
      <Title>Cards</Title>
      <Content>
        <Row>
          {skills.map((item, index) => (
            <Col key={"skill_" + index}>
              <SkillImage src={SkillImageResource} />
              <SkillDetail>Ability</SkillDetail>
            </Col>
          ))}
        </Row>
      </Content>
    </Container>
  );
}

export default MarketplaceDialogSkill;

const Container = styled.div`
  margin-top: 1rem;
`;

const Title = styled.div`
  font-weight: bold;
`;

const Content = styled.div`
  background: #af8068;
  border-radius: 0.6rem;
  padding: 1.5rem 2rem;
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;

  @media (max-width: ${CommonConstants.MIN_WIDTH_DESKTOP}) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const Col = styled.div`
  display: flex;
  flex-direction: row;
`;

const SkillImage = styled.img`
  width: 4rem;
  height: 4rem;
  object-fit: contain;
`;

const SkillDetail = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  font-size: 0.8rem;
  min-height: 4rem;
`;

class Props {
  constructor() {
    /**
     * @type {DicerDetail_Skill[]}
     */
    this.skills = [];
  }
}
