import React from "react";
import style from "./style/style";

const StyleSiteButtonBrownDark = style.SiteButtonBrownDark;

function SiteButtonBrownDark(props) {
  const { children, ...rest } = props;

  return (
    <StyleSiteButtonBrownDark
      active
      variant="contained"
      color="primary"
      disableRipple
      {...rest}
    >
      {children}
    </StyleSiteButtonBrownDark>
  );
}

export default SiteButtonBrownDark;
