import React, { useState, useEffect } from "react";
import styled from "styled-components";
import HomeBanner from "./components/HomeBanner/HomeBanner";
import HomeFeatures from "./components/HomeFeatures/HomeFeatures";
import HomeGameFlow from "./components/HomeGameFlow/HomeGameFlow";
import HomeGamePlay from "./components/HomeGamePlay/HomeGamePlay";
import HomeHighlight from "./components/HomeHighlight/HomeHighlight";
import HomeHowToEarn from "./components/HomeHowToEarn/HomeHowToEarn";
import HomeRoadmap from "./components/HomeRoadmap/HomeRoadmap";
import HomeOverview from "./components/HomeOverview/HomeOverview";
import HomeFooter from "./components/HomeFooter/HomeFooter";

import mockupRoadMap from "./mockup/homeRoadmap.mockup.json";
import Roadmap from "../../resources/model/Home/Roadmap";

function Home() {
  /**
   * @type {[Roadmap[], Function]}
   */
  const [roadmapList, setRoadmapList] = useState([]);
  /**
   * @type {[Number, Function]}
   */
  const [currentRoadmap, setCurrentRoadmap] = useState(0);

  useEffect(() => {
    // asynchronous call parallel apis
    fetchRoadmap();
  }, []);

  const fetchRoadmap = async () => {
    //TODO: fetch data from api
    setCurrentRoadmap(mockupRoadMap.step);
    setRoadmapList(mockupRoadMap.list);
  };

  return (
    <HomePage id="dk-website-home-page">
      <HomeBanner></HomeBanner>
      <HomeHighlight></HomeHighlight>
      <HomeGamePlay></HomeGamePlay>
      <HomeHowToEarn></HomeHowToEarn>
      <HomeFeatures></HomeFeatures>
      <HomeGameFlow></HomeGameFlow>
      <HomeOverview></HomeOverview>
      {/* <HomeRoadmap
        listStep={roadmapList}
        currentStep={currentRoadmap}
      ></HomeRoadmap> */}
      <HomeFooter></HomeFooter>
    </HomePage>
  );
}

export default Home;

const HomePage = styled.div`
  position: relative;
  z-index: 2;
`;
