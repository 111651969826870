import React, { useState, useEffect } from "react";
import {
  ConHomeBanner,
  ImageBannerBG,
  ImageBannerCharacter,
  ImageBannerLogo,
  ImgRaylightHomeBanner,
  PlayNowButton,
} from "./HomeBanner.component";
import BannerHome from "../../../../resources/images/1-Home/Banner-Home.png";
import DKCharacter from "../../../../resources/images/1-Home/DK-Character.png";
import DKLogo from "../../../../resources/images/1-Home/DK-logo.png";
import Raylight from "../../../../resources/images/1-Home/Raylight.png";
import PlayNow from "../../../../resources/images/1-Home/PlayNow.png";

import linkGameplay from "../../mockup/linkGamePlay.json";

function HomeBanner() {

  const [link, setLink] = useState("#");

  useEffect(() => {
    fetchLink();
  }, []);

  const fetchLink = async () => {
    setLink(linkGameplay.link);
  };

  return (
    <ConHomeBanner id="dk-website-home-page-banner">
      <ImageBannerBG src={BannerHome}></ImageBannerBG>
      <ImageBannerCharacter src={DKCharacter}></ImageBannerCharacter>
      <ImageBannerLogo src={DKLogo}></ImageBannerLogo>
      <ImgRaylightHomeBanner src={Raylight}></ImgRaylightHomeBanner>
      <a href={link}><PlayNowButton src={PlayNow}></PlayNowButton></a>
    </ConHomeBanner>

  );
}

export default HomeBanner;
