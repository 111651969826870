import {
  keyframes
} from "styled-components";

export const HOME_RAY_ANIM = keyframes `
0% {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}
100% {
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
}
`;