import React from "react";
import style from "./style/style";
import BoxSelect from "../../images/4-Marketplace/Box-Select.png";
import { MenuItem } from "@material-ui/core";
import { v4 as uuidv4 } from "uuid";

const StyleSiteSelect = style.SiteSelectNotSkew;
const StyleSiteSelectIconSelect = style.SiteSelectIconSelect;
const StyleSiteSelectOption = style.SiteSelectOption;

function SiteSelectNotSkew(props) {
  const { source, value, placeholder, onChange } = props;

  const _renderPlaceholder = () => {
    if (placeholder) {
      return (
        <StyleSiteSelectOption value="" disabled selected>
          {placeholder}
        </StyleSiteSelectOption>
      );
    } else {
      return null;
    }
  };

  const _renderOptionSelect = (itemList) => {
    return itemList.map((item, index) => {
      return (
        <StyleSiteSelectOption
          value={item.value}
          style={{ backgroundImage: `url(${BoxSelect}` }}
        >
          {item.display}
        </StyleSiteSelectOption>
      );
    });
  };

  return (
    <StyleSiteSelect value={value} onChange={(event) => onChange(event)}>
      {_renderPlaceholder()}
      {_renderOptionSelect(source)}
    </StyleSiteSelect>
  );
}

export default SiteSelectNotSkew;
