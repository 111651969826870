import React, { useState, useEffect } from "react";
import {
  OverviewMarketPage,
  OverviewMarketLatestSalesAndLatestListingsContainer,
} from "./resources/style/style";
import OverviewMarketTitle from "./components/OverviewMarketTitle/OverviewMarketTitle";
import OverviewMarketContent from "./components/OverviewMarketContent/OverviewMarketContent";
import OverviewMarketList from "./components/OverviewMarketList/OverviewMarketList";
import overviewOverAll from "./mockup/overviewOverAll.mockup.json";
import overviewList from "./mockup/overviewList.mockup.json";

function OverviewMarket() {
  useEffect(() => {
    // asynchronous call parallel apis
    fetchOverAllData();
    fetchOverviewList();
  }, []);

  const fetchOverAllData = async () => {
    //set mockup data
    setDataOverAll(overviewOverAll);
  };

  const fetchOverviewList = async () => {
    //set mockup data
    setDataOverviewList(overviewList);
  };

  /**
   * @type {Object, Function]}
   */
  const [dataOverAll, setDataOverAll] = useState({});

  /**
   * @type {Object, Function]}
   */
  const [dataOverviewList, setDataOverviewList] = useState({});

  /**
   * @type {String[], Function]}
   */
  const [tapOverviewMarketTitle, setTapOverviewMarketTitle] = useState([
    "All",
    "DICER",
    "Equipment",
    "Cosumable",
    "Etc.",
  ]);

  /**
   * @type {String[], Function]}
   */
  const [tapOverallStats, setTapOverallStats] = useState([
    "Last 24 h",
    "7 days",
    "30 days",
  ]);

  /**
   * @type {Number, Function]}
   */
  const [activeTapOverviewMarketTitle, setActiveTapOverviewMarketTitle] =
    useState(0);

  /**
   * @type {Number, Function]}
   */
  const [activeTapOverallStats, setActiveTapOverallStats] = useState(0);

  const onChangeTapOverviewMarketTitle = (index) => {
    setActiveTapOverviewMarketTitle(index);
  };

  const onChangeTapOverallStats = (index) => {
    setActiveTapOverallStats(index);
  };

  const onClickItemLatestSales = (e) => {
    console.log("onClickItemLatestSales", e);
  };

  const onClickItemLatestListings = (e) => {
    console.log("onClickItemLatestListings", e);
  };
  
  const onClickViewMoreLatestSales = (e) => {
    console.log("onClickViewMoreLatestSales", e);
  };

  const onClickViewMoreLatestListings = (e) => {
    console.log("onClickViewMoreLatestListings", e);
  };

  return (
    <OverviewMarketPage id="dk-website-overview-market-page">
      <OverviewMarketTitle
        tapOverviewMarketTitle={tapOverviewMarketTitle}
        activeTapOverviewMarketTitle={activeTapOverviewMarketTitle}
        onChangeTapOverviewMarketTitle={onChangeTapOverviewMarketTitle}
      />
      <OverviewMarketContent
        tapOverallStats={tapOverallStats}
        activeTapOverallStats={activeTapOverallStats}
        onChangeTapOverallStats={onChangeTapOverallStats}
        totalSale={dataOverAll?.totalSale}
        totalValume={dataOverAll?.totalValume}
        averagePrice={dataOverAll?.averagePrice}
        descriptionSale={dataOverAll?.descriptionSale}
        descriptionValume={dataOverAll?.descriptionValume}
        descriptionPrice={dataOverAll?.descriptionPrice}
      />
      <OverviewMarketLatestSalesAndLatestListingsContainer>
        <OverviewMarketList
          id="dk-website-overview-market-latest-sales"
          title={"Latest Sales"}
          data={dataOverviewList?.lstestSalesList}
          onClickItem={onClickItemLatestSales}
          onClickViewMore={onClickViewMoreLatestSales}
        />
        <OverviewMarketList
          id="dk-website-overview-market-latest-listings"
          title={"Latest Listings"}
          data={dataOverviewList?.latestListingsList}
          onClickItem={onClickItemLatestListings}
          onClickViewMore={onClickViewMoreLatestListings}
        />
      </OverviewMarketLatestSalesAndLatestListingsContainer>
    </OverviewMarketPage>
  );
}

export default OverviewMarket;
