import styled from "styled-components";
import SiteContainer from "../../../../resources/components/SiteContainer/SiteContainer";
import CommonConstants from "../../../../resources/constants/CommonConstants";

export const ConHomeFeatures = styled.div`
  text-align: center;
  align-items: center;
  justify-content: center;
  align-content: center;
  width: 100%;

  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    margin-top: 50px;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_TABLET_MIN}) and (max-width: ${CommonConstants.MIN_WIDTH_TABLET_MAX}) {
    margin-top: 50px;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP}) and (max-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XL}) {
    margin-top: 70px;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XL}) {
    margin-top: 70px;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XXL}) {
    margin-top: 70px;
  }
`;

export const ImgHomeFeatures = styled.img`
  padding: 20px 10px 20px 10px;
  margin-bottom: 50px;
  max-width: 42%;
  max-height: 95px;

  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    margin-bottom: 30px;
    max-width: 60%;
    max-height: unset;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_TABLET_MIN}) and (max-width: ${CommonConstants.MIN_WIDTH_TABLET_MAX}) {
    margin-bottom: 30px;
    max-width: 60%;
    max-height: unset;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP}) and (max-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XL}) {
    margin-bottom: 50px;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XL}) {
    margin-bottom: 50px;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XXL}) {
    margin-bottom: 50px;
  }
`;

export const ContainerFeatures = styled(SiteContainer)`
  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    display: none;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_TABLET_MIN}) and (max-width: ${CommonConstants.MIN_WIDTH_TABLET_MAX}) {
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP}) and (max-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XL}) {
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XL}) {
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XXL}) {
  }
`;

export const ContainerFeaturesMobile = styled(SiteContainer)`
  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_TABLET_MIN}) and (max-width: ${CommonConstants.MIN_WIDTH_TABLET_MAX}) {
    display: none;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP}) and (max-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XL}) {
    display: none;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XL}) {
    display: none;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XXL}) {
    display: none;
  }
`;
