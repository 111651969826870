import React from "react";
import style from "../style/style";

const StyleSiteStepperTitle = style.SiteStepperTitle;

function SiteStepperTitle(props) {
  const { children, ...rest } = props;

  return <StyleSiteStepperTitle {...rest}>{children}</StyleSiteStepperTitle>;
}

export default SiteStepperTitle;
