import React from "react";
import style from "./style/style";

const StyleSiteTextFieldContainer = style.SiteTextFieldContainer;
const StyleSiteTextField = style.SiteTextField;

function SiteTextField(props) {
  const { value, onChange, placeholder, ...rest } = props;

  return (
    <StyleSiteTextFieldContainer>
      <StyleSiteTextField
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        {...rest}
      />
    </StyleSiteTextFieldContainer>
  );
}

export default SiteTextField;
