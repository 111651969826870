import React, { useState } from "react";
import {
  FTMC_ActionContainer,
  FTMC_BalanceContainer,
  FTMC_BoxDicer,
  FTMC_BoxHarvest,
  FTMC_CheckboxBg,
  FTMC_CheckboxContainer,
  FTMC_CheckboxIcon,
  FTMC_CheckboxText,
  FTMC_Container,
  FTMC_DataContainer,
  FTMC_DataTopic,
  FTMC_DataValue,
  FTMC_DicerButton,
  FTMC_FinishedStatus,
  FTMC_HarvestButton,
  FTMC_LiveStatus,
  FTMC_MiniLeftMenuContainer,
  FTMC_MiniLeftMenuContent,
  FTMC_MiniLeftMenuIcon,
  FTMC_MiniLeftMenuSelector,
  FTMC_SearchInput,
  FTMC_SearchInputContainer,
  FTMC_StatusContainer,
  FTMC_StatusSwitch,
} from "./FarmTopMenu.component";
import FarmConstants from "../../../../resources/constants/FarmConstants";
import FarmingIconResource from "../../../../resources/images/2-Farm/Icon-Farming-Y.png";
import FarmInfo from "../../../../resources/model/Farm/FarmInfo";
import NumberFormat from "../../../../utils/NumberFormat";

/**
 *
 * @param {Props} props
 * @returns {React.FC}
 */
function FarmTopMenu(props) {
  const { data, status, stakedOnly, setStatus, setStakedOnly, onSearchTextSubmit } = props;
  const [searchText, setSearchText] = useState("");

  const handleSearchTextChange = ({ target }) => {
    setSearchText(target.value);
  };

  const handleStatusChange = (nextStatus) => {
    setStatus && setStatus(nextStatus);
  };

  const handleToggleStakedOnly = () => {
    setStakedOnly && setStakedOnly(!stakedOnly);
  };

  const handleSearchTextSubmit = (e) => {
    e.preventDefault();
    onSearchTextSubmit && onSearchTextSubmit(searchText);
  };

  const handleHarvestAllClick = () => {
    //TODO: waiting for implements
  };

  const handleBuyDicerClick = () => {
    //TODO: go to page buy dicer
  };

  return (
    <FTMC_Container>
      <FTMC_MiniLeftMenuContainer>
        <FTMC_MiniLeftMenuContent>
          <FTMC_MiniLeftMenuIcon src={FarmingIconResource} />
          Farming
          <FTMC_MiniLeftMenuSelector className="material-icons">
            arrow_drop_down
          </FTMC_MiniLeftMenuSelector>
        </FTMC_MiniLeftMenuContent>
      </FTMC_MiniLeftMenuContainer>
      <FTMC_BalanceContainer>
        <FTMC_BoxHarvest>
          <FTMC_DataContainer>
            <FTMC_DataTopic>You deposited</FTMC_DataTopic>
            <FTMC_DataValue>{NumberFormat.toUSD(data?.deposited)}</FTMC_DataValue>
          </FTMC_DataContainer>
          <FTMC_DataContainer>
            <FTMC_DataTopic>Pending to harvest</FTMC_DataTopic>
            <FTMC_DataValue>{data?.pendingToHarvest}</FTMC_DataValue>
          </FTMC_DataContainer>
          <FTMC_HarvestButton onClick={handleHarvestAllClick}>Harvest All</FTMC_HarvestButton>
        </FTMC_BoxHarvest>
        <FTMC_BoxDicer>
          <FTMC_DataContainer>
            <FTMC_DataTopic>Dicer in wallet</FTMC_DataTopic>
            <FTMC_DataValue>{data?.dicerInWallet}</FTMC_DataValue>
          </FTMC_DataContainer>
          <FTMC_DicerButton onClick={handleBuyDicerClick}>Buy Dicer</FTMC_DicerButton>
        </FTMC_BoxDicer>
      </FTMC_BalanceContainer>
      <FTMC_ActionContainer>
        <FTMC_StatusContainer>
          <FTMC_StatusSwitch status={status} />
          <FTMC_LiveStatus
            onClick={() => handleStatusChange(FarmConstants.FILTER_STATUS.LIVE)}
            active={status === FarmConstants.FILTER_STATUS.LIVE}
          >
            Live
          </FTMC_LiveStatus>
          <FTMC_FinishedStatus
            onClick={() => handleStatusChange(FarmConstants.FILTER_STATUS.FINISHED)}
            active={status === FarmConstants.FILTER_STATUS.FINISHED}
          >
            Finished
          </FTMC_FinishedStatus>
        </FTMC_StatusContainer>
        <FTMC_CheckboxContainer onClick={handleToggleStakedOnly}>
          <FTMC_CheckboxBg>
            {stakedOnly && <FTMC_CheckboxIcon className="material-icons">check</FTMC_CheckboxIcon>}
          </FTMC_CheckboxBg>
          <FTMC_CheckboxText>Staked Only</FTMC_CheckboxText>
        </FTMC_CheckboxContainer>
        <FTMC_SearchInputContainer>
          <form onSubmit={handleSearchTextSubmit}>
            <FTMC_SearchInput
              placeholder="Search Farms"
              value={searchText}
              onChange={handleSearchTextChange}
            />
          </form>
        </FTMC_SearchInputContainer>
      </FTMC_ActionContainer>
    </FTMC_Container>
  );
}

export default FarmTopMenu;

class Props {
  constructor() {
    /**
     * @type {FarmInfo}
     */
    this.data = {};
    this.status = "";
    this.setStatus = () => {};
    this.stakedOnly = false;
    this.setStakedOnly = () => {};
    this.onSearchTextSubmit = () => {};
  }
}
