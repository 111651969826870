import React from "react";
import MenuHowToEarn from "../../../../resources/images/1-Home/Menu-HowToEarn.png";
import DKCharacterMoney from "../../../../resources/images/1-Home/DK-Character-Money.png";
import SiteContainer from "../../../../resources/components/SiteContainer/SiteContainer";
import {
  ConHomeHowToEarn,
  ImgHomeHowToEarn,
  HomeHowToEarnContentImgMobile,
  HomeHowToEarnContent,
  HomeHowToEarnContentText,
  HomeHowToEarnContentImg,
} from "./HomeHowToEarn.component";

function HomeHowToEarn() {
  return (
    <ConHomeHowToEarn id="dk-website-home-page-how-to-earn">
      <div>
        <ImgHomeHowToEarn src={MenuHowToEarn}></ImgHomeHowToEarn>
      </div>

      <HomeHowToEarnContentImgMobile
        src={DKCharacterMoney}
      ></HomeHowToEarnContentImgMobile>

      <SiteContainer>
        <HomeHowToEarnContent>
          <HomeHowToEarnContentText>
            Let's play with your Dicer , play and earn KM Token to open the
            Gashapon and get SCP Token or NFT Equipment.
          </HomeHowToEarnContentText>
          <HomeHowToEarnContentImg
            src={DKCharacterMoney}
          ></HomeHowToEarnContentImg>
        </HomeHowToEarnContent>
      </SiteContainer>
    </ConHomeHowToEarn>
  );
}

export default HomeHowToEarn;
