import React from "react";

import LogoDKWebSite from "../../../../images/1-Home/DK-logo.png";
import style from "../style/style";

function HeaderLogo() {
  const HeaderLogo = style.HeaderLogo;
  const ImageLogo = style.ImageLogo;

  return (
    <HeaderLogo id="dk-website-header-logo">
      <ImageLogo src={LogoDKWebSite} />
    </HeaderLogo>
  );
}

export default HeaderLogo;
