import styled from "styled-components";
import CommonConstants from "../../../constants/CommonConstants";
import SiteTextH1 from "../../Text/SiteTextH1";
import SiteTextH3 from "../../Text/SiteTextH3";

const Thumbnail = styled.div`
  /* width: 100%; */
  margin: 20px;
`;

const ThumbnailImage = styled.img`
  width: 100%;
  border-radius: 20px 20px 0px 0px;
  /* background: #fff1ca; */
  min-height: 200px;
  box-shadow: 0px 0px 5px 5px rgba(39, 32, 24, 0.514);
`;

const ThumbnailText = styled.div`
  margin-top: -5px;
  padding: 20px;
  padding-left: 40px;
  padding-right: 40px;
  border-radius: 0px 0px 20px 20px;
  box-shadow: 0px 0px 5px 5px rgba(39, 32, 24, 0.514);
  background: rgb(39, 32, 24);
  text-align: left;
  align-items: flex-start;
`;

const ThumbnailTextTitle = styled(SiteTextH1)`
  color: #ffffff;
  font-weight: bold !important;

  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    font-size: 20px;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_TABLET_MIN}) and (max-width: ${CommonConstants.MIN_WIDTH_TABLET_MAX}) {
    font-size: 20px;
  }
`;

const ThumbnailTextDetail = styled(SiteTextH3)`
  color: #ffffff;
  font-weight: unset;

  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    font-size: 16px;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_TABLET_MIN}) and (max-width: ${CommonConstants.MIN_WIDTH_TABLET_MAX}) {
    font-size: 16px;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP}) {
    min-height: 50px;
  }
`;

export default {
  Thumbnail: Thumbnail,
  ThumbnailImage: ThumbnailImage,
  ThumbnailText: ThumbnailText,
  ThumbnailTextTitle: ThumbnailTextTitle,
  ThumbnailTextDetail: ThumbnailTextDetail,
};
