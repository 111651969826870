export default {
    MIN_WIDTH_MOBILE: "767px",
    MIN_WIDTH_TABLET_MIN: "768px",
    MIN_WIDTH_TABLET_MAX: "991px",
    MIN_WIDTH_DESKTOP: "992px",
    MIN_WIDTH_DESKTOP_XL: "1280px",
    MIN_WIDTH_DESKTOP_XXL: "1480px",
    MIN_WIDTH_DESKTOP_XXXL: "1780px",
    MIN_WIDTH_DESKTOP_2K: "2040px",
    MIN_WIDTH_DESKTOP_2800PX: "2800px",

    COLOR_ACTIVE: "#f4a623",
}