import React from "react";
import style from "../style/style";
import CursorRoadmap from "../../../images/1-Home/Cursor-Roadmap.png";

const StyleSiteStepperContent = style.SiteStepperContent;
const StyleSiteStepperContentSubItem = style.SiteStepperContentSubItem;
const StyleSiteStepperContentItem = style.SiteStepperContentItem;
const StyleSiteStepperContentSubItemLine = style.SiteStepperContentSubItemLine;
const StyleSiteStepperContentSubItemImage =
  style.SiteStepperContentSubItemImage;

function SiteStepperContent(props) {
  const { list, ...rest } = props;

  const renderContent = (list) => {
    return list.map((item, index) => {
      return (
        <StyleSiteStepperContentItem key={index}>
          <StyleSiteStepperContentSubItem>
            {item}
          </StyleSiteStepperContentSubItem>
          <StyleSiteStepperContentSubItemImage
            src={CursorRoadmap}
          ></StyleSiteStepperContentSubItemImage>
          <StyleSiteStepperContentSubItemLine
            id={"home-road-map-line-step-" + index}
          ></StyleSiteStepperContentSubItemLine>
        </StyleSiteStepperContentItem>
      );
    });
  };

  return (
    <StyleSiteStepperContent {...rest}>
      {renderContent(list)}
    </StyleSiteStepperContent>
  );
}

export default SiteStepperContent;
