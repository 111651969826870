import styled from "styled-components";
import SiteLeftMenu from "../../../../resources/components/SiteLeftMenu/SiteLeftMenu";
// FLMC = Farm Left Menu Component
export const FLMC_Container = styled(SiteLeftMenu)``;

export const FLMC_FarmingButtonContainer = styled.button`
  width: 85%;
  height: 3rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 3px solid ${(props) => (props.active ? "#ffa300" : "transparent")};
  border-radius: 3rem;
  background: ${(props) => (props.active ? "#2d0807" : "transparent")};
  font-weight: bold;
  font-size: 1.3rem;
  color: ${(props) => (props.active ? "#ffa300" : "#ffffff")};
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  line-height: 1;
  margin-bottom: 1rem;
`;

export const FLMC_FarmingButtonIcon = styled.img`
  width: 1.5rem;
  height: 1.5rem;
  object-fit: contain;
  margin-right: 2rem;
`;

export const FLMC_FarmingButtonText = styled.span`
  position: relative;
`;

export const FLMC_ComingSoonText = styled.div`
  position: absolute;
  top: -0.6rem;
  left: 0;
  color: red;
  font-weight: bold;
  font-size: 0.6rem;
`;
