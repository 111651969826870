import React, { useEffect, useState } from "react";
import styled from "styled-components";
import BuyDicerData from "../../../resources/model/Dicer/BuyDicerData";
import BuyDicerItem from "./BuyDicerItem";
import buyDicerMockup from "../mockup/buyDicer.mockup.json";

function BuyDicer() {
  /**
   * @type [BuyDicerData[], Function]
   */
  const [items, setItems] = useState([]);

  useEffect(() => {
    fetchBuyDicerItems();
  }, []);

  const fetchBuyDicerItems = async () => {
    //TODO: fetch data from api
    setItems(buyDicerMockup);
  };

  return (
    <Container>
      {items.map((item, index) => (
        <BuyDicerItem key={"dicerBox_" + index} data={item} />
      ))}
    </Container>
  );
}

export default BuyDicer;

const Container = styled.div`
  margin-top: 2rem;
`;
