import React, { useState, useEffect } from "react";
import {
  MarketplaceDialogBuyButton,
  MarketplaceDialogBuyContent,
  MarketplaceDialogBuyDiscount,
  MarketplaceDialogBuyIcon,
  MarketplaceDialogBuyPrice,
  MarketplaceDialogBuyPriceContainer,
  MarketplaceDialogContent,
  MarketplaceDialogDetailContainer,
  MarketplaceDialogDetailOwner,
  MarketplaceDialogDetailTitle,
  MarketplaceDialogDicerImage,
  MarketplaceDialogItemImageContainer,
  MarketplaceDialogSectionOne,
  MarketplaceDialogSectionTwo,
  MarketplaceDialogItemClass,
  MarketplaceDialogDetailItemDetail,
  MarketplaceDialogDetailItemTotalSupply,
  MarketplaceDialogItemDescription,
} from "../MarketplaceDialog.component";
import CharacterBearImageResource from "../../../../../../../resources/images/1-Home/Character-Bear.png";
import CapsuleImageResource from "../../../../../../../resources/images/4-Marketplace/Capsule.png";
import BuyIconImageResource from "../../../../../../../resources/images/4-Marketplace/busd.png";
import marketplaceDialogItemDetailMockup from "../../../mockup/marketplaceDialogItemDetail.mockup.json";
import MarketplaceDialogSaleHistory from "../DicerDetail/MarketplaceDialogSaleHistory";
import CapsuleDetail from "../../../../../../../resources/model/Marketplace/CapsuleDetail";

function MarketplaceDialogItemDetail({ dicerId }) {
  /**
   * @type [CapsuleDetail, Function]
   */
  const [itemDetails, setitemDetails] = useState(null);

  useEffect(() => {
    // fetch data
    fetchDicerDetails(dicerId);
  }, []);

  const fetchDicerDetails = async (dicerId) => {
    //TODO : fetch data from api
    setitemDetails(marketplaceDialogItemDetailMockup);
  };

  return (
    <MarketplaceDialogContent>
      <MarketplaceDialogSectionOne>
        <MarketplaceDialogItemImageContainer>
          <MarketplaceDialogDicerImage src={CapsuleImageResource} />
          <MarketplaceDialogItemClass>
            Class {itemDetails?.class}
          </MarketplaceDialogItemClass>
        </MarketplaceDialogItemImageContainer>
        <MarketplaceDialogBuyContent>
          <MarketplaceDialogBuyIcon src={BuyIconImageResource} />
          <MarketplaceDialogBuyPriceContainer>
            <MarketplaceDialogBuyPrice>
              {itemDetails?.price}
            </MarketplaceDialogBuyPrice>
            <MarketplaceDialogBuyDiscount>
              -${itemDetails?.discount}
            </MarketplaceDialogBuyDiscount>
          </MarketplaceDialogBuyPriceContainer>
          <MarketplaceDialogBuyButton>Buy</MarketplaceDialogBuyButton>
        </MarketplaceDialogBuyContent>
      </MarketplaceDialogSectionOne>
      <MarketplaceDialogSectionTwo>
        <MarketplaceDialogDetailContainer>
          <MarketplaceDialogDetailTitle>
            {itemDetails?.equip} #{dicerId}
          </MarketplaceDialogDetailTitle>
          <MarketplaceDialogDetailOwner>
            Owner: {itemDetails?.ownerId}
          </MarketplaceDialogDetailOwner>

          <MarketplaceDialogDetailItemDetail>
            {itemDetails?.detail}
          </MarketplaceDialogDetailItemDetail>
          <MarketplaceDialogDetailItemTotalSupply>
            Total Supply {itemDetails?.totalSupply}
          </MarketplaceDialogDetailItemTotalSupply>
        </MarketplaceDialogDetailContainer>

        <MarketplaceDialogItemDescription dangerouslySetInnerHTML={{__html: itemDetails?.description}}  />

        <MarketplaceDialogSaleHistory
          saleHistories={itemDetails?.saleHistories}
        />
      </MarketplaceDialogSectionTwo>
    </MarketplaceDialogContent>
  );
}

export default MarketplaceDialogItemDetail;
