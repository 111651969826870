import React from "react";
import styled from "styled-components";

function DemoPlayGame() {
  return (
  	<Container>
      <Content>
      	<Iframe src="https://dicekingdom.com/v3" frameborder={0}/>
      </Content>
    </Container>
    );
}

export default DemoPlayGame;

const Container = styled.div`
  margin-top: 2rem;
`;

const Content = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  height: 100%
`;

const Iframe = styled.iframe `
	width: 100%;
	height: 620px;
	border: none;
`
