import styled from "styled-components";
import CommonConstants from "../../../../resources/constants/CommonConstants";

export const OverviewMarketContentContainer = styled.div`
  position: relative;
  padding: 1rem 0rem 1rem 2rem;
  border: 0.5px solid #f4a623;
  border-radius: 20px;
  background: #1c0b09ca;
  /* width: 100%; */
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  height: 258px;

  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    padding: 0.5rem 0rem 0.5rem 0rem;
    height: unset;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_TABLET_MIN}) and (max-width: ${CommonConstants.MIN_WIDTH_TABLET_MAX}) {
    padding: 0.5rem 0rem 0.5rem 0rem;
    height: unset;
  }
`;

export const OverviewMarketContentTextTitleText = styled.div`
  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    display: none;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_TABLET_MIN}) and (max-width: ${CommonConstants.MIN_WIDTH_TABLET_MAX}) {
    display: none;
  }
`;

export const OverviewMarketContentText = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
`;
export const OverviewMarketContentSummaryContainerMain = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: space-evenly;
  width: 100%;
  height: 100%;

  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    flex-direction: column;
    gap: 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    align-items: flex-start;
    align-content: flex-start;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_TABLET_MIN}) and (max-width: ${CommonConstants.MIN_WIDTH_TABLET_MAX}) {
    flex-direction: column;
    gap: 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    align-items: flex-start;
    align-content: flex-start;
  }
`;

export const OverviewMarketContentSummaryContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  height: 100%;
  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    padding-left: 1rem;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_TABLET_MIN}) and (max-width: ${CommonConstants.MIN_WIDTH_TABLET_MAX}) {
    padding-left: 1rem;
  }
`;

export const OverviewMarketContentSummaryImage = styled.img`
  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    width: 60px;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_TABLET_MIN}) and (max-width: ${CommonConstants.MIN_WIDTH_TABLET_MAX}) {
    width: 90px;
  }
`;

export const OverviewMarketContentSummaryTextContainer = styled.div`
  position: relative;
  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    margin-left: 1rem;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_TABLET_MIN}) and (max-width: ${CommonConstants.MIN_WIDTH_TABLET_MAX}) {
    margin-left: 1rem;
  }
`;

export const OverviewMarketContentSummaryTextTitle = styled.div`
  color: #807a77;
  font-weight: 500;
  /* #faf6f8 */
  font-size: 0.9rem;
  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    font-size: 0.8rem;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_TABLET_MIN}) and (max-width: ${CommonConstants.MIN_WIDTH_TABLET_MAX}) {
    font-size: 0.8rem;
  }
`;

export const OverviewMarketContentSummaryTextValue = styled.div`
  color: #faf6f8;
  /* #faf6f8 */
  font-size: 1.8rem;
  font-weight: bold;
  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    font-size: 1.5rem;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_TABLET_MIN}) and (max-width: ${CommonConstants.MIN_WIDTH_TABLET_MAX}) {
    font-size: 1.5rem;
  }
`;

export const OverviewMarketContentImageContainer = styled.div`
  position: relative;
  width: 300px;
  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    display: none;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_TABLET_MIN}) and (max-width: ${CommonConstants.MIN_WIDTH_TABLET_MAX}) {
    display: none;
  }
`;

export const OverviewMarketContentImage = styled.img`
  position: absolute;
  right: 0px;

  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    display: none;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_TABLET_MIN}) and (max-width: ${CommonConstants.MIN_WIDTH_TABLET_MAX}) {
    display: none;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP}) and (max-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XL}) {
    display: none;
  }
`;

export const OverviewMarketContentTextTitle = styled.div`
  display: flex;
  flex-direction: row;
  color: #ffffff;
  font-size: 2rem;
  font-weight: bold;
  border-bottom: 0.5px solid #f4a72373;
`;

export const OverviewMarketContentBtnTitle = styled.div`
  margin-left: 3rem;
  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    margin-left: unset;
    margin-left: 0.5rem;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_TABLET_MIN}) and (max-width: ${CommonConstants.MIN_WIDTH_TABLET_MAX}) {
    margin-left: unset;
    margin-left: 0.5rem;
  }
`;
