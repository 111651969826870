import styled from "styled-components";
import CommonConstants from "../../../constants/CommonConstants";
import SiteProcessBar from "../../SiteProcessBar/SiteProcessBar";
import SiteProcessBarMobile from "../../SiteProcessBarMobile/SiteProcessBarMobile";
import SiteTextH1 from "../../Text/SiteTextH1";

const SiteStepper = styled.div`
  align-items: center;
  text-align: center;
  justify-content: center;
  position: relative;
`;

const SiteStepperProcessBar = styled(SiteProcessBar)`
  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    display: none;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_TABLET_MIN}) and (max-width: ${CommonConstants.MIN_WIDTH_TABLET_MAX}) {
    display: none;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP}) and (max-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XL}) {
    margin-left: 5%;
    margin-right: 5%;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XL}) {
    margin-left: 7%;
    margin-right: 7%;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XXL}) {
    margin-left: 7%;
    margin-right: 7%;
  }
`;

const SiteStepperProcessBarMobile = styled(SiteProcessBarMobile)`
  display: none !important;
`;

const SiteStepperTitle = styled(SiteTextH1)`
  color: #ffecd1;
  margin-bottom: 20px;
  font-weight: unset;

  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    font-size: 18px;
    margin-bottom: 10px;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_TABLET_MIN}) and (max-width: ${CommonConstants.MIN_WIDTH_TABLET_MAX}) {
    font-size: 18px;
    margin-bottom: 10px;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP}) and (max-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XL}) {
    font-size: 18px;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XL}) {
    font-size: 20px;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XXL}) {
    font-size: 24px;
  }
`;

const SiteStepperContent = styled.div`
  align-items: center;
  text-align: center;
  justify-content: center;
  display: flex;

  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    display: none;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_TABLET_MIN}) and (max-width: ${CommonConstants.MIN_WIDTH_TABLET_MAX}) {
    display: none;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP}) and (max-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XL}) {
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XL}) {
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XXL}) {
  }
`;

const SiteStepperContentMobile = styled.div`
  align-items: center;
  text-align: center;
  justify-content: center;
  display: flex;
  flex-direction: row;

  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_TABLET_MIN}) and (max-width: ${CommonConstants.MIN_WIDTH_TABLET_MAX}) {
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP}) and (max-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XL}) {
    display: none;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XL}) {
    display: none;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XXL}) {
    display: none;
  }
`;

const SiteStepperContentMobileLeftRight = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const SiteStepperContentMobileItem = styled.div`
  flex-grow: 1;
  position: relative;
  margin-bottom: 40px;
`;

const SiteStepperContentItem = styled.div`
  align-items: left;
  text-align: left;
  justify-content: flex-start;
  display: block;
  flex-grow: 1;
  position: relative;
`;

const SiteStepperContentSubItem = styled.div`
  /* margin-top: 60px; */
  margin: 60px 5px 0px 5px;
  padding-left: 10%;
  padding-right: 10%;
  position: relative;
`;

const SiteStepperContentSubItemLine = styled.div`
  position: absolute;
  left: 50%;
  top: -40px;
  width: 7px;
  height: 100px;
  background: rgb(27, 19, 10);
  z-index: 3;
`;

const SiteStepperContentSubItemImage = styled.img`
  position: absolute;
  left: 46.5%;
  top: -60px;
  /* width: ; */
  z-index: 4;
  max-width: 10%;
`;

const SiteStepperContentSubItemImageMobileLeft = styled.img`
  position: absolute;
  /* left: 100%; */
  right: -25px;
  z-index: 4;
  max-width: 20%;
  transform: rotate(90deg);

  @media (min-width: ${CommonConstants.MIN_WIDTH_TABLET_MIN}) and (max-width: ${CommonConstants.MIN_WIDTH_TABLET_MAX}) {
    max-width: 15%;
  }
`;

const SiteStepperContentSubItemImageMobileRight = styled.img`
  position: absolute;
  left: -25px;
  z-index: 4;
  max-width: 20%;
  transform: rotate(-90deg);

  @media (min-width: ${CommonConstants.MIN_WIDTH_TABLET_MIN}) and (max-width: ${CommonConstants.MIN_WIDTH_TABLET_MAX}) {
    max-width: 15%;
  }
`;

export default {
  SiteStepper: SiteStepper,
  SiteStepperProcessBar: SiteStepperProcessBar,
  SiteStepperProcessBarMobile: SiteStepperProcessBarMobile,

  SiteStepperTitle: SiteStepperTitle,
  SiteStepperContent: SiteStepperContent,
  SiteStepperContentSubItem: SiteStepperContentSubItem,
  SiteStepperContentItem: SiteStepperContentItem,
  SiteStepperContentSubItemLine: SiteStepperContentSubItemLine,
  SiteStepperContentSubItemImage: SiteStepperContentSubItemImage,
  SiteStepperContentSubItemImageMobileLeft:
    SiteStepperContentSubItemImageMobileLeft,
  SiteStepperContentSubItemImageMobileRight:
    SiteStepperContentSubItemImageMobileRight,

  SiteStepperContentMobile: SiteStepperContentMobile,
  SiteStepperContentMobileLeftRight: SiteStepperContentMobileLeftRight,
  SiteStepperContentMobileItem: SiteStepperContentMobileItem,
};
