import React, { useState } from "react";
import {
  OverviewMarketTitleContainer,
  OverviewMarketTitleText,
  OverviewMarketTitleTapBtn,
  OverviewMarketTitleTapBtnMoblie,
} from "./OverviewMarketTitle.component";
import SiteButtonGroupRadio from "../../../../resources/components/SiteButton/SiteButtonGroupRadio";
import SiteSelectNotSkew from "../../../../resources/components/SiteSelect/SiteSelectNotSkew";

function OverviewMarketTitle(props) {
  let {
    tapOverviewMarketTitle = [],
    activeTapOverviewMarketTitle = 0,
    onChangeTapOverviewMarketTitle = () => {},
  } = props;

  return (
    <OverviewMarketTitleContainer id="dk-website-overview-market-title">
      <OverviewMarketTitleText>Overview Market</OverviewMarketTitleText>
      <OverviewMarketTitleTapBtn>
        <SiteButtonGroupRadio
          list={tapOverviewMarketTitle}
          active={activeTapOverviewMarketTitle}
          setActiveOnClick={onChangeTapOverviewMarketTitle}
          fontSize={"1rem"}
          fontWeight={"normal"}
        />
      </OverviewMarketTitleTapBtn>
      <OverviewMarketTitleTapBtnMoblie>
        <SiteSelectNotSkew
          source={tapOverviewMarketTitle.map((item, index) => ({
            value: item,
            display: item,
          }))}
          value={tapOverviewMarketTitle[activeTapOverviewMarketTitle]}
          onChange={(e) => {
            onChangeTapOverviewMarketTitle(e.target.selectedIndex);
          }}
        />
      </OverviewMarketTitleTapBtnMoblie>
    </OverviewMarketTitleContainer>
  );
}

export default OverviewMarketTitle;
