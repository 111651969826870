import React from "react";
import styled from "styled-components";
import CommonConstants from "../../../../../../../resources/constants/CommonConstants";
import { DicerDetail_Clone } from "../../../../../../../resources/model/Marketplace/DicerDetail";
import MarketplaceDialogAccordion from "./MarketplaceDialogAccordion";

/**
 *
 * @param {Props} props
 * @returns {React.FC}
 */
function MarketplaceDialogCloning(props) {
  const { dicerId, dicerImage, items = [], responsive = false } = props;

  const UseGrid = responsive ? GridResponsive : Grid;

  return (
    <MarketplaceDialogAccordion title="Synchro Cloning" alwaysShow={responsive}>
      <UseGrid>
        {items.map((item, index) => (
          <Container key={"cloning_" + index}>
            <Title>Dicer #{dicerId}</Title>
            <Detail>
              Clone : {item.cloneCount} Skill : {item.skillCount}
            </Detail>
            <ImageContainer>
              <Image src={dicerImage} />
            </ImageContainer>
          </Container>
        ))}
      </UseGrid>
    </MarketplaceDialogAccordion>
  );
}

export default MarketplaceDialogCloning;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(auto, 1fr));
  gap: 1rem;
  margin-top: 1rem;
`;

const GridResponsive = styled.div`
  display: grid;
  grid-template-columns: repeat(4, minmax(auto, 1fr));
  gap: 1rem;
  margin-top: 1rem;

  @media (max-width: ${CommonConstants.MIN_WIDTH_DESKTOP}) {
    grid-template-columns: repeat(2, minmax(auto, 1fr));
  }
`;

const Container = styled.div`
  width: 5rem;
  border: 2px solid #ffcd83;
  border-radius: 0.6rem;
  padding: 0.5rem 1rem;
  background: #694007;
`;

const Title = styled.div`
  color: #ffffff;
  font-weight: 600;
  font-size: 0.7rem;
`;

const Detail = styled.div`
  color: #ffe3c7;
  font-size: 0.6rem;
`;

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.5rem 0;
`;

const Image = styled.img`
  width: 4rem;
  height: 4rem;
  object-fit: contain;
`;

class Props {
  constructor() {
    this.dicerId = "";
    this.dicerImage = "";
    /**
     * @type {DicerDetail_Clone[]}
     */
    this.items = [];
    this.responsive = false;
  }
}
