import React from "react";
import styled from "styled-components";
import SiteLeftMenu from "../../../resources/components/SiteLeftMenu/SiteLeftMenu";
import BuyDicerActiveIconResource from "../../../resources/images/3-Dicer/Icon-BuyDicer-Y.png";
import BuyDicerInactiveIconResource from "../../../resources/images/3-Dicer/Icon-BuyDicer-W.png";
import MyDicerActiveIconResource from "../../../resources/images/3-Dicer/Icon-MyDicer-Y.png";
import MyDicerInactiveIconResource from "../../../resources/images/3-Dicer/Icon-MyDicer-W.png";
import DicerConstants from "../../../resources/constants/DicerConstants";

/**
 *
 * @param {Props} props
 * @returns {React.FC}
 */
function DicerLeftMenu(props) {
  const { activeMenu, onMenuChange } = props;
  const BuyDicerIcon =
    activeMenu === DicerConstants.MENU.BUY_DICER
      ? BuyDicerActiveIconResource
      : BuyDicerInactiveIconResource;
  const MyDicerIcon =
    activeMenu === DicerConstants.MENU.MY_DICER
      ? MyDicerActiveIconResource
      : MyDicerInactiveIconResource;

  const handleMenuClick = (menuName) => {
    onMenuChange && onMenuChange(menuName);
  };

  return (
    <Container>
      <ButtonContainer
        active={activeMenu === DicerConstants.MENU.BUY_DICER}
        onClick={() => handleMenuClick(DicerConstants.MENU.BUY_DICER)}
      >
        <ButtonIcon src={BuyDicerIcon} />
        <ButtonText>Buy Dicer</ButtonText>
      </ButtonContainer>
      <ButtonContainer
        active={activeMenu === DicerConstants.MENU.MY_DICER}
        onClick={() => handleMenuClick(DicerConstants.MENU.MY_DICER)}
      >
        <ButtonIcon src={MyDicerIcon} />
        <ButtonText>My Dicer</ButtonText>
      </ButtonContainer>
    </Container>
  );
}

export default DicerLeftMenu;

const Container = styled(SiteLeftMenu)``;

const ButtonContainer = styled.button`
  width: 85%;
  height: 3rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 3px solid ${(props) => (props.active ? "#ffa300" : "transparent")};
  border-radius: 3rem;
  background: ${(props) => (props.active ? "#2d0807" : "transparent")};
  font-weight: bold;
  font-size: 1.3rem;
  color: ${(props) => (props.active ? "#ffa300" : "#ffffff")};
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  line-height: 1;
  margin-bottom: 1rem;
`;

const ButtonIcon = styled.img`
  width: 1.5rem;
  height: 1.5rem;
  object-fit: contain;
  margin-right: 2rem;
`;

const ButtonText = styled.span`
  position: relative;
`;

class Props {
  constructor() {
    this.activeMenu = "";
    this.onMenuChange = () => {};
  }
}
