class DicerDetail {
  constructor() {
    this.id = "";
    this.ownerId = "";
    this.name = "";
    this.image = "";
    this.price = 0;
    this.discount = 0;
    /**
     * @type {DicerDetail_About}
     */
    this.about = {};
    /**
     * @type {DicerDetail_Info}
     */
    this.info = {};
    /**
     * @type {DicerDetail_Part[]}
     */
    this.parts = [];
    /**
     * @type {DicerDetail_Skill[]}
     */
    this.skills = [];
    /**
     * @type {DicerDetail_SaleHistory[]}
     */
    this.saleHistories = [];
    /**
     * @type {DicerDetail_Absorb[]}
     */
    this.absorbs = [];
    /**
     * @type {DicerDetail_Clone[]}
     */
    this.clones = [];
    /**
     * @type {DicerDetail_Gene[]}
     */
    this.genes = [];
  }
}

export class DicerDetail_About {
  constructor() {
    this.race = "";
    this.color = "";
    this.clone = "";
    this.skill = "";
    this.activeSkill = {
      name: "",
    };
    this.status = {
      wealth: 0,
      devotion: 0,
      charm: 0,
      manage: 0,
      luck: 0,
      skill: 0,
    };
  }
}

export class DicerDetail_Info {
  constructor() {
    this.startMoney = 0;
    this.salary = 0;
    this.diceControl = 0;
    this.gaugeControl = 0;
    this.giftedBonus = 0;
    this.doubledBonus = 0;
    this.miniGamesBonus = 0;
    this.cardChainBonus = 0;
  }
}

export class DicerDetail_Part {
  constructor() {
    this.image = "";
    this.name = "";
    this.plusName = "";
    this.plusValue = 0;
  }
}

export class DicerDetail_Skill {
  constructor() {
    this.name = "";
    this.image = "";
  }
}

export class DicerDetail_SaleHistory {
  constructor() {
    this.buyer = "";
    this.seller = "";
    this.price = 0;
    this.date = "";
  }
}

export class DicerDetail_Absorb {
  constructor() {
    this.name = "";
    this.cloneCount = 0;
    this.skillCount = 0;
  }
}

export class DicerDetail_Clone {
  constructor() {
    this.name = "";
    this.cloneCount = 0;
    this.skillCount = 0;
  }
}

export class DicerDetail_Gene {
  constructor() {
    this.part = "";
    this.stand = 0;
    this.distressed = 0;
    this.panel = 0;
  }
}

export default DicerDetail;
