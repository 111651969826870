import styled from "styled-components";
import React from "react";
import SiteButtonBrownDark from "./SiteButtonBrownDark";
import SiteButtonBrownLight from "./SiteButtonBrownLight";
import style from "./style/style";

function SiteButtonGroupRadio(props) {
  const {
    list,
    active,
    setActiveOnClick,
    fontSize = "0.8rem",
    fontWeight = "bold",
    ...rest
  } = props;

  const StyleSiteButtonGroupRadio = style.SiteButtonGroupRadio;

  const BtnSiteButtonBrownDark = styled(SiteButtonBrownDark)`
    font-size: ${(styleProps) => styleProps.textSize} !important;
    margin-right: -3px !important;
    border-radius: 5px !important;
    font-weight: ${(styleProps) => styleProps.textWeight} !important;
  `;
  const BtnSiteButtonBrownLight = styled(SiteButtonBrownLight)`
    font-size: ${(styleProps) => styleProps.textSize} !important;
    margin-right: -3px !important;
    border-radius: 5px !important;
    font-weight: ${(styleProps) => styleProps.textWeight} !important;
  `;

  const _renderButtonGroup = () => {
    return list.map((item, index) => {
      if (active == index) {
        return (
          <BtnSiteButtonBrownLight
            onClick={() => setActiveOnClick(index)}
            key={index}
            textSize={fontSize}
            textWeight={fontWeight}
          >
            {item}
          </BtnSiteButtonBrownLight>
        );
      } else {
        return (
          <BtnSiteButtonBrownDark
            onClick={() => setActiveOnClick(index)}
            key={index}
            textSize={fontSize}
            textWeight={fontWeight}
          >
            {item}
          </BtnSiteButtonBrownDark>
        );
      }
    });
  };

  return (
    <StyleSiteButtonGroupRadio {...rest}>
      {_renderButtonGroup()}
    </StyleSiteButtonGroupRadio>
  );
}

export default SiteButtonGroupRadio;
