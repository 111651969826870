import styled from "styled-components";
import CommonConstants from "../../../constants/CommonConstants";

const SiteTextFieldContainer = styled.div `
  border: 2px solid #9e6547;
  transform: skewX(-6deg);
  border-radius: 0.25rem;
  background: #ffffff;
  padding: 0.4rem 1rem;
`;

const SiteTextField = styled.input `
  outline: none;
  border: none;
  font-size: 1.3rem;
  color: #9e6547;
  width: 100%;

  &::placeholder {
    color: #9e6547;
  }

  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    font-size: 1rem;
  }
`;

export default {
    SiteTextFieldContainer: SiteTextFieldContainer,
    SiteTextField: SiteTextField,
}