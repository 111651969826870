import styled from "styled-components";
import CommonConstants from "../../../../resources/constants/CommonConstants";
import {
  HOME_RAY_ANIM
} from "../../resources/style/style";

export const ConHomeGameFlow = styled.div `
  text-align: center;
  align-items: center;
  justify-content: center;
  align-content: center;
  position: relative;
  width: 100%;

  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    margin-top: 50px;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_TABLET_MIN}) and (max-width: ${CommonConstants.MIN_WIDTH_TABLET_MAX}) {
    margin-top: 50px;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP}) and (max-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XL}) {
    margin-top: 70px;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XL}) {
    margin-top: 70px;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XXL}) {
    margin-top: 70px;
  }
`;

export const ImgHomeGameFlow = styled.img `
  padding: 20px 10px 20px 10px;
  max-width: 42%;
  max-height: 95px;

  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    margin-bottom: 30px;
    max-width: 70%;
    max-height: unset;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_TABLET_MIN}) and (max-width: ${CommonConstants.MIN_WIDTH_TABLET_MAX}) {
    margin-bottom: 30px;
    max-width: 70%;
    max-height: unset;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP}) and (max-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XL}) {
    margin-bottom: 50px;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XL}) {
    margin-bottom: 50px;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XXL}) {
    margin-bottom: 50px;
  }
`;

export const ImgSecondHomeGameFlow = styled.img `
  position: absolute;
  /* top: 10%; */
  left: -10%;
  margin-top: -50px;
  margin-left: 20px;
  z-index: 2;

  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    display: none;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_TABLET_MIN}) and (max-width: ${CommonConstants.MIN_WIDTH_TABLET_MAX}) {
    display: none;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP}) and (max-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XL}) {
    width: 300px;
  }
  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XL}) {
    width: 350px;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XXL}) {
    width: 350px;
  }
`;

export const ImgRaylightHomeGameFlow = styled(ImgSecondHomeGameFlow)
`
  z-index: 1;
  -webkit-animation: ${HOME_RAY_ANIM} 40s linear infinite;
  animation: ${HOME_RAY_ANIM} 40s linear infinite;

  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    display: none;
  }
  @media (min-width: ${CommonConstants.MIN_WIDTH_TABLET_MIN}) and (max-width: ${CommonConstants.MIN_WIDTH_TABLET_MAX}) {
    display: none;
  }
  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP}) and (max-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XL}) {
    width: 300px;
  }
  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XL}) {
    width: 350px;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XXL}) {
    width: 350px;
  }
`;

export const HomeGameFlowImageContent = styled.div `
  position: relative;
  z-index: 2;
`;

export const ImgGameFlowImageContent = styled.img `
  max-width: 100%;
  margin-top: -50px;
`;