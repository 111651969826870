import styled from "styled-components";
import SiteTextH1 from "../../../../resources/components/Text/SiteTextH1";
import SiteTextH3 from "../../../../resources/components/Text/SiteTextH3";
import CommonConstants from "../../../../resources/constants/CommonConstants";

export const ConHomeRoadMap = styled.div`
  text-align: center;
  align-items: center;
  justify-content: center;
  align-content: center;
  width: 100%;

  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    margin-top: 30px;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_TABLET_MIN}) and (max-width: ${CommonConstants.MIN_WIDTH_TABLET_MAX}) {
    margin-top: 30px;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP}) and (max-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XL}) {
    margin-top: 30px;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XL}) {
    margin-top: 50px;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XXL}) {
    margin-top: 50px;
  }
`;

export const ImgHomeRoadMap = styled.img`
  padding: 20px 10px 20px 10px;

  max-width: 42%;
  max-height: 95px;

  margin-bottom: 50px;
  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    margin-bottom: 00px;
    max-width: 60%;
    max-height: unset;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_TABLET_MIN}) and (max-width: ${CommonConstants.MIN_WIDTH_TABLET_MAX}) {
    margin-bottom: 00px;
    max-width: 60%;
    max-height: unset;
  }
`;

export const HomeRoadMapStepContent = styled.div`
  padding: 20px;
  border-radius: 20px;
  background: rgb(27, 19, 10);
  min-height: 280px;
  min-width: 130px;
  box-shadow: 0px 0px 2px 2px rgb(27, 19, 10);
  text-align: left;
  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    padding: 10px;
    min-width: 130px;
    margin-top: 20px;
    min-height: 180px;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_TABLET_MIN}) and (max-width: ${CommonConstants.MIN_WIDTH_TABLET_MAX}) {
    padding: 10px;
    min-width: 130px;
    margin-top: 20px;
    min-height: 180px;
  }
  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP}) and (max-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XL}) {
    min-height: 280px;
  }
`;

export const HomeRoadMapStepContentActive = styled.div`
  padding: 20px;
  border-radius: 20px;
  background: rgb(27, 19, 10);
  min-height: 280px;
  min-width: 130px;
  border: 2px solid rgb(255 161 13);
  box-shadow: 0px 0px 2px 2px rgb(27, 19, 10);

  text-align: left;

  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    padding: 10px;
    min-width: 130px;
    margin-top: 20px;
    min-height: 180px;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_TABLET_MIN}) and (max-width: ${CommonConstants.MIN_WIDTH_TABLET_MAX}) {
    padding: 10px;
    min-width: 130px;
    margin-top: 20px;
    min-height: 180px;
  }
  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP}) and (max-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XL}) {
    min-height: 280px;
  }
`;

export const HomeRoadMapStepContentTitle = styled(SiteTextH1)`
  font-weight: bold;
  color: #ffffff;

  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    font-size: 1rem;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_TABLET_MIN}) and (max-width: ${CommonConstants.MIN_WIDTH_TABLET_MAX}) {
    font-size: 1rem;
  }
`;

export const HomeRoadMapStepContentTopic = styled.li`
  color: #ffecd1;
  margin-top: 10px;
  font-weight: normal;
  font-size: 1rem;

  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    font-size: 0.8rem;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_TABLET_MIN}) and (max-width: ${CommonConstants.MIN_WIDTH_TABLET_MAX}) {
    font-size: 0.8rem;
  }
`;
