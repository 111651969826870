import React, { useState } from "react";

import style from "../../style/style";
import ButtonMenuMobile from "../../../../../images/1-Home/Button-Menu-Mobile.png";
import ButtonCloseMobile from "../../../../../images/1-Home/Button-Close-Mobile.png";
import HeaderMenuText from "../HeaderMenuText";
import HeaderMenuButton from "../HeaderMenuButton";

function HeaderMenuMobile() {
  const [showMainMenu, setShowMainMenu] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [widthMenu, setWidthMenu] = useState(0);

  const HeaderMenuMobile = style.HeaderMenuMobile;
  const HeaderMenuHam = style.HeaderMenuHam;
  const HeaderMenuMobileContentContainer =
    style.HeaderMenuMobileContentContainer;
  const HeaderMenuMobileContent = style.HeaderMenuMobileContent;
  const HeaderMenuHamImg = style.HeaderMenuHamImg;

  const _onClickMenuHamImg = (e) => {
    let rawMainShowMenu = !showMainMenu;

    if (rawMainShowMenu) {
      setWidthMenu(100);
    } else {
      setWidthMenu(0);
    }
    setShowMenu(rawMainShowMenu);
    setShowMainMenu(rawMainShowMenu);

    // for (let i = 1; i <= 100; i++) {
    //   if (rawMainShowMenu) {
    //     setShowMainMenu(rawMainShowMenu);
    //     setTimeout(() => {
    //       let rawWidthMenu = widthMenu + i;
    //       setWidthMenu(rawWidthMenu);
    //       if (i == 100) {
    //         setShowMenu(rawMainShowMenu);
    //       }
    //     }, 20);
    //   } else {
    //     setShowMenu(rawMainShowMenu);
    //     setTimeout(() => {
    //       let rawWidthMenu = widthMenu - i;
    //       setWidthMenu(rawWidthMenu);
    //       if (i == 100) {
    //         setShowMainMenu(rawMainShowMenu);
    //       }
    //     }, 20);
    //   }
    // }
  };

  return (
    <HeaderMenuMobile id="dk-website-header-menu-mobile">
      <HeaderMenuHam>
        <HeaderMenuHamImg
          containerShow={!showMainMenu}
          src={ButtonMenuMobile}
          onClick={_onClickMenuHamImg}
        />
        <HeaderMenuHamImg
          containerShow={showMainMenu}
          src={ButtonCloseMobile}
          onClick={_onClickMenuHamImg}
        />
      </HeaderMenuHam>
      <HeaderMenuMobileContentContainer
        containerShow={showMainMenu}
        widthMenu={widthMenu}
      >
        <HeaderMenuMobileContent showMenuMobileContent={showMenu}>
          <HeaderMenuText></HeaderMenuText>
          <HeaderMenuButton></HeaderMenuButton>
        </HeaderMenuMobileContent>
      </HeaderMenuMobileContentContainer>
    </HeaderMenuMobile>
  );
}

export default HeaderMenuMobile;
