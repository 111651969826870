import React, { useEffect, useState } from "react";
import styled from "styled-components";
import FarmPool from "./components/FarmPool/FarmPool";
import HeaderImageSource from "../../resources/images/2-Farm/Pic-Header.png";
import FarmTopMenu from "./components/FarmTopMenu/FarmTopMenu";
import FarmLeftMenu from "./components/FarmLeftMenu/FarmLeftMenu";
import CommonConstants from "../../resources/constants/CommonConstants";
import farmInfoMockup from "./mockup/farmInfo.mockup.json";
import farmItemsMockup from "./mockup/farmItems.mockup.json";
import FarmInfo from "../../resources/model/Farm/FarmInfo";
import FarmItem from "../../resources/model/Farm/FarmItem";
import FarmConstants from "../../resources/constants/FarmConstants";

const DemoText = styled.div`
  font-size: 40px;
  color: white;
  text-align: center;
`

function Farm() {
  /**
   * @type {[FarmInfo, Function]}
   */
  const [info, setInfo] = useState(null);
  /**
   * @type {[FarmItem[], Function]}
   */
  const [items, setItems] = useState([]);
  const [status, setStatus] = useState(FarmConstants.FILTER_STATUS.LIVE);
  const [stakedOnly, setStakedOnly] = useState(false);

  useEffect(() => {
    // asynchronous call parallel apis
    fetchItems();
    fetchInfo();
  }, []);

  const fetchItems = async () => {
    //TODO: fetch data from api
    setItems(farmItemsMockup);
  };

  const fetchInfo = async () => {
    //TODO: fetch data from api
    setInfo(farmInfoMockup);
  };

  const handleSearchTextSubmit = async (searchText) => {
    //TODO: call api
    // fake search not found if searchText is not blank
    if (searchText) {
      setItems([]);
    } else {
      setItems(farmItemsMockup);
    }
  };

  return (
    <Container>
      <FarmLeftMenu />
      <Section>
        <Header>
          <HeaderImage src={HeaderImageSource} />
          <DemoText>DEMO DATA</DemoText>
        </Header>
        <Content>
          <FarmTopMenu
            data={info}
            status={status}
            setStatus={setStatus}
            stakedOnly={stakedOnly}
            setStakedOnly={setStakedOnly}
            onSearchTextSubmit={handleSearchTextSubmit}
          />
          <PoolContainer>
            {items.map((item, index) => (
              <FarmPool
                key={"fItem_" + index}
                data={item}
                deposited={info?.deposited}
                status={status}
                stakedOnly={stakedOnly}
              />
            ))}
          </PoolContainer>
        </Content>
      </Section>
    </Container>
  );
}

export default Farm;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  z-index: 2;
`;

const Section = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: 290px;
  padding-top: 4rem;
  overflow-x: hidden;

  @media (max-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XL}) {
    padding-top: 2.5rem;
    margin-left: 0;
  }

  @media (max-width: ${CommonConstants.MIN_WIDTH_TABLET_MAX}) {
    padding-top: 2rem;
  }
`;

const Header = styled.header``;

const HeaderImage = styled.img`
  width: 100%;
`;

const Content = styled.div`
  padding: 2rem;

  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    padding: 1rem;
  }
`;

const PoolContainer = styled.div`
  margin-top: 2rem;
`;
