import styled from "styled-components";
import CommonConstants from "../../../../resources/constants/CommonConstants";
import FarmConstants from "../../../../resources/constants/FarmConstants";
import BoxHarvestImageSource from "../../../../resources/images/2-Farm/Box-Harvest.png";
import BoxDicerImageSource from "../../../../resources/images/2-Farm/Box-Dicer.png";
// FTMC = Farm Top Menu Component
export const FTMC_Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FTMC_BalanceContainer = styled.div`
  display: grid;
  grid-template-columns: calc(55% - 2.5rem) calc(45% - 2.5rem);
  gap: 5rem;

  @media (max-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XXL}) {
    grid-template-columns: calc(55% - 1.25rem) calc(45% - 1.25rem);
    gap: 2.5rem;
  }

  @media (max-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XL}) {
    grid-template-columns: calc(55% - 0.5rem) calc(45% - 0.5rem);
    gap: 1rem;
  }

  @media (max-width: ${CommonConstants.MIN_WIDTH_DESKTOP}) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
`;

export const FTMC_BoxHarvest = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: auto auto auto;
  justify-content: space-between;
  align-items: center;
  height: 9rem;
  overflow: hidden;
  border-radius: 1.5rem;
  padding: 0 3rem;
  background-image: url(${BoxHarvestImageSource});
  background-size: cover;
  background-position: right;

  @media (max-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XXL}) {
    padding: 0 1.5rem;
  }

  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    padding: 1rem;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
`;

export const FTMC_BoxDicer = styled(FTMC_BoxHarvest)`
  background-image: url(${BoxDicerImageSource});
  grid-template-columns: auto auto;

  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    grid-template-columns: auto auto auto;
  }
`;

export const FTMC_BackgroundImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: right;
`;

export const FTMC_DataContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  color: #6e2105;
  font-weight: bold;
  z-index: 10;

  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    &:nth-child(1) {
      grid-column: 1/3;
    }
  }
`;

export const FTMC_DataTopic = styled.div``;

export const FTMC_DataValue = styled.div`
  font-size: 2rem;

  @media (max-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XXL}) {
    font-size: 1.75rem;
  }
`;

export const FTMC_HarvestButton = styled.button`
  border: 3px solid #561700;
  border-radius: 0.5rem;
  background-image: linear-gradient(#aaff73, #67ef18, #4cc100);
  transform: skewX(-6deg);
  padding: 0.5rem 1.25rem;
  justify-content: center;
  font-size: 1.3rem;
  font-weight: bold;
  color: #4f1304;
  cursor: pointer;
  overflow: hidden;
  width: fit-content;
  justify-self: flex-end;

  @media (max-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XXL}) {
    font-size: 1.1rem;
  }

  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    font-size: 1rem;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #00000015;
    display: none;
  }

  &:focus::after {
    display: block;
  }

  &:focus {
    box-shadow: 0 0 0 3px #55555580;
  }
`;

export const FTMC_DicerButton = styled(FTMC_HarvestButton)`
  background-image: linear-gradient(#6cbdff, #2291ff, #007ce2);
`;

export const FTMC_ActionContainer = styled.div`
  display: grid;
  grid-template-columns: 18rem auto auto;
  align-items: center;
  margin-top: 4rem;
  gap: 1rem;

  @media (max-width: ${CommonConstants.MIN_WIDTH_DESKTOP}) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 2rem;
  }
`;

export const FTMC_StatusContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  background: #8a4829;
  border: 2px solid #1c0c0a;
  border-radius: 0.5rem;
  overflow: hidden;
  transform: skewX(-6deg);
  width: fit-content;
`;

export const FTMC_StatusSwitch = styled.div`
  position: absolute;
  border: 2px solid #1c0c0a;
  border-radius: 0.5rem;
  left: ${(props) =>
    props.status === FarmConstants.FILTER_STATUS.LIVE ? "-2px" : "calc(5rem - 2px)"};
  top: -2px;
  height: 100%;
  width: ${(props) => (props.status === FarmConstants.FILTER_STATUS.LIVE ? "5rem" : "6.5rem")};
  background: #ffe3c7;
  transition: all 150ms;
`;

export const FTMC_LiveStatus = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => (props.active ? "#4e0904" : "#b98769")};
  z-index: 10;
  font-weight: bold;
  width: 5rem;
  height: 2.5rem;
  text-align: center;
`;

export const FTMC_FinishedStatus = styled(FTMC_LiveStatus)`
  width: 6.5rem;
`;

export const FTMC_CheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  justify-self: flex-start;

  @media (max-width: ${CommonConstants.MIN_WIDTH_DESKTOP}) {
    justify-self: flex-end;
  }
`;

export const FTMC_CheckboxBg = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.7rem;
  height: 1.7rem;
  border: 2px solid #000000;
  border-radius: 0.4rem;
  background: #8a4829;
`;

export const FTMC_CheckboxIcon = styled.span`
  color: #ffe3c7;
`;

export const FTMC_CheckboxText = styled.div`
  font-weight: bold;
  color: #ffe3c7;
  display: flex;
  align-items: center;
  margin-left: 1rem;

  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    margin-left: 0.5rem;
  }
`;

export const FTMC_SearchInputContainer = styled.div`
  border: 2px solid #000000;
  transform: skewX(-6deg);
  border-radius: 0.25rem;
  background: #8a4829;
  padding: 0.4rem 1rem;
  max-width: 15rem;
  justify-self: flex-end;

  @media (max-width: ${CommonConstants.MIN_WIDTH_DESKTOP}) {
    grid-column: 1/3;
    max-width: 100%;
    width: calc(100% - 2.5rem);
    justify-self: center;
  }
`;

export const FTMC_SearchInput = styled.input`
  outline: none;
  border: none;
  font-size: 1rem;
  background: transparent;
  color: #f9e7c8;
  width: 100%;

  &::placeholder {
    color: #f9e7c8;
  }
`;

export const FTMC_MiniLeftMenuContainer = styled.div`
  display: none;
  justify-content: flex-end;
  flex: 1;
  margin-bottom: 2rem;

  @media (max-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XL}) {
    display: flex;
  }
`;

export const FTMC_MiniLeftMenuContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 12rem;
  height: 3rem;
  background: #2d0807;
  border: 2px solid #ffa300;
  border-radius: 0.4rem;
  transform: skewX(-6deg);
  color: #ffa300;
  font-size: 1.5rem;
  font-weight: bold;
  cursor: pointer;
  margin-right: 2.5rem;
`;

export const FTMC_MiniLeftMenuIcon = styled.img`
  margin-right: 1rem;
  width: 1.5rem;
  height: 1.5rem;
  object-fit: contain;
`;

export const FTMC_MiniLeftMenuSelector = styled.div`
  position: absolute;
  top: -2px;
  right: -2.3rem;
  width: 2.5rem;
  height: 3rem;
  background: #ffe3c7;
  border: 2px solid #000000;
  border-radius: 0.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000000;
  font-size: 2.75rem;
`;
