import Feature1 from "../images/1-Home/Overview-1.png";
import Feature2 from "../images/1-Home/Overview-2.png";
import Feature3 from "../images/1-Home/Overview-3.png";
import Feature4 from "../images/1-Home/Overview-4.png";
import Feature5 from "../images/1-Home/Overview-5.png";
import Feature6 from "../images/1-Home/Overview-6.png";

export default [{
        // title: "Farming",
        // detail: "Earn DK Token by Yield Farming or staking NFT Dicer.",
        image: Feature1,
    },
    {
        // title: "Dicer",
        // detail: "The character is NFT, there are differences stat and skills.",
        image: Feature2,
    },
    {
        // title: "Marketplace",
        // detail: "Dicers can be exchanged in the NFT market.",
        image: Feature3,
    },
    {
        // title: "Cloning",
        // detail: "Get new dicer by using 2 Dicers.",
        image: Feature4,
    },
    {
        // title: "Absorb",
        // detail: "Increases Dicer's stat by absorbing.",
        image: Feature5,
    },
    {
        // title: "Equipment",
        // detail: "Enhance your abilities with different Class Equipment.",
        image: Feature6,
    },
];