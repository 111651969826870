import React from "react";
import style from "./style/style";

const StyleSiteButtonDarkNotSkew = style.SiteButtonDarkNotSkew;

function SiteButtonDarkNotSkew(props) {
  const { children, ...rest } = props;

  return (
    <StyleSiteButtonDarkNotSkew
      active
      variant="contained"
      color="primary"
      disableRipple
      {...rest}
    >
      {children}
    </StyleSiteButtonDarkNotSkew>
  );
}

export default SiteButtonDarkNotSkew;
