import React, { useState, useEffect } from "react";
import style from "./style/style";

const StyleSiteProcessBar = style.SiteProcessBar;
const StyleSiteProcessBarTop = style.SiteProcessBarTop;
const StyleSiteProcessBarBottom = style.SiteProcessBarBottom;

function SiteProcessBar(props) {
  const { step, idBar, ...rest } = props;

  const [width, setWidth] = useState(0);

  useEffect(() => {
    _setWidthInStep();
    window.addEventListener("resize", _setWidthInStep());
  });

  const _offset = (el) => {
    var rect = el.getBoundingClientRect(),
      scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
      scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
  };

  const _setWidthInStep = () => {
    if (window.document.getElementById("home-road-map-line-step-0") != null) {
      console.log("_setWidthInStep start");
      let roadMapBar = window.document.getElementById("home-road-map-bar");
      let lineStep1 = window.document.getElementById(
        "home-road-map-line-step-0"
      );
      let lineStep2 = window.document.getElementById(
        "home-road-map-line-step-1"
      );
      let lineStep5 = window.document.getElementById(
        "home-road-map-line-step-4"
      );

      let widthBar = _offset(roadMapBar).left;
      let widthLine = _offset(lineStep1).left;
      let width = widthLine - widthBar;

      let halfLine = (_offset(lineStep2).left - _offset(lineStep1).left) / 2;

      let sum = 0;

      switch (step) {
        case 0:
          sum = 0;
          break;

        case 1:
          sum = width;
          break;

        case 9:
          sum = _offset(lineStep5).left - _offset(roadMapBar).left;
          break;

        case 10:
          sum = _offset(lineStep5).left - _offset(roadMapBar).left + width;
          break;

        default:
          sum = halfLine * (step - 1) + width;
          break;
      }

      setWidth(sum);
    }
  };

  return (
    <StyleSiteProcessBar {...rest}>
      <StyleSiteProcessBarTop
        id={idBar}
        style={{ width: width + "px" }}
      ></StyleSiteProcessBarTop>
      <StyleSiteProcessBarBottom
        style={{ width: width + "px" }}
      ></StyleSiteProcessBarBottom>
    </StyleSiteProcessBar>
  );
}

export default SiteProcessBar;
