import React from "react";
import _ from "lodash";
import Grid from "@material-ui/core/Grid";
import {
  ConHomeFeatures,
  ImgHomeFeatures,
  ContainerFeatures,
  ContainerFeaturesMobile,
} from "./HomeFeatures.component.js";
import MenuFeatures from "../../../../resources/images/1-Home/Menu-Features.png";
import Thumbnail from "../../../../resources/components/Thumbnail/Thumbnail";
import FeaturesConstants from "../../../../resources/constants/FeaturesConstants";

function HomeFeatures() {
  const listFeatures = FeaturesConstants;

  const _renderGridMain = () => {
    let chunkListFeatures = _.chunk(listFeatures, 3);

    return chunkListFeatures.map((item, index) => {
      return <Grid container>{_renderGridItem(item)}</Grid>;
    });
  };

  const _renderGridItem = (list) => {
    return list.map((item, index) => {
      return (
        <Grid item xs={4}>
          <Thumbnail
            key={"_renderGridItem" + index}
            title={item.title}
            detail={item.detail}
            image={item.image}
          />
        </Grid>
      );
    });
  };

  const _renderGridMainMobile = () => {
    let chunkListFeatures = _.chunk(listFeatures, 3);

    return chunkListFeatures.map((item, index) => {
      return <Grid container>{_renderGridItemMobile(item)}</Grid>;
    });
  };

  const _renderGridItemMobile = (list) => {
    return list.map((item, index) => {
      return (
        <Grid item xs={12}>
          <Thumbnail
            key={"_renderGridItemMobile" + index}
            title={item.title}
            detail={item.detail}
            image={item.image}
          />
        </Grid>
      );
    });
  };

  return (
    <ConHomeFeatures id="dk-website-home-page-features">
      <ImgHomeFeatures src={MenuFeatures}></ImgHomeFeatures>
      <ContainerFeatures>{_renderGridMain()}</ContainerFeatures>
      <ContainerFeaturesMobile>
        {_renderGridMainMobile()}
      </ContainerFeaturesMobile>
    </ConHomeFeatures>
  );
}

export default HomeFeatures;
