import styled from "styled-components";
import SloganBar from "../../../../resources/images/1-Home/Slogan-Bar.png"

export const ConHomeHighlight = styled.div `
  text-align: center;
  align-items: center;
  justify-content: center;
  align-content: center;

  background: url(${SloganBar});
  background-repeat: repeat;
  background-position: bottom;
  /* background-size: 100% 100%; */

  width: 100%;
  /* padding: 20px 0px; */
  /* min-height: 500px; */
`;

export const ImgHighlight = styled.img `
  padding: 20px 10px 20px 10px;
  max-width: 90%;
  max-height: 140px;
`;