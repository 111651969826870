const DemoPlayConstants = {
  MENU: {
    GAME: "GAME",
    NEWS: "NEWS",
    VIDEO: "VIDEO",
    LIBRARY: "LIBRARY",
  },
};

export default DemoPlayConstants;
