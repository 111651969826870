import React, { useState } from "react";
import styled from "styled-components";

function MarketplaceDialogAccordion({ title, children, alwaysShow = false }) {
  const [expanded, setExpanded] = useState(alwaysShow);

  const handleToggleExpandCollapse = () => {
    setExpanded(!expanded);
  };

  return (
    <Container>
      <Header>
        <Title>{title || "Title"}</Title>
        {!alwaysShow && (
          <HeaderAction onClick={handleToggleExpandCollapse}>
            {expanded ? "Hide" : "Show"}
          </HeaderAction>
        )}
      </Header>
      {expanded && <Content>{children}</Content>}
    </Container>
  );
}

export default MarketplaceDialogAccordion;

const Container = styled.div`
  background: #ffa300;
  border: 2px solid #8a4829;
  border-radius: 0.6rem;
  padding: 0.5rem 1rem;
  margin-top: 1rem;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Title = styled.div`
  font-size: 1rem;
  font-weight: bold;
  display: flex;
  flex: 1;
`;

const HeaderAction = styled.div`
  color: #ffffff;
  text-decoration: underline;
  cursor: pointer;
  font-size: 0.8rem;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
