import styled from "styled-components";
import CommonConstants from "../../../../../../resources/constants/CommonConstants";

export const MarketplaceFilterTapPage = styled.div `
  position: relative;
  justify-content: center;
`;

export const MarketplaceFilterTapTitleContent = styled.div `
  display: flex;
  flex-direction: row;
  position: relative;
  justify-content: center;
`;

export const MarketplaceFilterTapTitleText = styled.div `
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
  flex-grow: 1;
  align-self: stretch;
`;

export const MarketplaceFilterTapTitleTextRight = styled.div `
  color: ${CommonConstants.COLOR_ACTIVE};
  font-size: 1rem;
  font-weight: normal;
  cursor: pointer;
`;