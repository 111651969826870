import React from "react";
import {
  MarketplaceFilterTapPage,
  MarketplaceFilterTapTitleContent,
  MarketplaceFilterTapTitleText,
  MarketplaceFilterTapTitleTextRight,
} from "./MarketplaceFilterTap.component";

function MarketplaceFilterTap(props) {
  const { children, titleTxt, clearTxt } = props;
  return (
    <MarketplaceFilterTapPage id="dk-website-marketplace-page-dicer-filter-tap">
      <MarketplaceFilterTapTitleContent>
        <MarketplaceFilterTapTitleText>
          {titleTxt}
        </MarketplaceFilterTapTitleText>
        <MarketplaceFilterTapTitleTextRight>
          {clearTxt}
        </MarketplaceFilterTapTitleTextRight>
      </MarketplaceFilterTapTitleContent>
      {children}
    </MarketplaceFilterTapPage>
  );
}

export default MarketplaceFilterTap;
