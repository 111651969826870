import styled from "styled-components";
import CommonConstants from "../../../../resources/constants/CommonConstants";
// FPHC = Farm Pool Header Component
export const FPHC_Header = styled.div`
  display: grid;
  grid-template-columns: auto auto auto auto auto auto;
  gap: 1rem;
  align-items: center;

  @media (max-width: ${CommonConstants.MIN_WIDTH_DESKTOP}) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
`;

export const FPHC_HeaderIcon = styled.img`
  height: 60px;
  object-fit: contain;
  margin-right: 1rem;
`;

export const FPHC_ContentContainer = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.row ? "row" : "column")};
  align-items: ${(props) => (props.itemCenter ? "center" : "flex-start")};

  @media (max-width: ${CommonConstants.MIN_WIDTH_DESKTOP}) {
    &:nth-child(1) {
      grid-column: 1/3;
    }
  }
`;

export const FPHC_Title = styled.div`
  font-size: 1.75rem;
  font-weight: bold;
  color: #ff6d00;

  @media (max-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XXL}) {
    font-size: 1.5rem;
  }
`;

export const FPHC_SubTitle = styled.div``;

export const FPHC_HighlightContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;

export const FPHC_HighlightText = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  color: #ffa300;

  @media (max-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XXL}) {
    font-size: 1.3rem;
  }
`;

export const FPHC_HightlightTextDetail = styled.div`
  font-size: 0.9rem;
  line-height: 1.9;
  margin-left: 16px;

  @media (max-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XXL}) {
    font-size: 0.8rem;
  }

  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    visibility: hidden;
  }
`;

export const FPHC_DetailContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  /* cursor: pointer; */

  @media (max-width: ${CommonConstants.MIN_WIDTH_DESKTOP}) {
    grid-column: 1/3;
    justify-content: center;
  }

  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    display: ${(props) => (props.expanded ? "none" : "flex")};
  }
`;

export const FPHC_DetailText = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  color: #ff6d00;
  margin-right: 12px;

  @media (max-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XXL}) {
    font-size: 1.3rem;
  }

  @media (max-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XL}) {
    font-size: 1.1rem;
  }
`;

export const FPHC_DetailIcon = styled.span`
  color: #ff6d00;
  transition: transform 150ms;
  transform: rotate(${(props) => (props.expanded ? 0 : 180)}deg);
`;
