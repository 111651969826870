import {
  Select,
  withStyles
} from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import SiteTextField from "../../../../../../resources/components/SiteTextField/SiteTextField";
import CommonConstants from "../../../../../../resources/constants/CommonConstants";

export const MarketplaceFilterPage = styled.div `
  position: relative;
  /* background-color: #FFF; */
  margin-bottom: 7rem;
  /* height: 33%; */
  /* overflow: auto; */
`;

export const MarketplaceFilterContent = styled.div `
  width: 100%;
  position: relative;
  margin-bottom: 10px;
  text-align: center;
`;

export const MarketplaceFilterTitle = styled.div `
  width: 100%;
  text-align: left;
  color: #fff;
  font-weight: bold;
  font-size: 1rem;
`;
export const MarketplaceFilterIconSelect = styled.img `
  /* width: 0%; */
  height: 37px;
`;

export const MarketplaceFilterInputContainerSelect = styled.div `
  border: 2px solid #9e6547;
  transform: skewX(-6deg);
  border-radius: 0.25rem;
  background: #ffffff;
  padding: 0.4rem 1rem;
`;

// export const MarketplaceFilterInputSelect = styled.input `
//   outline: none;
//   border: none;
//   font-size: 1.3rem;
//   color: #9e6547;
//   width: 100%;

//   &::placeholder {
//     color: #9e6547;
//   }

//   @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
//     font-size: 1rem;
//   }
// `;

const CustomizedSelect = withStyles({
  root: {
    "&:focus": {
      border: "none",
      backgroundColor: "#1c0b09",
    },
  },

  select: {
    height: "20px",
    boxShadow: "none",
    textTransform: "none",
    border: "1px solid #ffecd1",
    borderRadius: "3px",
    marginRight: "-3px",
    background: "#1c0b09",
    fontWeight: 900,
    transform: "skewX(174deg)",
    width: "100%",
    color: "#ffecd1",
    "&:placeholder": {
      color: "#ffecd1",
    },
    "&:focus": {
      border: "1px solid #ffecd1",
      borderRadius: "3px",
      backgroundColor: "#1c0b09",
    },
    "&:active": {
      border: "1px solid #ffecd1",
      borderRadius: "3px",
      backgroundColor: "#1c0b09",
    },
  },
})(Select);

export const MarketplaceFilterInputSelect = styled(CustomizedSelect)
``;

export const MarketplaceFilterSelectSpan = styled.div `
  color: #ffecd1;
  font-size: 1rem;
  width: 100%;
  text-align: center;
`;

export const MarketplaceFilterSearchContainer = styled.div `
  position: relative;
  text-align: left;
  margin-bottom: 20px;
`;

export const MarketplaceFilterSearchTextField = styled(SiteTextField)
`
  font-size: 0.8rem;
`;

export const MarketplaceFilterSearchBottomContainer = styled.div `
  display: flex;
  color: #663304;
  font-size: 0.65rem;
  font-weight: bold;
`;

export const MarketplaceFilterSearchBottomContainerItem = styled.div `
  color: #663304;
  font-size: 0.65rem;
  font-weight: bold;
  padding-left: 3px;
  padding-right: 3px;
  cursor: pointer;
`;

export const MarketplaceFilterSearchBottomContainerItemActive = styled.div `
  color: ${CommonConstants.COLOR_ACTIVE};
  font-size: 0.65rem;
  font-weight: bold;
  padding-left: 3px;
  padding-right: 3px;
`;

export const MarketplaceFilterCheckboxImage = styled.div `
  width: 100%;
  text-align: left;
  justify-content: left;
  /* margin-top: 10px; */
`;

export const MarketplaceFilterContentCheckBoxImage = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
`;