class BuyDicerData {
  constructor() {
    this.name = "Dicer Box1";
    this.description = "Each box random Dicer";
    this.buyPrice = 10;
    this.fastPrice = 20;
    this.availableAmount = 495;
    this.totalAmount = 1000;
    this.timeLeft = 285;
  }
}

export default BuyDicerData;
