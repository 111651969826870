import React, { useState } from "react";
import {
  OverviewMarketListContainer,
  OverviewMarketListTitle,
  OverviewMarketListItemContainer,
  OverviewMarketListItemImageContainer,
  OverviewMarketListItemImageBox,
  OverviewMarketListItemImage,
  OverviewMarketListItemNumberInImageContainer,
  OverviewMarketListItemTitleContainer,
  OverviewMarketListItemTitleId,
  OverviewMarketListItemTitleDetailContainer,
  OverviewMarketListItemTitleDetailLabel,
  OverviewMarketListItemTitleDetailValue,
  OverviewMarketListItemDetailContainer,
  OverviewMarketListItemDetailBoxContainer,
  OverviewMarketListItemDetailBox,
  OverviewMarketListItemDetailBoxLabel,
  OverviewMarketListItemDetailBoxValue,
  OverviewMarketListItemPriceContainer,
  OverviewMarketListItemPriceImageBox,
  OverviewMarketListItemPriceImage,
  OverviewMarketListItemPriceDetailContainer,
  OverviewMarketListItemPriceDetailHighlight,
  OverviewMarketListItemPriceDetailHighlightSmall,
  OverviewMarketListItemPriceDetail,
  OverviewMarketListItemButtonContainer,
  OverviewMarketListItemButton,
  OverviewMarketListItemButtonIcon,
  OverviewMarketListBtnViewMore
} from "./OverviewMarketList.component";
import DicerImage from "../../../../resources/images/1-Home/Character-Bear.png";
import EggImage from "../../../../resources/images/4-Marketplace/Capsule.png";
import BitkubCoinImage from "../../../../resources/images/6-Overview/bitkubCoin.png";

function OverviewMarketList(props) {
  let { data = [], title, onClickItem = () => {}, onClickViewMore = () => {}, ...rest } = props;

  const _renderItemsList = () => {
    return data.map((item, index) => (
      <OverviewMarketListItemContainer onClick={onClickItem}>
        {_renderItemImage(item)}
        {_renderItemTitle(item)}
        {_renderItemDetail(item)}
        {_renderItemPrice(item)}
        <OverviewMarketListItemButtonContainer>
          <OverviewMarketListItemButton>
            {/* {">"} */}
            <OverviewMarketListItemButtonIcon fontSize={"large"} />
          </OverviewMarketListItemButton>
        </OverviewMarketListItemButtonContainer>
      </OverviewMarketListItemContainer>
    ));
  };

  const _renderItemImage = (item) => {
    return (
      <OverviewMarketListItemImageContainer>
        <OverviewMarketListItemImageBox>
          <OverviewMarketListItemImage
            src={
              item?.urlImage
                ? item?.urlImage
                : item?.type === "dicer"
                ? DicerImage
                : EggImage
            }
          />
        </OverviewMarketListItemImageBox>
        <OverviewMarketListItemNumberInImageContainer>
          {item?.parts.map((partItem) => {
            return partItem + " ";
          })}
        </OverviewMarketListItemNumberInImageContainer>
      </OverviewMarketListItemImageContainer>
    );
  };

  const _renderItemTitle = (item) => {
    return (
      <OverviewMarketListItemTitleContainer>
        <OverviewMarketListItemTitleId>
          Dicer #{item?.id}
        </OverviewMarketListItemTitleId>
        <OverviewMarketListItemTitleDetailContainer>
          <OverviewMarketListItemTitleDetailLabel>
            Clone &nbsp;
          </OverviewMarketListItemTitleDetailLabel>
          <OverviewMarketListItemTitleDetailValue>
            {item?.clone}
          </OverviewMarketListItemTitleDetailValue>
          <OverviewMarketListItemTitleDetailLabel>
            {" "}
            &nbsp; | absorb&nbsp;
          </OverviewMarketListItemTitleDetailLabel>
          <OverviewMarketListItemTitleDetailValue>
            {item?.absorb}
          </OverviewMarketListItemTitleDetailValue>
        </OverviewMarketListItemTitleDetailContainer>
      </OverviewMarketListItemTitleContainer>
    );
  };

  const _renderItemDetail = (item) => {
    if (item?.type === "dicer") {
      return (
        <OverviewMarketListItemDetailContainer>
          <OverviewMarketListItemDetailBoxContainer>
            <OverviewMarketListItemDetailBox>
              <OverviewMarketListItemDetailBoxLabel>
                Wealth
              </OverviewMarketListItemDetailBoxLabel>
              <OverviewMarketListItemDetailBoxValue>
                {item?.wealth}
              </OverviewMarketListItemDetailBoxValue>
            </OverviewMarketListItemDetailBox>
            <OverviewMarketListItemDetailBox
              style={{ margin: "0.5rem 0rem 0.5rem 0rem" }}
            >
              <OverviewMarketListItemDetailBoxLabel>
                Manage
              </OverviewMarketListItemDetailBoxLabel>
              <OverviewMarketListItemDetailBoxValue>
                {item?.manage}
              </OverviewMarketListItemDetailBoxValue>
            </OverviewMarketListItemDetailBox>
            <OverviewMarketListItemDetailBox>
              <OverviewMarketListItemDetailBoxLabel>
                Devotion
              </OverviewMarketListItemDetailBoxLabel>
              <OverviewMarketListItemDetailBoxValue>
                {item?.devotion}
              </OverviewMarketListItemDetailBoxValue>
            </OverviewMarketListItemDetailBox>
          </OverviewMarketListItemDetailBoxContainer>

          <OverviewMarketListItemDetailBoxContainer>
            <OverviewMarketListItemDetailBox>
              <OverviewMarketListItemDetailBoxLabel>
                Luck
              </OverviewMarketListItemDetailBoxLabel>
              <OverviewMarketListItemDetailBoxValue>
                {item?.luck}
              </OverviewMarketListItemDetailBoxValue>
            </OverviewMarketListItemDetailBox>
            <OverviewMarketListItemDetailBox
              style={{ margin: "0.5rem 0rem 0.5rem 0rem" }}
            >
              <OverviewMarketListItemDetailBoxLabel>
                Charm
              </OverviewMarketListItemDetailBoxLabel>
              <OverviewMarketListItemDetailBoxValue>
                {item?.charm}
              </OverviewMarketListItemDetailBoxValue>
            </OverviewMarketListItemDetailBox>
            <OverviewMarketListItemDetailBox>
              <OverviewMarketListItemDetailBoxLabel>
                Skill
              </OverviewMarketListItemDetailBoxLabel>
              <OverviewMarketListItemDetailBoxValue>
                {item?.skill}
              </OverviewMarketListItemDetailBoxValue>
            </OverviewMarketListItemDetailBox>
          </OverviewMarketListItemDetailBoxContainer>
        </OverviewMarketListItemDetailContainer>
      );
    } else {
      return null;
    }
  };

  const _renderItemPrice = (item) => {
    return (
      <OverviewMarketListItemPriceContainer
        contenStretch={item?.type === "dicer" ? "stretch" : "no"}
      >
        <OverviewMarketListItemPriceImageBox>
          <OverviewMarketListItemPriceImage src={BitkubCoinImage} />
        </OverviewMarketListItemPriceImageBox>

        <OverviewMarketListItemPriceDetailContainer>
          <OverviewMarketListItemPriceDetailContainer>
            <OverviewMarketListItemPriceDetailHighlight>
              {item?.priceKUB}
            </OverviewMarketListItemPriceDetailHighlight>
            <OverviewMarketListItemPriceDetailHighlightSmall>
              &nbsp;KUB
            </OverviewMarketListItemPriceDetailHighlightSmall>
            <OverviewMarketListItemPriceDetail>
              &nbsp;≈ {item?.priceUS}
            </OverviewMarketListItemPriceDetail>
          </OverviewMarketListItemPriceDetailContainer>
          <OverviewMarketListItemPriceDetailContainer>
            <OverviewMarketListItemPriceDetail>
              {item?.lastUpdate}
            </OverviewMarketListItemPriceDetail>
          </OverviewMarketListItemPriceDetailContainer>
        </OverviewMarketListItemPriceDetailContainer>
      </OverviewMarketListItemPriceContainer>
    );
  };

  const _renderButtonViewMore = () => {
    return <OverviewMarketListBtnViewMore onClick={onClickViewMore}>
      View more on marketplace
    </OverviewMarketListBtnViewMore>

  }

  return (
    <OverviewMarketListContainer {...rest}>
      <OverviewMarketListTitle>{title}</OverviewMarketListTitle>
      {_renderItemsList()}
      {_renderButtonViewMore()}
    </OverviewMarketListContainer>
  );
}

export default OverviewMarketList;
