import React from "react";
import style from "./style/style";

const StyleSiteButtonBule = style.SiteButtonBule;

function SiteButtonBule(props) {
  const { children, ...rest } = props;

  return (
    <StyleSiteButtonBule
      active
      variant="contained"
      color="primary"
      disableRipple
      {...rest}
    >
      {children}
    </StyleSiteButtonBule>
  );
}

export default SiteButtonBule;
