import React, { useEffect, useState } from "react";
import {
  FPDC_ButtonContainer,
  FPDC_CancelButton,
  FPDC_Container,
  FPDC_Dialog,
  FPDC_DicerAvatar,
  FPDC_DicerAvatarContainer,
  FPDC_DicerID,
  FPDC_DicerItem,
  FPDC_DicerItemSelected,
  FPDC_DicerListContainer,
  FPDC_SubmitButton,
  FPDC_Title,
} from "./FarmPoolDialog.component";
import CharacterBearImageResource from "../../../../resources/images/1-Home/Character-Bear.png";
import farmDialogMockup from "../../mockup/farmDialog.mockup.json";

/**
 *
 * @param {Props} props
 * @returns {React.FC}
 */
function FarmPoolDialog(props) {
  const { visible, stakeAction, onSubmit, onClose } = props;
  const [dicers, setDicers] = useState([]);
  const [selectedDicer, setSelectedDicer] = useState(null);

  useEffect(() => {
    fetchDicers();
  }, []);

  const fetchDicers = async () => {
    setDicers(farmDialogMockup);
    setSelectedDicer(farmDialogMockup[0]);
  };

  const handleSelectDicer = (dicer) => {
    setSelectedDicer(dicer);
  };

  const handleSubmit = () => {
    onSubmit && onSubmit(selectedDicer);
  };

  return (
    <FPDC_Container visible={visible}>
      <FPDC_Dialog>
        <FPDC_Title>{stakeAction} your dicer</FPDC_Title>
        <FPDC_DicerListContainer>
          {dicers.map((item) => (
            <FPDC_DicerItem key={item.id} onClick={() => handleSelectDicer(item)}>
              {selectedDicer?.id === item.id && (
                <FPDC_DicerItemSelected className="material-icons">check</FPDC_DicerItemSelected>
              )}
              <FPDC_DicerAvatarContainer selected={selectedDicer?.id === item.id}>
                <FPDC_DicerAvatar src={CharacterBearImageResource} />
              </FPDC_DicerAvatarContainer>
              <FPDC_DicerID>Dicer {item.id}</FPDC_DicerID>
            </FPDC_DicerItem>
          ))}
        </FPDC_DicerListContainer>
        <FPDC_ButtonContainer>
          <FPDC_CancelButton onClick={onClose}>Cancel</FPDC_CancelButton>
          <FPDC_SubmitButton onClick={handleSubmit}>{stakeAction}</FPDC_SubmitButton>
        </FPDC_ButtonContainer>
      </FPDC_Dialog>
    </FPDC_Container>
  );
}

export default FarmPoolDialog;

class Props {
  constructor() {
    this.visible = false;
    this.stakeAction = "";
    this.onClose = () => {};
    this.onSubmit = () => {};
  }
}
