import React from "react";
import styled from "styled-components";
import CommonConstants from "../../../resources/constants/CommonConstants";
import DicerBoxImageResource from "../../../resources/images/3-Dicer/Dicer-Box.png";
import BuyDicerData from "../../../resources/model/Dicer/BuyDicerData";

/**
 *
 * @param {Props} props
 * @returns {React.FC}
 */
function BuyDicerItem(props) {
  const { data } = props;
  return (
    <Container>
      <Title>{data?.name}</Title>
      <ContentRow>
        <DicerBoxImage src={DicerBoxImageResource} />
        <DetailContent>
          <DescriptionText>{data?.description}</DescriptionText>
          <PriceText>Price : {data?.buyPrice} KUB</PriceText>
          <StockText>
            {data?.availableAmount?.toLocaleString()} of {data?.totalAmount?.toLocaleString()} left
          </StockText>
          <ButtonRow>
            <BuyButtonContainer>
              <TimeCounterText>Time : {data?.timeLeft}</TimeCounterText>
              <ButtonSubText>{data?.buyPrice} KUB</ButtonSubText>
              <ButtonText>Buy</ButtonText>
            </BuyButtonContainer>
            <FastButtonContainer>
              <ButtonSubText>{data?.fastPrice} KUB</ButtonSubText>
              <ButtonText>Fast</ButtonText>
            </FastButtonContainer>
          </ButtonRow>
        </DetailContent>
      </ContentRow>
    </Container>
  );
}

export default BuyDicerItem;

const Container = styled.div`
  padding: 1rem 1.8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
  border: 1px solid #f4a623;
  border-radius: 12px;
  color: #f9e7c8;
  background-color: #1c0b09;
`;

const Title = styled.div`
  font-size: 1.25rem;
  font-weight: bold;
  color: #dc5e00;
`;

const ContentRow = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  margin-top: 1rem;
  gap: 1.5rem;

  @media (max-width: ${CommonConstants.MIN_WIDTH_DESKTOP}) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const DicerBoxImage = styled.img`
  width: 200px;
  height: 200px;
  object-fit: contain;
  justify-self: center;
`;

const DetailContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const DescriptionText = styled.div`
  font-size: 1rem;
  color: #ffa300;
  margin-bottom: 1.5rem;
`;

const PriceText = styled.div`
  font-size: 1rem;
  color: #f2e0c2;
`;

const StockText = styled(PriceText)`
  margin-bottom: 3rem;
`;

const ButtonRow = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  gap: 1rem;
`;

const TimeCounterText = styled.div`
  position: absolute;
  top: -1.5rem;
  color: red;
`;

const BuyButtonContainer = styled.button`
  position: relative;
  border: 3px solid #561700;
  border-radius: 0.5rem;
  background-image: linear-gradient(#aaff73, #67ef18, #4cc100);
  transform: skewX(-6deg);
  padding: 0.5rem 1.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: fit-content;
  min-width: 8rem;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #00000015;
    display: none;
  }

  &:focus::after {
    display: block;
  }

  &:focus {
    box-shadow: 0 0 0 3px #55555580;
  }
`;

const FastButtonContainer = styled(BuyButtonContainer)`
  background-image: linear-gradient(#6cbdff, #2291ff, #007ce2);
`;

const ButtonText = styled.div`
  font-size: 1rem;
  font-weight: bold;
  color: #4f1304;
  line-height: 1;
`;

const ButtonSubText = styled.div`
  color: #4f1304;
`;

class Props {
  constructor() {
    /**
     * @type {BuyDicerData}
     */
    this.data = {};
  }
}
