class FarmItem {
  constructor() {
    this.name = "";
    this.earnType = "";
    this.multiplyRate = 0;
    this.depositFee = 0;
    this.earnValue = 0;
    this.earnValueAround = 0;
    this.depositLP = "";
    this.depositLPAround = 0;
    this.totalAPR = 0;
    this.tvl = "";
    this.status = "";
    this.staked = false;
    this.contentMode = "";
  }
}

export default FarmItem;
