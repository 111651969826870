import React from "react";
import style from "../style/style";

import ButtonFB from "../../../../images/1-Home/Button-FB.png";
import ButtonIG from "../../../../images/1-Home/Button-IG.png";
import ButtonTwitter from "../../../../images/1-Home/Button-Twitter.png";
import ButtonYT from "../../../../images/1-Home/Button-YT.png";
import ButtonMD from "../../../../images/1-Home/Button-MD.png";
import ButtonTG from "../../../../images/1-Home/Button-TG.png";
import ButtonDC from "../../../../images/1-Home/Button-DC.png";
import URLConstants from "../../../../constants/URLConstants";

function FooterSocial() {
  const StyleFooterSocial = style.FooterSocial;
  const StyleFooterSocialFacebook = style.FooterSocialFacebook;
  const StyleFooterSocialTwitter = style.FooterSocialTwitter;
  const StyleFooterSocialIG = style.FooterSocialIG;
  const StyleFooterSocialYoutube = style.FooterSocialYoutube;
  const StyleFooterSocialMedium = style.FooterSocialMedium;
  const StyleFooterSocialDiscord = style.FooterSocialDiscord;
  const StyleFooterSocialTelegram = style.FooterSocialTelegram;

  const onClickLinkIcon = (link) => {
    window.open(link);
  };

  return (
    <StyleFooterSocial>
      <StyleFooterSocialTwitter
        src={ButtonTwitter}
        onClick={() => {
          onClickLinkIcon(URLConstants.URL_TWITTER);
        }}
      />

      <StyleFooterSocialFacebook
        src={ButtonFB}
        onClick={() => {
          onClickLinkIcon(URLConstants.URL_FACEBOOK);
        }}
      />

      <StyleFooterSocialTelegram
        src={ButtonTG}
        onClick={() => {
          onClickLinkIcon(URLConstants.URL_TELEGRAM);
        }}
      />

      <StyleFooterSocialMedium
        src={ButtonMD}
        onClick={() => {
          onClickLinkIcon(URLConstants.URL_MEDIUM);
        }}
      />

      <StyleFooterSocialDiscord
        src={ButtonDC}
        onClick={() => {
          onClickLinkIcon(URLConstants.URL_DISCORD);
        }}
      />

      <StyleFooterSocialYoutube
        src={ButtonYT}
        onClick={() => {
          onClickLinkIcon(URLConstants.URL_YOUTUBE);
        }}
      />

      {/* <StyleFooterSocialIG
        src={ButtonIG}
        onClick={() => {
          onClickLinkIcon(URLConstants.URL_IG);
        }}
      /> */}
    </StyleFooterSocial>
  );
}

export default FooterSocial;
