import {
  InputBase,
  ListItemText,
  Select,
  Typography,
  withStyles,
} from "@material-ui/core";
import styled from "styled-components";
import CommonConstants from "../../../constants/CommonConstants";
import BoxSelect from "../../../images/4-Marketplace/Box-Select.png";

import CursorImage from "../../../images/6-Overview/Cursor.png";
import { ArrowDropDown, ArrowDropDownCircleOutlined } from "@material-ui/icons";

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: "0.3rem",
    position: "relative",
    background: "rgb(28 12 10)",
    display: "flex",
    border: `1px solid ${CommonConstants.COLOR_ACTIVE}`,
    fontSize: "14px !important",
    // padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    color: "#FFFFFF",
    // height: "34px",
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: `${CommonConstants.COLOR_ACTIVE}`,
      boxShadow: `0 0 0 0.2rem ${CommonConstants.COLOR_ACTIVE}`,
      background: "rgb(28 12 10)",
    },
  },
  icon: {
    color: `${CommonConstants.COLOR_ACTIVE}`,
  },
}))(InputBase);

const SiteSelectImage = styled(Select)`
  width: ${(props) => props.width}px;
`;

const SiteSelectImageBootstrapInput = styled(BootstrapInput)`
  /* width: 500px; */
`;

const SiteSelectImageIcon = styled(ArrowDropDown)`
  color: ${CommonConstants.COLOR_ACTIVE} !important;
  margin-right: 5px;
`;

const SiteSelectIconSelect = styled.img`
  /* width: 0%; */
  height: 37px;
  transform: skewX(175deg);
  cursor: pointer;
  margin-left: -2px;
  margin-top: -1px;
`;

const SiteSelect = styled.select`
  /* margin: 50px; */
  width: 100%;
  cursor: pointer;
  transform: skewX(170deg);
  width: 100%;
  padding: 5px 35px 5px 5px;
  font-size: 16px;
  color: #ffecd1;
  border: 1px solid #ffecd1;
  height: 34px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #1c0b09;
  background: url(${BoxSelect}) 100% / 27px no-repeat #1c0b09;
`;

const SiteSelectNotSkew = styled.select`
  /* margin: 50px; */
  width: 100%;
  cursor: pointer;
  /* transform: skewX(170deg); */
  width: 100%;
  padding: 3px 35px 5px 10px;
  font-size: 16px;
  color: #ffffff;
  border: 3px solid rgb(95, 54, 17);
  height: 34px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 8px;
  background: url(${CursorImage});

  /* background-image: url("data:image/svg+xml;utf8,<path xmlns='http://www.w3.org/2000/svg' d='M347.902 147.5C349.057 149.5 351.943 149.5 353.098 147.5L356.995 140.75C358.15 138.75 356.707 136.25 354.397 136.25H346.603C344.293 136.25 342.85 138.75 344.005 140.75L347.902 147.5Z' fill='#2F1C1A'/>"); */
  background-color: rgb(128, 75, 49);
  background-repeat: no-repeat;
  background-position: 97% center;
  background-size: 13px;

  /* &:focus {
    border: 3px solid rgb(95, 54, 17) !important;
  }
  &:active {
    border: 3px solid rgb(95, 54, 17)!important;
  } */
`;

const SiteSelectOption = styled.option`
  background-color: #ffecd1;
  color: #1c0b09;
`;

const MenuItemIcon = styled.img`
  max-height: 20px;
`;

const MenuItemText = styled(Typography)`
  font-size: 0.875rem !important;
  font-weight: bold !important;
`;

export default {
  SiteSelect: SiteSelect,
  SiteSelectNotSkew: SiteSelectNotSkew,
  SiteSelectOption: SiteSelectOption,
  SiteSelectIconSelect: SiteSelectIconSelect,
  SiteSelectImage: SiteSelectImage,
  SiteSelectImageBootstrapInput: SiteSelectImageBootstrapInput,
  SiteSelectImageIcon: SiteSelectImageIcon,
  MenuItemIcon: MenuItemIcon,
  MenuItemText: MenuItemText,
};
