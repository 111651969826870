import React from "react";
import {
  MarketplaceSectionTapPage,
  MarketplaceSectionTapTitle,
  MarketplaceSectionTapContent,
  MarketplaceSectionTapText,
  MarketplaceSectionTapRight,
} from "./MarketplaceSectionTap.component";

function MarketplaceSectionTap(props) {
  const { children, titleTxt, rightContent } = props;
  return (
    <MarketplaceSectionTapPage id="dk-website-marketplace-page-dicer-section-tap">
      <MarketplaceSectionTapTitle>
        <MarketplaceSectionTapText>{titleTxt}</MarketplaceSectionTapText>
        <MarketplaceSectionTapRight>{rightContent}</MarketplaceSectionTapRight>
      </MarketplaceSectionTapTitle>
      <MarketplaceSectionTapContent>{children}</MarketplaceSectionTapContent>
    </MarketplaceSectionTapPage>
  );
}

export default MarketplaceSectionTap;
