import styled from "styled-components";
import SiteSelectImage from "../../../../../../resources/components/SiteSelect/SiteSelectImage";
import CommonConstants from "../../../../../../resources/constants/CommonConstants";
import SiteButtonDarkNotSkew from "../../../../../../resources/components/SiteButton/SiteButtonDarkNotSkew";

export const MarketplaceHeaderTapPageMbile = styled.div `
  position: relative;
  z-index: 3;
  height: auto;
  margin: 1rem 1.5rem 1rem 1.5rem;
  padding-top: 5rem;
  display: flex;
  flex-direction: row;

  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    padding-top: 3rem;
    margin-bottom: 0px;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_TABLET_MIN}) and (max-width: ${CommonConstants.MIN_WIDTH_TABLET_MAX}) {
    padding-top: 4rem;
    margin-bottom: 0px;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP}) and (max-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XL}) {
    display: none;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XL}) {
    display: none;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XXL}) {
    display: none;
  }
`;

export const MarketplaceHeaderTapSelectImage = styled(SiteSelectImage)
``;

export const MarketplaceHeaderTapSelectImageContainer = styled.div `
  flex-grow: 1;
`;

export const MarketplaceHeaderTapFilterContainer = styled.div ``;

export const MarketplaceHeaderTapBtnFilter = styled(SiteButtonDarkNotSkew)
`
  /* height: 54px; */
`;