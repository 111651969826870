import React from "react";
import SiteProcessBar from "../SiteProcessBar/SiteProcessBar";
import SiteStepperContent from "./components/SiteStepperContent";
import SiteStepperContentMobile from "./components/SiteStepperContentMobile";
import SiteStepperTitle from "./components/SiteStepperTitle";
import style from "./style/style";

const StyleSiteStepper = style.SiteStepper;
const StyleSiteStepperProcessBar = style.SiteStepperProcessBar;
const StyleSiteStepperProcessBarMobile = style.SiteStepperProcessBarMobile;

function SiteStepper(props) {
  const { title, percent, step, stepList, idBar, ...rest } = props;

  return (
    <StyleSiteStepper {...rest}>
      <SiteStepperTitle>{title}</SiteStepperTitle>
      <StyleSiteStepperProcessBar step={step} id={idBar} />
      <StyleSiteStepperProcessBarMobile step={step} id={idBar + "mobile"} />
      <SiteStepperContent list={stepList}></SiteStepperContent>
      <SiteStepperContentMobile list={stepList}></SiteStepperContentMobile>
    </StyleSiteStepper>
  );
}

export default SiteStepper;
