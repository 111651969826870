import React, { useEffect, useState } from "react";
import styled from "styled-components";
import MyDicerData from "../../../resources/model/Dicer/MyDicerData";
import myDicerMockup from "../mockup/myDicer.mockup.json";
import MyDicerItem from "./MyDicerItem";

function MyDicer() {
  /**
   * @type [MyDicerData[], Function]
   */
  const [myDicers, setMyDicers] = useState([]);

  useEffect(() => {
    fetchMyDicers();
  }, []);

  const fetchMyDicers = async () => {
    //TODO: fetch data from api
    setMyDicers(myDicerMockup);
  };

  return (
    <Container>
      <Title>XXXXXX Dicer</Title>
      <Content>
        {myDicers.map((item, index) => (
          <MyDicerItem key={"myDicer_" + index} data={item} />
        ))}
      </Content>
    </Container>
  );
}

export default MyDicer;

const Container = styled.div`
  margin-top: 2rem;
`;

const Title = styled.div`
  font-size: 1rem;
  font-weight: bold;
  color: #ffffff;
`;

const Content = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;
