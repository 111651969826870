import styled from "styled-components";
import CommonConstants from "../../../../resources/constants/CommonConstants";
// FPCC = Farm Pool Content Component
export const FPCC_Main = styled.div`
  display: ${(props) => (props.expanded ? "grid" : "none")};
  grid-template-columns: auto auto;
  gap: 2rem;
  justify-content: center;
  margin-top: 1rem;

  @media (max-width: ${CommonConstants.MIN_WIDTH_DESKTOP}) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
`;

export const FPCC_Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 1.5rem;
  background: #f9e7c8;
  padding: 1.2rem;
  min-width: 13rem;

  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    padding: 0.8rem;
  }
`;

export const FPCC_ActionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FPCC_TabContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;

export const FPCC_Tab = styled.div`
  font-size: 1.3rem;
  font-weight: bold;
  padding: 0.5rem 1.5rem;
  border-radius: 1rem 1rem 0 0;
  background: ${(props) => (props.active ? "#b07e5f" : "transparent")};
  color: ${(props) => (props.active ? "#000000" : "#c8baa1")};
  cursor: pointer;
  transition: all 150ms;

  &:hover {
    text-decoration: ${(props) => (props.active ? "none" : "underline")};
  }

  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    font-size: 1rem;
    padding: 0.4rem 1rem;
  }
`;

export const FPCC_FormContent = styled.div`
  background: #b07e5f;
  border-radius: 0 1rem 1rem 1rem;
  padding: 2rem;

  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    padding: 1rem;
  }
`;

export const FPCC_AmountInputContainer = styled.div`
  border: 2px solid #9e6547;
  transform: skewX(-6deg);
  border-radius: 0.25rem;
  background: #ffffff;
  padding: 0.4rem 1rem;
`;

export const FPCC_AmountInput = styled.input`
  outline: none;
  border: none;
  font-size: 1.3rem;
  color: #9e6547;
  width: 100%;

  &::placeholder {
    color: #9e6547;
  }

  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    font-size: 1rem;
  }
`;

export const FPCC_PercentRow = styled.div`
  display: grid;
  grid-template-columns: auto auto auto auto;
  gap: 1rem;
  margin-top: ${(props) => props.mt || "1.2rem"};

  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 0.5rem;
  }
`;

export const FPCC_Percent = styled.div`
  display: flex;
  flex: 1;
  border: 2px solid #5d1a0e;
  border-radius: 0.4rem;
  background: #8a4829;
  color: #ffa300;
  transform: skewX(-6deg);
  padding: 0.5rem 1rem;
  min-width: 4.5rem;
  justify-content: center;
  cursor: pointer;
  transition: 150ms;

  &:hover {
    background: #753d22;
  }

  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    min-width: 2rem;
  }
`;

export const FPCC_ButtonRow = styled(FPCC_PercentRow)`
  grid-template-columns: auto auto;
  gap: 1rem;

  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 0.5rem;
  }
`;

export const FPCC_Button = styled.button`
  border: 2px solid #905132;
  border-radius: 0.4rem;
  background: #ffa300;
  transform: skewX(-6deg);
  padding: 0.5rem 1.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.3rem;
  font-weight: bold;
  color: #4e0904;
  min-width: 10rem;
  height: 3rem;
  cursor: pointer;
  transition: 150ms;
  margin-left: ${(props) => (props.ml ? "1rem" : "0")};
  grid-column: ${(props) => (props.colFull ? "1/5" : "auto")};

  &:hover {
    background: #e09106;
  }

  &:focus {
    box-shadow: 0 0 0 3px #55555580;
  }
`;

export const FPCC_EarnedTitle = styled.div`
  color: #000000;
  font-size: 1.3rem;
  font-weight: bold;
`;

export const FPCC_EarnNumberContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 3rem 0;
`;

export const FPCC_EarnedValue = styled.div`
  color: #4e0904;
  font-size: 2.5rem;
  font-weight: bold;
`;

export const FPCC_EarnedPrice = styled.div`
  color: #8a4829;
`;

export const FPCC_DepositSubActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 2rem;
  visibility: ${(props) => (props.show ? "visible" : "hidden")};
`;

export const FPCC_DepositSubAction = styled.div`
  color: #591811;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    font-size: 0.9rem;
  }
`;

export const FPCC_DepositSubActionDivider = styled.div`
  width: 2px;
  height: 1.5rem;
  background: #591811;
  margin: 0 1.5rem;

  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    margin: 0 1rem;
  }
`;

export const FPCC_AvailableAmount = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
  color: #4e0904;

  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    font-size: 0.8rem;
  }
`;

export const FPCC_StakeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 33rem;
  height: 100%;
`;

export const FPCC_StakeTitle = styled.div`
  margin-top: 2.5rem;
  font-size: 2.5rem;
  font-weight: bold;
  color: #000000;

  @media (max-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XL}) {
    font-size: 2rem;
  }
`;

export const FPCC_StakeDesc = styled.div`
  font-size: 1.5rem;
  color: #4e0904;
  margin-bottom: 3.5rem;

  @media (max-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XL}) {
    font-size: 1.25rem;
  }
`;

export const FPCC_DetailContainer = styled.div`
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* cursor: pointer; */
  margin-top: 1rem;

  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    display: ${(props) => (props.expanded ? "flex" : "none")};
  }
`;

export const FPCC_DetailText = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  color: #ff6d00;
  margin-right: 12px;

  @media (max-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XXL}) {
    font-size: 1.3rem;
  }

  @media (max-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XL}) {
    font-size: 1.1rem;
  }
`;

export const FPCC_DetailIcon = styled.span`
  color: #ff6d00;
  transition: transform 150ms;
`;
