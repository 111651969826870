import React from "react";
import styled from "styled-components";

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin-top: 3px;
  margin-bottom: 3px;
`;

const CheckboxContainerImage = styled.div`
  /* display: inline-block;
  vertical-align: middle;
  align-items: center;
  text-align: center;
  justify-content: center;
  align-items: center; */
  /* height: 70px; */
  /* margin-right: 10px;
   */
  position: absolute;
  top: calc(75px - 18px);
  left: calc(75px - 18px);
`;

const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
`;
// Hide checkbox visually but remain accessible to screen readers.
// Source: https://polished.js.org/docs/#hidevisually
const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const StyledCheckbox = styled.div`
  display: inline-block;
  width: 16px;
  height: 16px;
  background: ${(props) => (props.checked ? "#1c0b09" : "#1c0b09")};
  border-radius: 3px;
  transition: all 150ms;

  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3px #ffecd1;
  }

  ${Icon} {
    visibility: ${(props) => (props.checked ? "visible" : "hidden")};
  }
`;

const SiteCheckbox = ({ className, checked, ...props }) => (
  <CheckboxContainer className={className}>
    <HiddenCheckbox checked={checked} {...props} />
    <StyledCheckbox checked={checked}>
      <Icon viewBox="0 0 24 24">
        <polyline points="20 6 9 17 4 12" />
      </Icon>
    </StyledCheckbox>
  </CheckboxContainer>
);

const SiteCheckboxImage = ({ className, checked, ...props }) => (
  <CheckboxContainerImage className={className}>
    <HiddenCheckbox checked={checked} {...props} />
    <StyledCheckbox checked={checked}>
      <Icon viewBox="0 0 24 24">
        <polyline points="20 6 9 17 4 12" />
      </Icon>
    </StyledCheckbox>
  </CheckboxContainerImage>
);

const SiteCheckboxColor = styled(SiteCheckbox)`
  /* position: absolute !important; */
  /* bottom: 2px !important;
  right: 2px !important; */
  padding-bottom: 0px !important;
  margin-bottom: -10px !important;
  margin-top: 20px !important;
  margin-left: 25px !important;
`;

const SiteCheckboxSpan = styled.span`
  color: #ffecd1;
  padding-left: 5px;
`;

const SiteCheckboxSpanImage = styled.div`
  color: #663304;
  font-size: 0.8rem;
  margin-left: 8px;
  align-self: center;
  position: absolute;
  top: -45px;
  left: 100%;
  font-weight: bold;
  /* margin-top: -10px; */
`;

const SiteCheckboxColorContainer = styled.div`
  position: relative;
  border: 1px solid #1c0b09;
  border-radius: 3px;
  background: ${(props) => props.color};
  width: 40px;
  margin-top: 10px !important;
  cursor: pointer;
`;

const SiteCheckboxImageImg = styled.img`
  width: 75px;
  height: 75px;
`;

const SiteContainerCheckboxImage = styled.div`
  position: relative;
  cursor: pointer;
`;

export default {
  SiteCheckbox: SiteCheckbox,
  SiteCheckboxSpan: SiteCheckboxSpan,
  SiteCheckboxSpanImage: SiteCheckboxSpanImage,
  SiteCheckboxColorContainer: SiteCheckboxColorContainer,
  SiteCheckboxColor: SiteCheckboxColor,
  SiteCheckboxImage: SiteCheckboxImage,
  SiteCheckboxImageImg: SiteCheckboxImageImg,
  SiteContainerCheckboxImage: SiteContainerCheckboxImage,
};
