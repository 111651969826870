import styled from "styled-components";
import ButtonBar from "../../../images/4-Marketplace/Button-Bar.png";

import {
  Slider,
  withStyles
} from "@material-ui/core";
import CommonConstants from "../../../constants/CommonConstants";

const CustomizedSlider = withStyles({
  root: {
    width: "90%",
    // textAlign: "center",
  },
  colorPrimary: {
    color: "#FFFFFF00",
  },
  rail: {
    height: "7px",
    background: "#1c0b09",
    opacity: 1,
    border: "1px solid #ffecd1",
  },
  track: {
    height: "7px",
    background: "#1c0b09",
    opacity: 1,
    border: "1px solid #ffecd1",
  },
  thumb: {
    background: `url(${ButtonBar})`,
    backgroundSize: "17px auto",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    width: "17px",
    height: "20px",
    borderRadius: "0px",
  },
  valueLabel: {
    top: "22px",
    marginLeft: "3px"
  },

  mark: {
    display: "none",
  },
  markLabel: {

    display: "none",
  },
  markLabelActive: {

    display: "none",
  },
})(Slider);

const SiteSlider = styled(CustomizedSlider)
`
  border-radius: 0.5rem !important;
`;

export default {
  SiteSlider: SiteSlider,
};