import React from "react";
import style from "../style/style";
import CursorRoadmap from "../../../images/1-Home/Cursor-Roadmap.png";

const StyleSiteStepperContentMobile = style.SiteStepperContentMobile;
const StyleSiteStepperContentMobileLeftRight =
  style.SiteStepperContentMobileLeftRight;
const StyleStepperContentMobileItem = style.SiteStepperContentMobileItem;
const StyleSiteStepperContentSubItemImageMobileLeft =
  style.SiteStepperContentSubItemImageMobileLeft;
const StyleSiteStepperContentSubItemImageMobileRight =
  style.SiteStepperContentSubItemImageMobileRight;

function SiteStepperContentMobile(props) {
  const { list, step } = props;

  React.useEffect(() => {});

  const _renderLeftItem = (list) => {
    let newList = list.filter((item, index) => {
      return (index + 1) % 2 != 0;
    });
    return newList.map((item, index) => {
      return (
        <StyleStepperContentMobileItem
          key={index}
          style={{ marginRight: "25px", marginLeft: "10px" }}
        >
          <StyleSiteStepperContentSubItemImageMobileLeft
            src={CursorRoadmap}
            style={{ top: 120 }}
          ></StyleSiteStepperContentSubItemImageMobileLeft>
          {item}
        </StyleStepperContentMobileItem>
      );
    });
  };

  const _renderRightItem = (list) => {
    let newList = list.filter((item, index) => {
      return (index + 1) % 2 == 0;
    });
    return newList.map((item, index) => {
      return (
        <StyleStepperContentMobileItem
          key={index}
          style={{ marginLeft: "25px", marginRight: "10px" }}
        >
          <StyleSiteStepperContentSubItemImageMobileRight
            src={CursorRoadmap}
            style={{ top: 120 }}
          ></StyleSiteStepperContentSubItemImageMobileRight>
          {item}
        </StyleStepperContentMobileItem>
      );
    });
  };

  return (
    <StyleSiteStepperContentMobile>
      <StyleSiteStepperContentMobileLeftRight>
        {_renderLeftItem(list)}
      </StyleSiteStepperContentMobileLeftRight>
      <StyleSiteStepperContentMobileLeftRight>
        {_renderRightItem(list)}
      </StyleSiteStepperContentMobileLeftRight>
    </StyleSiteStepperContentMobile>
  );
}

export default SiteStepperContentMobile;
