import React, { useState, useEffect } from "react";
import {
  MarketplaceDialogBuyButton,
  MarketplaceDialogBuyContent,
  MarketplaceDialogBuyDiscount,
  MarketplaceDialogBuyIcon,
  MarketplaceDialogBuyPrice,
  MarketplaceDialogBuyPriceContainer,
  MarketplaceDialogContent,
  MarketplaceDialogDetailContainer,
  MarketplaceDialogDetailOwner,
  MarketplaceDialogDetailTitle,
  MarketplaceDialogDicerImage,
  MarketplaceDialogDicerImageContainer,
  MarketplaceDialogSectionOne,
  MarketplaceDialogSectionTwo,
} from "../MarketplaceDialog.component";
import CharacterBearImageResource from "../../../../../../../resources/images/1-Home/Character-Bear.png";
import BuyIconImageResource from "../../../../../../../resources/images/4-Marketplace/busd.png";
import MarketplaceDialogGene from "./MarketplaceDialogGene";
import MarketplaceDialogAbsorb from "./MarketplaceDialogAbsorb";
import MarketplaceDialogCloning from "./MarketplaceDialogCloning";
import MarketplaceDialogDicerDetailMockup from "../../../mockup/marketplaceDialogDicerDetail.mockup.json";
import MarketplaceDialogAboutDicer from "./MarketplaceDialogAboutDicer";
import DicerDetail from "../../../../../../../resources/model/Marketplace/DicerDetail";
import MarketplaceDialogInfo from "./MarketplaceDialogInfo";
import MarketplaceDialogPart from "./MarketplaceDialogPart";
import MarketplaceDialogSkill from "./MarketplaceDialogSkill";
import MarketplaceDialogSaleHistory from "./MarketplaceDialogSaleHistory";

function MarketplaceDialogDicerDetail({ dicerId }) {
  /**
   * @type [DicerDetail, Function]
   */
  const [dicerDetails, setDicerDetails] = useState(null);

  useEffect(() => {
    // fetch data
    fetchDicerDetails(dicerId);
  }, []);

  const fetchDicerDetails = async (dicerId) => {
    //TODO : fetch data from api
    setDicerDetails(MarketplaceDialogDicerDetailMockup);
  };

  return (
    <MarketplaceDialogContent>
      <MarketplaceDialogSectionOne>
        <MarketplaceDialogDicerImageContainer>
          <MarketplaceDialogDicerImage src={CharacterBearImageResource} />
        </MarketplaceDialogDicerImageContainer>
        <MarketplaceDialogBuyContent>
          <MarketplaceDialogBuyIcon src={BuyIconImageResource} />
          <MarketplaceDialogBuyPriceContainer>
            <MarketplaceDialogBuyPrice>{dicerDetails?.price}</MarketplaceDialogBuyPrice>
            <MarketplaceDialogBuyDiscount>-${dicerDetails?.discount}</MarketplaceDialogBuyDiscount>
          </MarketplaceDialogBuyPriceContainer>
          <MarketplaceDialogBuyButton>Buy</MarketplaceDialogBuyButton>
        </MarketplaceDialogBuyContent>
        <MarketplaceDialogGene items={dicerDetails?.genes} />
        <MarketplaceDialogAbsorb
          dicerId={dicerId}
          dicerImage={CharacterBearImageResource}
          items={dicerDetails?.absorbs}
        />
        <MarketplaceDialogCloning
          dicerId={dicerId}
          dicerImage={CharacterBearImageResource}
          items={dicerDetails?.clones}
        />
      </MarketplaceDialogSectionOne>
      <MarketplaceDialogSectionTwo>
        <MarketplaceDialogDetailContainer>
          <MarketplaceDialogDetailTitle>Dicer #{dicerId}</MarketplaceDialogDetailTitle>
          <MarketplaceDialogDetailOwner>
            Owner: {dicerDetails?.ownerId}
          </MarketplaceDialogDetailOwner>
        </MarketplaceDialogDetailContainer>
        <MarketplaceDialogAboutDicer about={dicerDetails?.about} />
        <MarketplaceDialogPart parts={dicerDetails?.parts} />
        <MarketplaceDialogInfo info={dicerDetails?.info} />
        <MarketplaceDialogSkill skills={dicerDetails?.skills} />
        <MarketplaceDialogSaleHistory saleHistories={dicerDetails?.saleHistories} />
      </MarketplaceDialogSectionTwo>
    </MarketplaceDialogContent>
  );
}

export default MarketplaceDialogDicerDetail;
