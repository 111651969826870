import styled from "styled-components";
import CommonConstants from "../../../constants/CommonConstants";

const SiteProcessBarMobile = styled.div `
  width: 100%;
  display: none;
  justify-content: center;

  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    display: flex;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_TABLET_MIN}) and (max-width: ${CommonConstants.MIN_WIDTH_TABLET_MAX}) {
    display: flex;
  }

  position: absolute;
  height: 74%;
  padding-top: 80px;
  /* z-index: 99; */
  left: 1.5px;
`;

const SiteProcessBarMobileBlankContentLeft = styled.div `
  /* height: 400px; */
  width: 17px;
  border-radius: 5px 0px 0px 5px;
  background: rgba(65, 37, 14, 0.788);
  border: 1px solid rgba(153, 153, 153, 0.623);
  border-right: 0px;
  position: relative;
`;

const SiteProcessBarMobileBlankContentRight = styled.div `
  /* height: 400px; */
  width: 17px;
  border-radius: 0px 5px 5px 0px;
  background: rgb(131, 116, 104);
  border: 1px solid rgba(153, 153, 153, 0.623);
  border-left: 0px;
  position: relative;
`;

const SiteProcessBarMobileContentLeft = styled.div `
  position: absolute;
  height: 0px;
  width: 20px;
  border-radius: 5px 5px 0px 5px;
  background: rgb(0, 102, 251);
  background: linear-gradient(
    180deg,
    rgba(0, 102, 251, 1) 0%,
    rgba(0, 255, 235, 1) 80%
  );
`;

const SiteProcessBarMobileContentRight = styled.div `
  position: absolute;
  height: 0px;
  width: 17px;
  border-radius: 0px 5px 5px 0px;
  background: rgb(113, 148, 199);
  background: linear-gradient(
    180deg,
    rgba(113, 148, 199, 1) 0%,
    rgba(0, 255, 235, 1) 80%
  );
`;

export default {
  SiteProcessBarMobile: SiteProcessBarMobile,
  SiteProcessBarMobileBlankContentLeft: SiteProcessBarMobileBlankContentLeft,
  SiteProcessBarMobileBlankContentRight: SiteProcessBarMobileBlankContentRight,
  SiteProcessBarMobileContentLeft: SiteProcessBarMobileContentLeft,
  SiteProcessBarMobileContentRight: SiteProcessBarMobileContentRight,
};