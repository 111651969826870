import styled from "styled-components";
import BarSideImageResource from "../../../images/2-Farm/Bar-Side.png";
import CommonConstants from "../../../constants/CommonConstants";

export const LeftMenu = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 290px;
  width: 290px;
  overflow: hidden;
  background-image: url(${BarSideImageResource});
  background-repeat: repeat-y;
  height: 100%;
  z-index: 2;
  padding-top: 8rem;

  @media (max-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XL}) {
    display: none;
  }
`;
