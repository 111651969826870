import React from "react";
import { MarketplaceSectionPage } from "./MarketplaceSection.component";

function MarketplaceSection(props) {
  const { children } = props;
  return (
    <MarketplaceSectionPage id="dk-website-marketplace-page-dicer-section">
      {children}
    </MarketplaceSectionPage>
  );
}

export default MarketplaceSection;
