import React from "react";
import style from "./style/style";

const StyleThumbnail = style.Thumbnail;
const StyleThumbnailImage = style.ThumbnailImage;
const StyleThumbnailText = style.ThumbnailText;
const StyleThumbnailTextTitle = style.ThumbnailTextTitle;
const StyleThumbnailTextDetail = style.ThumbnailTextDetail;

function Thumbnail(props) {
  const { title, detail, image, ...rest } = props;

  return (
    <StyleThumbnail {...rest}>
      <StyleThumbnailImage src={image}></StyleThumbnailImage>
      <StyleThumbnailText>
        <StyleThumbnailTextTitle>{title}</StyleThumbnailTextTitle>
        <StyleThumbnailTextDetail>{detail}</StyleThumbnailTextDetail>
      </StyleThumbnailText>
    </StyleThumbnail>
  );
}

export default Thumbnail;
