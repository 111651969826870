import React from "react";
import styled from "styled-components";
import { CapsuleDetail_About } from "../../../../../../../resources/model/Marketplace/CapsuleDetail";

/**
 *
 * @param {Props} props
 * @returns {React.FC}
 */
function MarketplaceDialogAboutCapsule(props) {
  const { about } = props;
  return (
    <Container>
      <Title>About</Title>
      <Content>
        <CurrentStageTitle>Current Stage</CurrentStageTitle>
        <CapsuleContainer>
          <CapsulePercentage percent={+about?.currentStage} />
        </CapsuleContainer>
        <CapsuleDetailRow>
          <CapsuleDetailLeftMessage>Capsule</CapsuleDetailLeftMessage>
          <CapsuleDetailRightMessage>Adult</CapsuleDetailRightMessage>
        </CapsuleDetailRow>
      </Content>
    </Container>
  );
}

export default MarketplaceDialogAboutCapsule;

const Container = styled.div`
  margin-top: 1rem;
`;

const Title = styled.div`
  font-weight: bold;
`;

const Content = styled.div`
  background: #af8068;
  border-radius: 0.6rem;
  padding: 2rem;
`;

const CurrentStageTitle = styled.div`
  color: #4e0904;
`;

const CapsuleContainer = styled.div`
  margin-top: 1rem;
  border: 2px solid #ebcbb0;
  width: 100%;
  height: 1rem;
  background: #1c0c0a;
`;

const CapsulePercentage = styled.div`
  background: #ffa300;
  height: 100%;
  width: ${(props) => props.percent + "%" || "0"};
`;

const CapsuleDetailRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 0.5rem;
  font-size: 0.8rem;
`;

const CapsuleDetailLeftMessage = styled.div`
  color: #ffffff;
`;

const CapsuleDetailRightMessage = styled.div`
  color: #f3e5d8;
`;

class Props {
  constructor() {
    /**
     * @type {CapsuleDetail_About}
     */
    this.about = {};
  }
}
