import React from "react";
import styled from "styled-components";
import { DicerDetail_About } from "../../../../../../../resources/model/Marketplace/DicerDetail";
import SkillImageResource from "../../../../../../../resources/images/4-Marketplace/Pic-Skill2.png";
import CommonConstants from "../../../../../../../resources/constants/CommonConstants";

/**
 *
 * @param {Props} props
 * @returns {React.FC}
 */
function MarketplaceDialogAboutDicer(props) {
  const { about } = props;

  return (
    <Container>
      <Title>About</Title>
      <Content>
        <Row col2Long>
          <FieldName>Race:</FieldName>
          <FieldValue>
            {about?.race} <RaceColor color={about?.color} />
          </FieldValue>
          <FieldName>Clone:</FieldName>
          <FieldValue>{about?.clone}</FieldValue>
          <FieldName>Cards:</FieldName>
          <FieldValue>{about?.skill}</FieldValue>
        </Row>
        <Divider />
        <Row col1Long mb>
          <FieldName>Active Skill:</FieldName>
          <FieldValue>
            <SkillImage src={SkillImageResource} />
          </FieldValue>
          <AbilityText>Ability:</AbilityText>
        </Row>
        <Row mb>
          <FieldName>Wealth:</FieldName>
          <FieldValue>{about?.status?.wealth}</FieldValue>
          <FieldName>Devotion:</FieldName>
          <FieldValue>{about?.status?.devotion}</FieldValue>
          <FieldName>Charm:</FieldName>
          <FieldValue>{about?.status?.charm}</FieldValue>
        </Row>
        <Row>
          <FieldName>Manage:</FieldName>
          <FieldValue>{about?.status?.manage}</FieldValue>
          <FieldName>Luck:</FieldName>
          <FieldValue>{about?.status?.luck}</FieldValue>
          <FieldName>Skill:</FieldName>
          <FieldValue>{about?.status?.skill}</FieldValue>
        </Row>
      </Content>
    </Container>
  );
}

export default MarketplaceDialogAboutDicer;

const Container = styled.div`
  margin-top: 1rem;
`;

const Title = styled.div`
  font-weight: bold;
`;

const Content = styled.div`
  background: #af8068;
  border-radius: 0.6rem;
  padding: 1.5rem 2rem;
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-bottom: ${(props) => (props.mb ? "1rem" : "0")};

  @media (max-width: ${CommonConstants.MIN_WIDTH_DESKTOP}) {
    grid-template-columns: 1fr 1fr;
  }
`;

const FieldName = styled.div`
  color: #4e0904;
`;

const FieldValue = styled.div`
  display: inline-flex;
  color: #ffffff;
  font-weight: bold;
  margin-left: 0.5rem;
`;

const RaceColor = styled.div`
  width: 1.2rem;
  height: 1.2rem;
  border: 1.5px solid black;
  border-radius: 0.3rem;
  background: ${(props) => props.color || "#ffffff"};
  margin-left: 0.5rem;
`;

const Divider = styled.div`
  height: 1px;
  width: 100%;
  margin: 1rem 0;
  background: #4e0904;
`;

const SkillImage = styled.img`
  width: 2rem;
  height: 2rem;
  object-fit: contain;
`;

const AbilityText = styled.div`
  color: #4e0904;
  font-size: 0.8rem;
  min-height: 3rem;
`;

class Props {
  constructor() {
    /**
     * @type {DicerDetail_About}
     */
    this.about = {};
  }
}
