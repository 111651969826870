import React from "react";
import MenuRoadmap from "../../../../resources/images/1-Home/Menu-Roadmap.png";
import SiteContainer from "../../../../resources/components/SiteContainer/SiteContainer";
import SiteStepper from "../../../../resources/components/SiteStepper/SiteStepper";
import RoadmapConstants from "../../../../resources/constants/RoadmapConstants";
import Roadmap from "../../../../resources/model/Home/Roadmap";
import {
  ConHomeRoadMap,
  ImgHomeRoadMap,
  HomeRoadMapStepContent,
  HomeRoadMapStepContentActive,
  HomeRoadMapStepContentTitle,
  HomeRoadMapStepContentTopic,
} from "./HomeRoadmap.component";

function HomeRoadMap(props) {
  /**
   * @type {[Roadmap[], Number]}
   */
  let { listStep, currentStep } = props;

  const _renderStepContent = () => {
    return listStep.map((item, index) => {
      let id = "home-road-map-step-" + index;

      if (item.active) {
        return (
          <HomeRoadMapStepContentActive id={id} key={index}>
            <HomeRoadMapStepContentTitle>
              {item.title}
            </HomeRoadMapStepContentTitle>
            {_renderStepTopic(item.topic)}
          </HomeRoadMapStepContentActive>
        );
      } else {
        return (
          <HomeRoadMapStepContent id={id} key={index}>
            <HomeRoadMapStepContentTitle>
              {item.title}
            </HomeRoadMapStepContentTitle>
            {_renderStepTopic(item.topic)}
          </HomeRoadMapStepContent>
        );
      }
    });
  };

  const _renderStepTopic = (list) => {
    return list.map((item, index) => {
      return (
        <HomeRoadMapStepContentTopic key={index}>
          {item}
        </HomeRoadMapStepContentTopic>
      );
    });
  };

  return (
    <ConHomeRoadMap id="dk-website-home-road-map">
      <ImgHomeRoadMap src={MenuRoadmap}></ImgHomeRoadMap>
      <SiteContainer>
        <SiteStepper
          title={RoadmapConstants.title}
          step={currentStep}
          stepList={_renderStepContent()}
          idBar="home-road-map-bar"
        ></SiteStepper>
      </SiteContainer>
    </ConHomeRoadMap>
  );
}

export default HomeRoadMap;
