import React, { useState } from "react";
import styled from "styled-components";
import CommonConstants from "../../resources/constants/CommonConstants";
import DemoPlayConstants from "../../resources/constants/DemoPlayConstants";
import DemoPlayGame from "./components/DemoPlayGame";
import DemoPlayLeftMenu from "./components/DemoPlayLeftMenu";
import DemoPlayLibrary from "./components/DemoPlayLibrary";
import DemoPlayMiniMenu from "./components/DemoPlayMiniMenu";
import DemoPlayNews from "./components/DemoPlayNews";
import DemoPlayVideo from "./components/DemoPlayVideo";

function DemoPlay() {
  const [activeMenu, setActiveMenu] = useState(DemoPlayConstants.MENU.GAME);

  const renderContent = () => {
    if (activeMenu === DemoPlayConstants.MENU.GAME) {
      return <DemoPlayGame />;
    } else if (activeMenu === DemoPlayConstants.MENU.NEWS) {
      return <DemoPlayNews />;
    } else if (activeMenu === DemoPlayConstants.MENU.VIDEO) {
      return <DemoPlayVideo />;
    } else if (activeMenu === DemoPlayConstants.MENU.LIBRARY) {
      return <DemoPlayLibrary />;
    } else {
      return null;
    }
  };

  return (
    <Container>
      <DemoPlayLeftMenu activeMenu={activeMenu} onMenuChange={setActiveMenu} />
      <Section>
        <Content>
          <DemoPlayMiniMenu activeMenu={activeMenu} onMenuChange={setActiveMenu} />
          {renderContent()}
        </Content>
      </Section>
    </Container>
  );
}

export default DemoPlay;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  z-index: 2;
`;

const Section = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: 250px;
  padding-top: 7rem;
  overflow-x: hidden;
  min-height: 80vh;

  @media (max-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XXL}) {
    padding-top: 4rem;
    margin-left: 230px;
  }

  @media (max-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XL}) {
    padding-top: 5rem;
    margin-left: 0;
  }

  @media (max-width: ${CommonConstants.MIN_WIDTH_TABLET_MAX}) {
    padding-top: 4rem;
  }
`;

const Content = styled.div`
  padding: 2rem;

  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    padding: 1rem;
  }
`;
