import React from "react";
import style from "./style/style";

const StyleSiteProcessBarMobile = style.SiteProcessBarMobile;
const StyleSiteProcessBarMobileBlankContentLeft =
  style.SiteProcessBarMobileBlankContentLeft;
const StyleSiteProcessBarMobileBlankContentRight =
  style.SiteProcessBarMobileBlankContentRight;
const StyleSiteProcessBarMobileContentLeft =
  style.SiteProcessBarMobileContentLeft;
const StyleSiteProcessBarMobileContentRight =
  style.SiteProcessBarMobileContentRight;

function SiteProcessBarMobile(props) {
  const { percent, step, idBar, ...rest } = props;

  // const [txtHeight, setTxtHeight] = React.useState(0);

  // React.useEffect(() => {
  //   _setPercentInStep();
  // });

  const _setHeightInStep = () => {
    let sum = 0;

    switch (step) {
      case 0:
        sum = 0;
        break;

      case 1:
        sum = 60;
        break;

      case 2:
        sum = 160;
        break;

      case 3:
        sum = 200;
        break;

      case 4:
        sum = 300;
        break;

      case 5:
        sum = 340;
        break;

      case 6:
        sum = 445;
        break;

      case 7:
        sum = 480;
        break;

      case 8:
        sum = 583;
        break;

      case 9:
        sum = 620;
        break;

      case 10:
        sum = "100%";
        break;

      default:
        sum = 0;
        break;
    }

    return sum;
  };

  return (
    <StyleSiteProcessBarMobile>
      <StyleSiteProcessBarMobileBlankContentLeft>
        <StyleSiteProcessBarMobileContentLeft
          style={{ height: _setHeightInStep() }}
        />
      </StyleSiteProcessBarMobileBlankContentLeft>
      <StyleSiteProcessBarMobileBlankContentRight>
        <StyleSiteProcessBarMobileContentRight
          style={{ height: _setHeightInStep() }}
        />
      </StyleSiteProcessBarMobileBlankContentRight>
    </StyleSiteProcessBarMobile>
  );
}

export default SiteProcessBarMobile;
