import React, { forwardRef, useImperativeHandle, useState } from "react";
import FarmConstants from "../../../../resources/constants/FarmConstants";
import FarmItem from "../../../../resources/model/Farm/FarmItem";
import NumberFormat from "../../../../utils/NumberFormat";
import FarmPoolDialog from "../FarmPoolDialog/FarmPoolDialog";
import {
  FPCC_ActionContainer,
  FPCC_AmountInput,
  FPCC_AmountInputContainer,
  FPCC_AvailableAmount,
  FPCC_Button,
  FPCC_Content,
  FPCC_DepositSubAction,
  FPCC_DepositSubActionContainer,
  FPCC_DepositSubActionDivider,
  FPCC_EarnedPrice,
  FPCC_EarnedTitle,
  FPCC_EarnedValue,
  FPCC_EarnNumberContainer,
  FPCC_FormContent,
  FPCC_PercentRow,
  FPCC_Main,
  FPCC_Percent,
  FPCC_StakeContainer,
  FPCC_StakeDesc,
  FPCC_StakeTitle,
  FPCC_Tab,
  FPCC_TabContainer,
  FPCC_ButtonRow,
  FPCC_DetailContainer,
  FPCC_DetailText,
  FPCC_DetailIcon,
} from "./FarmPoolContent.component";

/**
 *
 * @param {Props} props
 * @returns {React.FC}
 */
function FarmPoolContent(props, ref) {
  const { data, expanded, deposited } = props;
  const [activeTab, setActiveTab] = useState(0);
  const [dialogVisible, setDialogVisible] = useState(false);
  const [stakeAction, setStakeAction] = useState(null);
  const [amount, setAmount] = useState("");

  useImperativeHandle(
    ref,
    () => ({
      isDialogOpen: () => dialogVisible,
    }),
    [dialogVisible]
  );

  const handleTabChnage = (targetTab) => {
    setActiveTab(targetTab);
    setAmount("");
  };

  const renderMainActionButton = () => {
    if (activeTab === 0) {
      return (
        <FPCC_ButtonRow>
          <FPCC_Button colFull onClick={handleApproveClick}>
            Approve
          </FPCC_Button>
        </FPCC_ButtonRow>
      );
    } else {
      return (
        <FPCC_ButtonRow>
          <FPCC_Button onClick={handleSettleAndExitClick}>Settle & Exit</FPCC_Button>
          <FPCC_Button onClick={handleWithdrawClick}>
            {activeTab === 0 ? "Deposit" : "Withdraw"}
          </FPCC_Button>
        </FPCC_ButtonRow>
      );
    }
  };

  const renderContentByMode = () => {
    if (data?.contentMode === FarmConstants.CONTENT_MODE.WALLET) {
      return (
        <React.Fragment>
          <FPCC_ActionContainer>
            <FPCC_TabContainer>
              <FPCC_Tab active={activeTab === 0} onClick={() => handleTabChnage(0)}>
                Deposit
              </FPCC_Tab>
              <FPCC_Tab active={activeTab === 1} onClick={() => handleTabChnage(1)}>
                Withdraw
              </FPCC_Tab>
              {activeTab === 0 && <FPCC_AvailableAmount>Available 0.00000</FPCC_AvailableAmount>}
            </FPCC_TabContainer>
            <FPCC_FormContent>
              <FPCC_AmountInputContainer>
                <FPCC_AmountInput
                  placeholder="Amount"
                  value={amount}
                  onChange={({ target }) => setAmount(target.value)}
                />
              </FPCC_AmountInputContainer>

              <FPCC_PercentRow>
                <FPCC_Percent onClick={() => handlePercentClick(25)}>25%</FPCC_Percent>
                <FPCC_Percent onClick={() => handlePercentClick(50)}>50%</FPCC_Percent>
                <FPCC_Percent onClick={() => handlePercentClick(75)}>75%</FPCC_Percent>
                <FPCC_Percent onClick={() => handlePercentClick(100)}>100%</FPCC_Percent>
              </FPCC_PercentRow>
              {renderMainActionButton()}
            </FPCC_FormContent>
            <FPCC_DepositSubActionContainer show={activeTab === 0}>
              <FPCC_DepositSubAction onClick={handleAddLiquidityClick}>
                Add liquidity
              </FPCC_DepositSubAction>
              <FPCC_DepositSubActionDivider />
              <FPCC_DepositSubAction onClick={handleRemoveLiquidityClick}>
                Remove liquidity
              </FPCC_DepositSubAction>
            </FPCC_DepositSubActionContainer>
          </FPCC_ActionContainer>
        </React.Fragment>
      );
    } else if (data?.contentMode === FarmConstants.CONTENT_MODE.STAKE) {
      return (
        <FPCC_StakeContainer>
          <FPCC_StakeTitle>Your Dicers: 3</FPCC_StakeTitle>
          <FPCC_StakeDesc>Stake Dicers to earn DK</FPCC_StakeDesc>
          <FPCC_ButtonRow>
            <FPCC_Button onClick={() => handleStakeActionClick(FarmConstants.STAKE_ACTION.DEPOSIT)}>
              Deposit
            </FPCC_Button>
            <FPCC_Button
              onClick={() => handleStakeActionClick(FarmConstants.STAKE_ACTION.WITHDRAW)}
            >
              Withdraw
            </FPCC_Button>
          </FPCC_ButtonRow>
        </FPCC_StakeContainer>
      );
    } else {
      return null;
    }
  };

  const handlePercentClick = (percent) => {
    const value = String((+deposited * percent) / 100);
    setAmount(value);
  };

  const handleStakeActionClick = (targetAction) => {
    // click from content mode stake => button deposit and withdraw
    setStakeAction(targetAction);
    setDialogVisible(true);
  };

  const handleFarmDialogClose = () => {
    setStakeAction(null);
    setDialogVisible(false);
  };

  const handleFarmDialogSubmit = (dicer) => {
    setStakeAction(null);
    setDialogVisible(false);
  };

  const handleApproveClick = () => {
    //TODO : clicked from approve (deposit)
  };

  const handleAddLiquidityClick = () => {
    //TODO : clicked from add liquidity (deposit)
  };

  const handleRemoveLiquidityClick = () => {
    //TODO : clicked from remove liquidity (deposit)
  };

  const handleSettleAndExitClick = () => {
    //TODO : clicked from settle&exit (withdraw)
  };

  const handleWithdrawClick = () => {
    //TODO : clicked from withdraw (withdraw)
  };

  const handleHarvestClick = () => {
    //TODO : clicked from harvest
    if (data?.contentMode === FarmConstants.CONTENT_MODE.WALLET) {
      // wallet = show deposit & withdraw with amount
    } else if (data?.contentMode === FarmConstants.CONTENT_MODE.STAKE) {
      // stake = show dicer and button deposit & withdraw that when click will open dialog
    }
  };

  return (
    <React.Fragment>
      <FPCC_Main id="__farm_pool_block_zone" expanded={expanded}>
        <FPCC_Content>{renderContentByMode()}</FPCC_Content>
        <FPCC_Content>
          <FPCC_EarnedTitle>Earned</FPCC_EarnedTitle>
          <FPCC_EarnNumberContainer>
            <FPCC_EarnedValue>{data?.earnValue}</FPCC_EarnedValue>
            <FPCC_EarnedPrice>~{NumberFormat.toUSD(data?.earnValueAround)}</FPCC_EarnedPrice>
          </FPCC_EarnNumberContainer>
          <FPCC_Button onClick={handleHarvestClick}>Harvest</FPCC_Button>
        </FPCC_Content>
      </FPCC_Main>
      <FPCC_DetailContainer expanded={expanded}>
        <FPCC_DetailText>Details</FPCC_DetailText>
        <FPCC_DetailIcon className="material-icons" expanded={expanded}>
          expand_less
        </FPCC_DetailIcon>
      </FPCC_DetailContainer>
      <FarmPoolDialog
        visible={dialogVisible}
        stakeAction={stakeAction}
        onSubmit={handleFarmDialogSubmit}
        onClose={handleFarmDialogClose}
      />
    </React.Fragment>
  );
}

export default forwardRef(FarmPoolContent);

class Props {
  constructor() {
    /**
     * @type {FarmItem}
     */
    this.data = {};
    this.expanded = false;
    this.deposited = 0;
  }
}
