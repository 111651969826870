import React from "react";
import styled from "styled-components";
import { DicerDetail_Gene } from "../../../../../../../resources/model/Marketplace/DicerDetail";
import MarketplaceDialogAccordion from "./MarketplaceDialogAccordion";

/**
 *
 * @param {Props} props
 * @returns {React.FC}
 */
function MarketplaceDialogGene(props) {
  const { items = [] } = props;
  return (
    <MarketplaceDialogAccordion title="Gene">
      <Table>
        <TableRow>
          <TableCell></TableCell>
          <TableCell center>Stand</TableCell>
          <TableCell center>Distressed</TableCell>
          <TableCell center>Panel</TableCell>
        </TableRow>
        {items.map((item, index) => (
          <TableRow key={"geneTableRow_" + index}>
            <TableCell mb>
              <Part>{item.part}</Part>
            </TableCell>
            <TableCell mb center>
              {item.stand}
            </TableCell>
            <TableCell mb center>
              {item.distressed}
            </TableCell>
            <TableCell mb center>
              {item.panel}
            </TableCell>
          </TableRow>
        ))}
      </Table>
    </MarketplaceDialogAccordion>
  );
}

export default MarketplaceDialogGene;

const Table = styled.div`
  display: table;
  color: #4e0904;
`;

const TableRow = styled.div`
  display: table-row;
`;

const TableCell = styled.div`
  display: table-cell;
  padding: 0 0.25rem;
  padding-bottom: ${(props) => (props.mb ? "0.3rem" : "0")};
  text-align: ${(props) => (props.center ? "center" : "left")};
  font-size: 0.8rem;
`;

const Part = styled.div`
  font-size: 1.2rem;
  font-weight: bold;
  min-width: 5rem;
`;

class Props {
  constructor() {
    /**
     * @type {DicerDetail_Gene[]}
     */
    this.items = [];
  }
}
