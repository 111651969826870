import styled from "styled-components";
import CommonConstants from "../../../../constants/CommonConstants";
import MenubarImage from "../../../../images/1-Home/Menubar.png";
import SiteButtonYellow from "../../../SiteButton/SiteButtonYellow";
import ConHeaderMenu from "../components/HeaderMenu/HeaderMenu";

const Header = styled.div`
  display: flex;
  flex-direction: row;
  background-image: url(${MenubarImage});
  background-position: top;
  background-repeat: no-repeat;
  background-size: 100vw 100%;
  position: absolute;
  z-index: 10;
  width: 100%;
  top: 0;
  margin-top: -2px;

  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    background-color: #261f1e;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_TABLET_MIN}) and (max-width: ${CommonConstants.MIN_WIDTH_TABLET_MAX}) {
    background-color: #261f1e;
  }
`;

const HeaderLogo = styled.div`
  text-align: center;
  padding-top: 10px;
  display: block;

  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    padding: 0px 10px 0px 10px;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_TABLET_MIN}) and (max-width: ${CommonConstants.MIN_WIDTH_TABLET_MAX}) {
    padding: 0px 15px 0px 15px;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP}) and (max-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XL}) {
    padding: 0px 20px 0px 20px;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XL}) {
    padding: 0px 20px 0px 40px;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XXL}) {
    padding: 0px 40px 0px 40px;
  }
`;

const ImageLogo = styled.img`
  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    width: 80px;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_TABLET_MIN}) and (max-width: ${CommonConstants.MIN_WIDTH_TABLET_MAX}) {
    width: 110px;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP}) and (max-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XL}) {
    width: 150px;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XL}) {
    width: 150px;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XXL}) {
    width: 150px;
  }
`;

const HeaderMenu = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    display: none;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_TABLET_MIN}) and (max-width: ${CommonConstants.MIN_WIDTH_TABLET_MAX}) {
    display: none;
  }
`;

const HeaderMenuText = styled.div`
  flex-grow: 1;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  display: flex;
  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    flex-direction: column;
    justify-content: center;
    text-align: center;
    flex-grow: unset;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_TABLET_MIN}) and (max-width: ${CommonConstants.MIN_WIDTH_TABLET_MAX}) {
    flex-direction: column;
    justify-content: center;
    text-align: center;
    flex-grow: unset;
  }
`;

const HeaderMenuTextItem = styled.div`
  cursor: pointer;
  color: #fff;
  font-weight: 900;
  font-size: 25px;
  margin: 0px 20px 0px 20px;

  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    display: block;
    font-size: 1rem;
    margin: 20px 0px 0px 0px;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_TABLET_MIN}) and (max-width: ${CommonConstants.MIN_WIDTH_TABLET_MAX}) {
    display: block;
    font-size: 1rem;
    margin: 20px 0px 0px 0px;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP}) and (max-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XL}) {
    font-size: 20px;
    margin: 0px 15px 0px 15px;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XL}) {
    font-size: 20px;
    margin: 0px 15px 0px 15px;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XXL}) {
    font-size: 20px;
    margin: 0px 20px 0px 20px;
  }
`;

const HeaderMenuTextItemActive = styled(HeaderMenuTextItem)`
  color: ${CommonConstants.COLOR_ACTIVE};
`;

const HeaderMenuButton = styled.div`
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: center;
  align-items: center;
  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_TABLET_MIN}) and (max-width: ${CommonConstants.MIN_WIDTH_TABLET_MAX}) {
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
`;

const HeaderMenuButtonLeft = styled.div`
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: center;
  align-items: center;
  gap: 30px;

  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_TABLET_MIN}) and (max-width: ${CommonConstants.MIN_WIDTH_TABLET_MAX}) {
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP}) and (max-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XL}) {
    gap: 15px;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XL}) {
    gap: 20px;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XXL}) {
    gap: 30px;
  }
`;

const HeaderMenuButtonRight = styled.div`
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: center;
  align-items: center;
`;

const HeaderMenuImage = styled.img`
  cursor: pointer;

  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    width: 100px;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_TABLET_MIN}) and (max-width: ${CommonConstants.MIN_WIDTH_TABLET_MAX}) {
    width: 100px;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP}) and (max-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XL}) {
    width: 150px;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XL}) {
    width: 150px;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XXL}) {
    width: 150px;
  }
`;

const BtnSiteButtonYellow = styled(SiteButtonYellow)`
  padding: 10px 16px !important;
  margin-right: 5px !important;
  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    font-size: 1rem !important;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_TABLET_MIN}) and (max-width: ${CommonConstants.MIN_WIDTH_TABLET_MAX}) {
    font-size: 1rem !important;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP}) and (max-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XL}) {
    font-size: 16px !important;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XL}) {
    font-size: 16px !important;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XXL}) {
    font-size: 16px !important;
  }
`;

const HeaderMenuMobile = styled.div`
  width: 100%;

  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    display: block;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_TABLET_MIN}) and (max-width: ${CommonConstants.MIN_WIDTH_TABLET_MAX}) {
    display: block;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP}) and (max-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XL}) {
    display: none;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XL}) {
    display: none;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XXL}) {
    display: none;
  }
`;

const HeaderMenuHam = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding-right: 1rem;
`;

const HeaderMenuHamImg = styled.img`
  width: 1.5rem;
  padding-right: 10px;
  cursor: pointer;
  display: ${(props) => (props.containerShow ? "block" : "none")};
`;

const HeaderMenuMobileContentContainer = styled.div`
  width: 100vw;
  min-height: ${(props) => props.widthMenu + "vh"};
  overflow: auto;
  position: absolute;
  left: 0px;
  background-color: #261f1e;
  display: ${(props) => (props.containerShow ? "flex" : "none")};
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
`;

const HeaderMenuMobileContent = styled.div`
  width: 100%;
  height: 100%;
  display: ${(props) => (props.showMenuMobileContent ? "flex" : "none")};
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export default {
  Header: Header,

  HeaderLogo: HeaderLogo,
  ImageLogo: ImageLogo,

  HeaderMenu: HeaderMenu,

  HeaderMenuText: HeaderMenuText,
  HeaderMenuTextItem: HeaderMenuTextItem,
  HeaderMenuTextItemActive: HeaderMenuTextItemActive,

  HeaderMenuButton: HeaderMenuButton,
  HeaderMenuButtonLeft: HeaderMenuButtonLeft,
  HeaderMenuButtonRight: HeaderMenuButtonRight,
  HeaderMenuImage: HeaderMenuImage,
  BtnSiteButtonYellow: BtnSiteButtonYellow,

  HeaderMenuMobile: HeaderMenuMobile,
  HeaderMenuHam: HeaderMenuHam,
  HeaderMenuHamImg: HeaderMenuHamImg,
  HeaderMenuMobileContent: HeaderMenuMobileContent,
  HeaderMenuMobileContentContainer: HeaderMenuMobileContentContainer,
};
