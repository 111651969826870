import React from "react";
import style from "./style/style";

const StyleSiteContainer = style.SiteContainer;

function SiteContainer(props) {
  const { children, ...rest } = props;

  return <StyleSiteContainer {...rest}>{children}</StyleSiteContainer>;
}

export default SiteContainer;
