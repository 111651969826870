import React from "react";
import style from "./style/style";

const StyleSiteCheckbox = style.SiteCheckbox;
const StyleSiteCheckboxSpan = style.SiteCheckboxSpan;

function SiteCheckbox(props) {
  const { value, checked, onChange } = props;

  return (
    <label style={{cursor: "pointer"}}>
      <StyleSiteCheckbox checked={checked} onChange={onChange} />
      <StyleSiteCheckboxSpan style={{ marginLeft: 8 }}>
        {value}
      </StyleSiteCheckboxSpan>
    </label>
  );
}

export default SiteCheckbox;
