import React from "react";
import MenuGameFlow from "../../../../resources/images/1-Home/Menu-GameFlow.png";
import DKHand from "../../../../resources/images/1-Home/DK-Hand.png";
import PicGameFlow from "../../../../resources/images/1-Home/Pic-GameFlow.png";
import Raylight from "../../../../resources/images/1-Home/Raylight.png";
import SiteContainer from "../../../../resources/components/SiteContainer/SiteContainer";
import {
  ConHomeGameFlow,
  ImgHomeGameFlow,
  ImgSecondHomeGameFlow,
  ImgRaylightHomeGameFlow,
  HomeGameFlowImageContent,
  ImgGameFlowImageContent,
} from "./HomeGameFlow.component";

function HomeGameFlow() {
  return (
    <SiteContainer>
      <ConHomeGameFlow id="dk-website-home-page-game-flow">
        <ImgHomeGameFlow src={MenuGameFlow}></ImgHomeGameFlow>
        <ImgSecondHomeGameFlow src={DKHand}></ImgSecondHomeGameFlow>
        <ImgRaylightHomeGameFlow
          id="dk-website-home-page-game-flow-img-raylight"
          src={Raylight}
        ></ImgRaylightHomeGameFlow>

        <HomeGameFlowImageContent>
          <ImgGameFlowImageContent src={PicGameFlow}></ImgGameFlowImageContent>
        </HomeGameFlowImageContent>
      </ConHomeGameFlow>
    </SiteContainer>
  );
}

export default HomeGameFlow;
