import styled from "styled-components";
import SiteLeftMenu from "../../../../../../resources/components/SiteLeftMenu/SiteLeftMenu";
import CommonConstants from "../../../../../../resources/constants/CommonConstants";

export const MarketplaceLeftMenuPage = styled.div`
  position: relative;
  margin-top: 1rem;
  width: 90%;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const MarketplaceLeftMenuContainer = styled(SiteLeftMenu)`
  position: relative !important;
  padding: 0px !important;
  padding-left: 10px !important;
  height: auto !important;
  min-width: 280px !important;
  width: 280px !important;

  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    display: ${(props) => (props.show ? "block" : "none")} !important;
    margin-top: 20px !important;
    border-radius: 0px 10px 10px 0px;
    position: absolute !important;
    z-index: 9;
    width: 80% !important;
    max-width: 300px !important;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_TABLET_MIN}) and (max-width: ${CommonConstants.MIN_WIDTH_TABLET_MAX}) {
    display: ${(props) => (props.show ? "block" : "none")} !important;
    margin-top: 20px !important;
    border-radius: 0px 10px 10px 0px;
    position: absolute !important;
    z-index: 9;
    width: 80% !important;
    max-width: 300px !important;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP}) and (max-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XL}) {
    display: block !important;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XL}) {
    display: block !important;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XXL}) {
    display: block !important;
  }
`;
