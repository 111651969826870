import styled from "styled-components";
import React from "react";
import SiteButtonOutlineDoubleRadius from "./SiteButtonOutlineDoubleRadius";
import SiteButtonBrownDoubleRadius from "./SiteButtonBrownDoubleRadius";
import style from "./style/style";
import CommonConstants from "../../../resources/constants/CommonConstants";

function SiteButtonGroupRadioOutline(props) {
  const {
    list,
    active,
    setActiveOnClick,
    fontSize = "0.8rem",
    fontWeight = "bold",
    ...rest
  } = props;

  const StyleSiteButtonGroupRadio = style.SiteButtonGroupRadio;

  const BtnSiteButtonBrownDark = styled(SiteButtonOutlineDoubleRadius)`
    font-size: ${(styleProps) => styleProps.textSize} !important;
    font-weight: ${(styleProps) => styleProps.textWeight} !important;
    margin-top: -6px !important;
    /* margin-left: 5px !important;
    margin-right: 5px !important; */
    @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
      font-size: 0.8rem !important;
    }

    @media (min-width: ${CommonConstants.MIN_WIDTH_TABLET_MIN}) and (max-width: ${CommonConstants.MIN_WIDTH_TABLET_MAX}) {
      font-size: 0.8rem !important;
    }
  `;
  const BtnSiteButtonBrownLight = styled(SiteButtonBrownDoubleRadius)`
    font-size: ${(styleProps) => styleProps.textSize} !important;
    font-weight: ${(styleProps) => styleProps.textWeight} !important;
    margin-top: -6px !important;
    @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
      font-size: 0.8rem !important;
    }

    @media (min-width: ${CommonConstants.MIN_WIDTH_TABLET_MIN}) and (max-width: ${CommonConstants.MIN_WIDTH_TABLET_MAX}) {
      font-size: 0.8rem !important;
    }
    /* margin-left: 5px !important;
    margin-right: 5px !important; */
  `;

  const _renderButtonGroup = () => {
    return list.map((item, index) => {
      if (active == index) {
        return (
          <BtnSiteButtonBrownLight
            onClick={() => setActiveOnClick(index)}
            key={index}
            textSize={fontSize}
            textWeight={fontWeight}
          >
            {item}
          </BtnSiteButtonBrownLight>
        );
      } else {
        return (
          <BtnSiteButtonBrownDark
            onClick={() => setActiveOnClick(index)}
            key={index}
            textSize={fontSize}
            textWeight={fontWeight}
          >
            {item}
          </BtnSiteButtonBrownDark>
        );
      }
    });
  };

  return (
    <StyleSiteButtonGroupRadio {...rest}>
      {_renderButtonGroup()}
    </StyleSiteButtonGroupRadio>
  );
}

export default SiteButtonGroupRadioOutline;
