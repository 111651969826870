
import React from "react";
import style from "./style/style";

const StyleSiteButtonBrownDoubleRadius = style.SiteButtonBrownDoubleRadius;

function SiteButtonBrownDoubleRadius(props) {
  const { children, ...rest } = props;

  return (
    <StyleSiteButtonBrownDoubleRadius
      active
      variant="contained"
      color="primary"
      disableRipple
      {...rest}
    >
      {children}
    </StyleSiteButtonBrownDoubleRadius>
  );
}

export default SiteButtonBrownDoubleRadius;
