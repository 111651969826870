import React from "react";
import styled from "styled-components";
import CommonConstants from "../../../resources/constants/CommonConstants";
import BarSideImageResource from "../../../resources/images/5-DemoPlay/Bar-Side.png";
import DemoPlayMenuMaster from "../master/DemoPlayMenuMaster";

/**
 *
 * @param {Props} props
 * @returns {React.FC}
 */
function DemoPlayLeftMenu(props) {
  const { activeMenu, onMenuChange } = props;

  const handleMenuChange = (menuName) => {
    onMenuChange && onMenuChange(menuName);
  };

  return (
    <Container>
      {Object.keys(DemoPlayMenuMaster).map((key, index) => {
        const item = DemoPlayMenuMaster[key];
        return (
          <MenuBox
            key={"menubox_" + index}
            active={activeMenu === key}
            onClick={() => handleMenuChange(key)}
          >
            <MenuImage src={item.ICON} />
            <MenuText>{item.NAME}</MenuText>
          </MenuBox>
        );
      })}
    </Container>
  );
}

export default DemoPlayLeftMenu;

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background: url(${BarSideImageResource}) repeat-y;
  padding-top: 8rem;

  @media (max-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XXL}) {
    padding-top: 5.4rem;
  }

  @media (max-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XL}) {
    display: none;
  }
`;

const MenuBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 6rem;
  height: 6rem;
  padding: 2rem;
  border-bottom: 2px solid #8f6939;
  background: ${(props) => (props.active ? "#a37132" : "#684935bb")};
  cursor: pointer;

  &::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 0.5rem;
    top: 0;
    left: 0;
    background: #fea200;
    display: ${(props) => (props.active ? "block" : "none")};
  }
`;

const MenuImage = styled.img`
  width: 6rem;
  height: 6rem;
  object-fit: contain;
`;

const MenuText = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  color: #ffffff;
`;

class Props {
  constructor() {
    this.activeMenu = "";
    this.onMenuChange = () => {};
  }
}
