import React from "react";
import style from "./style/style";

const StyleSiteButtonBrownLight = style.SiteButtonBrownLight;

function SiteButtonBrownLight(props) {
  const { children, ...rest } = props;

  return (
    <StyleSiteButtonBrownLight
      active
      variant="contained"
      color="primary"
      disableRipple
      {...rest}
    >
      {children}
    </StyleSiteButtonBrownLight>
  );
}

export default SiteButtonBrownLight;
