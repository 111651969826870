class DicerSearchResult {
    constructor() {
        this.id = "";
        this.clone = 0;
        this.skill = 0;
        this.urlImage = "";

        /**
         * @type {String[]}
         * */
        this.parts = [];

        this.price = 0.0;
        this.priceUS = 0.0;
        this.wealth = 0;
        this.manage = 0;
        this.devotion = 0;
        this.luck = 0;
        this.charm = 0;

        /**
         * @type {String}
         * @example dicer, capsule
         */
        this.type = "";

        /**
         * @type {String}
         * @ sale, sold
         */
        this.tag = "";
    }


}

export default DicerSearchResult;