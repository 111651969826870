import styled from "styled-components";
import CommonConstants from "../../../constants/CommonConstants";

const SiteTextH1 = styled.div `
  font-weight: normal;
  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    /* font-size: 25px; */font-size: 1.5rem;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_TABLET_MIN}) and (max-width: ${CommonConstants.MIN_WIDTH_TABLET_MAX}) {
    /* font-size: 25px; */font-size: 1.5rem;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP}) and (max-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XL}) {
    font-size: 1.5rem;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XL}) {
    font-size: 1.5rem;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XXL}) {
    font-size: 1.5rem;
  }
`;

const SiteTextH3 = styled.div `
  font-weight: normal;
  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    /* font-size: 18px; */font-size: 1.0rem;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_TABLET_MIN}) and (max-width: ${CommonConstants.MIN_WIDTH_TABLET_MAX}) {
    /* font-size: 18px; */font-size: 1.0rem;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP}) and (max-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XL}) {
    font-size: 1.0rem;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XL}) {
    font-size: 1.0rem;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XXL}) {
    font-size: 1.0rem;
  }
`;

export default {
  SiteTextH1: SiteTextH1,
  SiteTextH3: SiteTextH3,
};