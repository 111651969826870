import styled from "styled-components";
import CommonConstants from "../../../constants/CommonConstants";

const SitePagination = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const SitePaginationCursorLeft = styled.img`
  max-width: 25px;
  transform: rotate(90deg);
  cursor: pointer;
  @media (min-width: ${CommonConstants.MIN_WIDTH_TABLET_MIN}) and (max-width: ${CommonConstants.MIN_WIDTH_TABLET_MAX}) {
    max-width: 20px;
  }
`;

const SitePaginationCursorRight = styled.img`
  max-width: 25px;
  transform: rotate(-90deg);
  cursor: pointer;
  @media (min-width: ${CommonConstants.MIN_WIDTH_TABLET_MIN}) and (max-width: ${CommonConstants.MIN_WIDTH_TABLET_MAX}) {
    max-width: 20px;
  }
`;

const SitePaginationInput = styled.input`
  width: 30px;
  font-size: 1rem;
  font-weight: bold;
  color: #ffffff;
  background-color: #261004;
  text-align: center;
  border: none;
  border-radius: 3px;
  margin-left: 5px;
  margin-right: 5px;
`;

const SitePaginationContentContainer = styled.div`
  display: flex;
  margin-left: 20px;
  margin-right: 20px;
  color: #ffffff;
  font-size: 1rem;
  font-weight: bold;
  align-items: center;
`;

export default {
  SitePaginationCursorLeft: SitePaginationCursorLeft,
  SitePaginationCursorRight: SitePaginationCursorRight,
  SitePagination: SitePagination,
  SitePaginationContentContainer: SitePaginationContentContainer,
  SitePaginationInput: SitePaginationInput,
};
