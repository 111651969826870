import styled from "styled-components";
import CommonConstants from "../../../constants/CommonConstants";

const SiteContainer = styled.div `
  /* width: 100%; */

  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    margin-right: 30px;
    margin-left: 30px;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_TABLET_MIN}) and (max-width: ${CommonConstants.MIN_WIDTH_TABLET_MAX}) {
    margin-right: 50px;
    margin-left: 50px;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP}) and (max-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XL}) {
    margin-right: 80px;
    margin-left: 80px;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XL}) {
    margin-right: 100px;
    margin-left: 100px;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XXL}) {
    margin-right: 20%;
    margin-left: 20%;
  }
`;

export default {
  SiteContainer: SiteContainer,
};