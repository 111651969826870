import React from "react";

import HeaderLogo from "./resources/components/HeaderLogo";
import HeaderMenu from "./resources/components/HeaderMenu/HeaderMenu";
import HeaderMenuMobile from "./resources/components/HeaderMenuMobile/HeaderMenuMobile";
import style from "./resources/style/style";

function Header() {
  const Header = style.Header;

  return (
    <Header id="dk-website-header">
      <HeaderLogo></HeaderLogo>
      <HeaderMenu></HeaderMenu>
      <HeaderMenuMobile></HeaderMenuMobile>
    </Header>
  );
}

export default Header;
