import styled from "styled-components";
import CommonConstants from "../../../constants/CommonConstants";

const SiteProcessBar = styled.div`
  align-items: center;
  text-align: center;
  justify-content: center;
  position: relative;

  height: 33px;
  border-radius: 5px 5px 5px 5px;
  box-shadow: 0px 0px 0px 2px rgba(153, 153, 153, 0.623);
  margin-bottom: 20px;
  background: rgb(131, 116, 104);

  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 18px;
    background: rgba(65, 37, 14, 0.788);
    left: 0;
    top: 15px;
    border-radius: 5px 0px 5px 5px;
  }
`;

const SiteProcessBarTop = styled.div`
  position: relative;
  height: 25px;
  width: 0px;
  background: rgb(113, 148, 199);
  background: linear-gradient(
    111deg,
    rgba(113, 148, 199, 1) 0%,
    rgba(0, 255, 235, 1) 80%
  );
  border-radius: 5px 5px 0px 0px;
`;

const SiteProcessBarBottom = styled.div`
  content: "";
  display: block;
  position: absolute;
  width: 0px;
  height: 18px;
  background: rgb(0, 102, 251);
  background: linear-gradient(
    111deg,
    rgba(0, 102, 251, 1) 0%,
    rgba(0, 255, 235, 1) 80%
  );
  left: 0;
  top: 15px;
  border-radius: 5px 0px 5px 5px;
  z-index: 2;
`;

export default {
  SiteProcessBar: SiteProcessBar,
  SiteProcessBarTop: SiteProcessBarTop,
  SiteProcessBarBottom: SiteProcessBarBottom,
};
