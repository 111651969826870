import React from "react";
import style from "../style/style";
import ConfigMenu from "../../../../constants/ConfigMenu";
import { useHistory } from "react-router-dom";
import navcss from "./nav.css"

function HeaderMenuText() {
  const HeaderMenuText = style.HeaderMenuText;
  const HeaderMenuTextItem = style.HeaderMenuTextItem;
  const HeaderMenuTextItemActive = style.HeaderMenuTextItemActive;

  const MENU_LIST = ConfigMenu.MENU_LIST;

  const renderMenu = () => {
    return MENU_LIST.map((item, index) => {
      if (item.MENU_ITEM === "PitchDeck") {
        return (
          <HeaderMenuTextItem
            key={index}
            onClick={() => {
              window.open(item.MENU_ROUTER);
            }}
          >
            {item.MENU_ITEM}
          </HeaderMenuTextItem>
        );
      }

      if (item.MENU_NEWPAGE) {
        return (
          <HeaderMenuTextItem
            key={index}
            onClick={() => {
              window.open(item.MENU_ROUTER);
            }}
            className="navigation"
          >
            {item.MENU_ITEM}
            {item.MENU_SUB &&
              <div className="navigation-content">
                {item.MENU_SUB.map((i, id) => {
                  <a href="#" key={id}>aaaa</a>
                })}
                
                <a href="#">Link 2</a>
                <a href="#">Link 3</a>
              </div>
            }
          </HeaderMenuTextItem>
        );
      }

      if (item.MENU_ITEM === "Doc") {
        return (
          <HeaderMenuTextItem
            key={index}
            onClick={() => {
              window.open(item.MENU_ROUTER);
            }}
          >
            {item.MENU_ITEM}
          </HeaderMenuTextItem>
        );
      }


      if (
        window.location.pathname === item.MENU_ROUTER ||
        window.location.pathname ===
          ConfigMenu.MENU_BASE_PATH + item.MENU_ROUTER
      ) {
        return (
          <HeaderMenuTextItemActive
            key={index}
            onClick={() => {
              window.location.href =
                ConfigMenu.MENU_BASE_PATH + item.MENU_ROUTER;
            }}
          >
            {item.MENU_ITEM}
          </HeaderMenuTextItemActive>
        );
      } else {
        return (
          <HeaderMenuTextItem
            key={index}
            onClick={() => {
              window.location.href =
                ConfigMenu.MENU_BASE_PATH + item.MENU_ROUTER;
            }}
          >
            {item.MENU_ITEM}
          </HeaderMenuTextItem>
        );
      }
    });
  };

  const newMenu = () => {
    return (
      <div>
      <HeaderMenuTextItem
        className="navigation"
      >
        Whitepaper <img src="/icon-arrow-down.png" className="inline-img" width={20}/>
        <div className="navigation-content">
            <a href="https://dicekingdomgame.gitbook.io/dice-kingdom-th/" target="_blank"><img className="inline-img" src="/icon-th.png" width={20}/> ภาษาไทย</a>
            <a href="https://dicekingdomgame.gitbook.io/dice-kingdom/" target="_blank"><img className="inline-img" src="/icon-en.png" width={20}/> English</a>
          </div>
      </HeaderMenuTextItem>
      <HeaderMenuTextItem
        className="navigation"
      >
        Marketplace <img src="/icon-arrow-down.png" className="inline-img" width={20}/>
        <div className="navigation-content">
            <a href="https://www.bitkubnft.com/gashapon/130" target="_blank"><img className="inline-img" src="/icon-bitkub.png" width={20}/> Bitkub NFT</a>
            <a href="https://www.megaland.io/dicekingdom" target="_blank"><img className="inline-img" src="/icon-megaland.png" width={20}/> Megaland</a>
          </div>
      </HeaderMenuTextItem>

      <HeaderMenuTextItem
        className="navigation"
      >
        Buy-DK <img src="/icon-arrow-down.png" className="inline-img" width={20}/>
          <div className="navigation-content">
            <a href="https://app.diamon.finance" target="_blank"><img className="inline-img" src="/icon-diamon.png" width={20}/> Diamon</a>
            <a href="https://www.megaland.io/swap" target="_blank"><img className="inline-img" src="/icon-megaland.png" width={20}/> Megaland</a>
            
          </div>
      </HeaderMenuTextItem>


      <div className="navigation">
        <a href="javascript:showModal()" className="navigation bold-text">Add BKC Chain</a>
      </div>

    </div>
    );
  }
  return (
    <HeaderMenuText id="dk-website-header-menu-text">
      {newMenu()}
    </HeaderMenuText>
  );
}

export default HeaderMenuText;
