import React from "react";
import style from "./style/style";

const StyleSiteTextH3 = style.SiteTextH3;

function SiteTextH3(props) {
  const { children, ...rest } = props;

  return <StyleSiteTextH3 {...rest}>{children}</StyleSiteTextH3>;
}

export default SiteTextH3;
