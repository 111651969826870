class DicerFilter {
    constructor() {
        this.groupId = "";
        this.groupName = "";

        /**
         * @type {String}
         * @example checkbox, selection, color-checkbox, slide, search-checkbox-image
         */
        this.type = "";

        /**
         * @type {(DicerFilter_ItemCheckbox[]|DicerFilter_ItemSelection[]|DicerFilter_ItemColorCheckbox[]|DicerFilter_ItemSearchCheckboxImage[])}
         */
        this.list = [];

        this.min = 0;
        this.max = 4;
        /**
         * @type {Number[]}
         * */
        this.value = [];


    }


}

export class DicerFilter_ItemCheckbox {
    constructor() {
        this.value = "";
        this.checked = true;
    }
}

export class DicerFilter_ItemSelection {
    constructor() {
        this.itemId = "";
        this.title = "";
        this.value = "";
        /**
         * @type {(DicerFilter_ItemSourceOfSelection[])}
         */
        this.source = [];
    }
}

export class DicerFilter_ItemColorCheckbox {
    constructor() {
        this.value = "";
        this.checked = true;
    }
}

export class DicerFilter_ItemSearchCheckboxImage {
    constructor() {
        this.value = "";
        this.checked = true;
        this.urlImage = "";
    }
}

export class DicerFilter_ItemSourceOfSelection {
    constructor() {
        this.display = "";
        this.value = "";
    }
}

export default DicerFilter;