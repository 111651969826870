import React, { useState, useEffect } from "react";
import style from "../style/style";
import ButtonConnectWallet from "../../../../images/1-Home/Button-ConnectWallet.png";
import PlayNow from '../../../../images/1-Home/PlayNow.png';
import Megaland from '../../../../images/1-Home/Megaland.png';
import ConfigMenu from "../../../../constants/ConfigMenu";

import linkGameplay from "../../../../../pages/Home/mockup/linkGamePlay.json";

function HeaderMenuButton() {
  const HeaderMenuButton = style.HeaderMenuButton;
  const HeaderMenuButtonLeft = style.HeaderMenuButtonLeft;
  const HeaderMenuButtonRight = style.HeaderMenuButtonRight;
  const HeaderMenuImage = style.HeaderMenuImage;
  const BtnSiteButtonYellow = style.BtnSiteButtonYellow;

  const handleDemoPlayClick = () => {
    window.location.href = ConfigMenu.MENU_BASE_PATH + "/demoplay";
  };

  const [link, setLink] = useState("#");
  const [megaland, setMegaland] = useState("#");

  useEffect(() => {
    fetchLink();
  }, []);

  const fetchLink = async () => {
    setLink(linkGameplay.link);
    setMegaland(linkGameplay.megaland);
  };

  return (
    <HeaderMenuButton id="dk-website-header-menu-button">
      <HeaderMenuButtonRight>
        <a href={link}><img src={PlayNow} width="140" alt="PlayNow"/></a>
      </HeaderMenuButtonRight>
    </HeaderMenuButton>
  );
}

export default HeaderMenuButton;
