class RoadMap {
    constructor(title = "", topic = [], active = false) {
        /**
         * @type {String}
         */
        this.title = title;
        /**
         * @type {String[]}
         */
        this.topic = topic;
        /**
         * @type {Boolean}
         */
        this.active = active;
    }
}

export default RoadMap;