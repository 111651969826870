import styled from "styled-components";
import CommonConstants from "../../../../../../resources/constants/CommonConstants";

export const MarketplaceHeaderTapPage = styled.div `
  position: relative;
  z-index: 3;
  width: 100%;
  height: auto;
  background: rgb(28 12 10);
  display: inline-flex;
  padding-top: 5rem;
  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    padding-top: 3rem;
    display: none;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_TABLET_MIN}) and (max-width: ${CommonConstants.MIN_WIDTH_TABLET_MAX}) {
    display: none;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP}) and (max-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XL}) {
    padding-top: 5.5rem;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XL}) {
    padding-top: 5.5rem;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XXL}) {
    padding-top: 5rem;
  }
`;

export const MarketplaceHeaderTapItem = styled.div `
  /* height: 90px; */
  display: inline-flex;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  border-bottom: 3px;
  border-style: solid;
  border-color: ${(props) =>
    props.active ? CommonConstants.COLOR_ACTIVE : "rgb(28 12 10)"};
  color: ${(props) => (props.active ? CommonConstants.COLOR_ACTIVE : "#fff")};
  padding: 20px 0px 20px 0px;
  margin: 0px 20px 0px 20px;
  cursor: pointer;
`;

export const MarketplaceHeaderTapItemImage = styled.img `
  max-height: 25px;
`;

export const MarketplaceHeaderTapItemText = styled.span `
  position: relative;
  font-size: 1rem;
  font-weight: bold;
  margin-left: 10px;
`;

export const MarketplaceHeaderTapItemTextComingSoon = styled.div `
  position: absolute;
  top: -0.6rem;
  left: 0;
  color: red;
  font-weight: bold;
  font-size: 0.6rem;
`;