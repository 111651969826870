import React from "react";
import SiteTextH1 from "../../../Text/SiteTextH1";
import style from "../style/style";

function FooterContactUs() {
  const StyleFooterContactUs = style.FooterContactUs;
  const StyleFooterContactUsTitle = style.FooterContactUsTitle;
  const StyleFooterContactUsText = style.FooterContactUsText;

  return (
    <StyleFooterContactUs>
      <StyleFooterContactUsTitle>Contact us</StyleFooterContactUsTitle>
      <StyleFooterContactUsText>
        support@dicekingdom.com
      </StyleFooterContactUsText>
    </StyleFooterContactUs>
  );
}

export default FooterContactUs;
