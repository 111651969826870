import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import styled from "styled-components";
import CommonConstants from "../../../constants/CommonConstants";

const SiteButtonGroupRadio = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  margin-bottom: 15px;
`;

const SiteButtonGroupRadioIcon = styled.div`
  display: flex;
  flex-direction: row;
  /* margin-top: 15px;
  margin-bottom: 15px; */
`;

const CustomizedButton = withStyles({
  root: {
    boxShadow: "none",
    textTransform: "none",
    // fontSize: 16,
    // padding: "10px 16px",
    // marginRight: "5px",
    border: "3px solid",
    lineHeight: 1.5,
    fontWeight: 900,
    transform: "skewX(170deg)",
    minWidth: "unset",
    // flexGrow: 1,
    // borderRadius: 7,
  },
})(Button);

const CustomizedButtonNotSkew = withStyles({
  root: {
    boxShadow: "none",
    textTransform: "none",
    // fontSize: 16,
    // padding: "10px 16px",
    // marginRight: "5px",
    border: "3px solid",
    lineHeight: 1.5,
    fontWeight: "bold",
    // borderRadius: 7,
  },
})(Button);

const SiteButton = styled(CustomizedButton)`
  border-radius: 0.5rem !important;
  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    flex-grow: 1 !important;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_TABLET_MIN}) and (max-width: ${CommonConstants.MIN_WIDTH_TABLET_MAX}) {
    flex-grow: 1 !important;
  }
`;

const SiteButtonNotSkew = styled(CustomizedButtonNotSkew)`
  border-radius: 0.3rem !important;
`;

const SiteButtonYellow = styled(SiteButton)`
  background: rgb(250, 191, 28) !important;
  background: linear-gradient(
    rgba(250, 191, 28, 1) 0%,
    rgba(210, 144, 0, 1) 100%,
    rgba(250, 191, 28, 1) 0%
  ) !important;

  border-color: #663304 !important;
  color: #663304 !important;

  &:hover {
    background: rgb(255, 187, 0) !important;
    background: linear-gradient(
      #f8b600 0%,
      #f8aa00 100%,
      #f8b600 0%
    ) !important;

    border-color: #663304 !important;
    box-shadow: none !important;
  }

  &:active {
    box-shadow: none !important;
    background: rgb(250, 191, 28) !important;
    background: linear-gradient(
      rgba(250, 191, 28, 1) 0%,
      #c08401 100%,
      rgba(250, 191, 28, 1) 0%
    ) !important;

    border-color: #663304 !important;
  }

  &:focus {
    box-shadow: 0 0 0 0.2rem #663304 !important;
  }
`;

const SiteButtonBrownDoubleRadius = styled(SiteButtonNotSkew)`
  background: #836348 !important;
  border-color: #836348 !important;
  color: #d4b48f !important;
  border-width: 1px !important;
  border-radius: 20px !important;
  /* width: 100% !important; */
`;

const SiteButtonOutlineDoubleRadius = styled(SiteButtonNotSkew)`
  background: none !important;
  border-color: transparent !important;
  color: #dfd0cd !important;
  border-width: 1px !important;
  border-radius: 20px !important;
  /* width: 100% !important; */
  &:hover {
    background: none !important;
    border-color: #836348 !important;
    border-width: 1px !important;
  }
  &:active {
    background: #836348 !important;
  }
`;

const SiteButtonBule = styled(SiteButtonNotSkew)`
  background: #2191ff !important;
  background: linear-gradient(#2191ff 0%, #0067ce 100%, #2191ff 0%) !important;

  border-color: #663304 !important;
  color: #ffffff !important;
  border-width: 1px !important;
  border-radius: 8px !important;
  width: 100% !important;

  &:hover {
    background: #2191ff !important;
    background: linear-gradient(
      #2191ff 0%,
      #0067ce 100%,
      #2191ff 0%
    ) !important;

    border-color: #0067ce !important;
    box-shadow: none !important;
  }

  &:active {
    box-shadow: none !important;
    background: #2191ff !important;
    background: linear-gradient(
      #2191ff 0%,
      #0067ce 100%,
      #2191ff 0%
    ) !important;

    border-color: #0067ce !important;
  }

  &:focus {
    box-shadow: 0 0 0 0.2rem #0067ce !important;
  }
`;

const SiteButtonBrownDark = styled(SiteButton)`
  background: #8a4829 !important;

  border-color: #663304 !important;
  color: #c59678 !important;

  &:hover {
    background: #3a1a0c !important;

    border-color: #663304 !important;
    box-shadow: none !important;
  }

  &:active {
    box-shadow: none !important;
    background: #8a4829 !important;

    border-color: #663304 !important;
  }

  &:focus {
    box-shadow: 0 0 0 0.2rem #663304 !important;
  }
`;

const SiteButtonBrownLight = styled(SiteButton)`
  background: #ffe3c7 !important;

  border-color: #663304 !important;
  color: #4e0904 !important;

  &:hover {
    box-shadow: none !important;
    background: #b89069 !important;
  }

  &:active {
    box-shadow: none !important;
    background: #ffe3c7 !important;
  }

  &:focus {
    box-shadow: 0 0 0 0.2rem #663304 !important;
  }
`;

const SiteButtonDarkNotSkew = styled(SiteButtonNotSkew)`
  background: #1c0b09 !important;

  border-color: ${CommonConstants.COLOR_ACTIVE} !important;
  color: #ffffff !important;
  border-width: 1px !important;

  &:hover {
    box-shadow: none !important;
    background: #241918 !important;
  }

  &:active {
    box-shadow: none !important;
    background: #241918 !important;
  }

  &:focus {
    box-shadow: 0 0 0 0.2rem ${CommonConstants.COLOR_ACTIVE} !important;
    /* border-width: 2px !important; */
  }
`;

export default {
  SiteButtonGroupRadio: SiteButtonGroupRadio,
  SiteButtonGroupRadioIcon: SiteButtonGroupRadioIcon,
  SiteButtonYellow: SiteButtonYellow,
  SiteButtonBule: SiteButtonBule,
  SiteButtonBrownDark: SiteButtonBrownDark,
  SiteButtonBrownLight: SiteButtonBrownLight,
  SiteButtonDarkNotSkew: SiteButtonDarkNotSkew,
  SiteButtonBrownDoubleRadius: SiteButtonBrownDoubleRadius,
  SiteButtonOutlineDoubleRadius: SiteButtonOutlineDoubleRadius,
};
