import styled from "styled-components";
import CommonConstants from "../../../../resources/constants/CommonConstants";

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

export const OverviewMarketListContainer = styled.div`
  position: relative;
  padding: 1rem 2rem 1rem 2rem;
  border: 0.5px solid #f4a623;
  border-radius: 20px;
  background: #1c0b09ca;
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 1rem;

  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    padding: 1rem;
    width: unset;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_TABLET_MIN}) and (max-width: ${CommonConstants.MIN_WIDTH_TABLET_MAX}) {
    padding: 1rem;
    width: unset;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP}) and (max-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XL}) {
    width: unset;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XL}) {
    width: 100%;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XXL}) {
    width: 100%;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP_2K}) {
    width: 100%;
  }
`;

export const OverviewMarketListTitle = styled.div`
  color: #faf6f8;
  /* #faf6f8 */
  font-size: 1.8rem;
  font-weight: bold;

  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    font-size: 1rem;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_TABLET_MIN}) and (max-width: ${CommonConstants.MIN_WIDTH_TABLET_MAX}) {
    font-size: 1rem;
  }
`;

export const OverviewMarketListItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  /* justify-content: space-evenly; */
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: 0.5px solid #f4a72373;
`;

export const OverviewMarketListItemImageContainer = styled.div`
  position: relative;
  border: 0.5px solid #f4a623;
  border-radius: 10px;
  background: #64544f;
  height: 70px;
  width: 65px;
  padding: 0px;
  /* align-items: center; */
  text-align: center;
  /* align-content: center; */
  justify-content: center;
  display: flex;

  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    height: 45px;
    width: 35px;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_TABLET_MIN}) and (max-width: ${CommonConstants.MIN_WIDTH_TABLET_MAX}) {
    height: 50px;
    width: 40px;
  }
`;

export const OverviewMarketListItemImageBox = styled.div`
  margin-top: 5px;
  height: calc(65px - 19px);
  width: calc(65px - 19px);

  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    height: calc(35px - 10px);
    width: 35px;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_TABLET_MIN}) and (max-width: ${CommonConstants.MIN_WIDTH_TABLET_MAX}) {
    height: calc(40px - 10px);
    width: 40px;
  }
`;

export const OverviewMarketListItemImage = styled.img`
  max-height: calc(65px - 19px);
  max-width: calc(65px - 19px);

  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    max-height: calc(35px - 10px);
    max-width: calc(35px - 10px);
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_TABLET_MIN}) and (max-width: ${CommonConstants.MIN_WIDTH_TABLET_MAX}) {
    max-height: calc(40px - 10px);
    max-width: calc(40px - 10px);
  }
`;

export const OverviewMarketListItemNumberInImageContainer = styled.div`
  background: #f4a623;
  width: 65px;
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: 0px 0px 10px 10px;
  color: #64544f;
  font-size: 0.7rem;

  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    width: 35px;
    font-size: 0.4rem;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_TABLET_MIN}) and (max-width: ${CommonConstants.MIN_WIDTH_TABLET_MAX}) {
    width: 40px;
    font-size: 0.5rem;
  }
`;

export const OverviewMarketListItemTitleContainer = styled.div`
  padding: 0px 20px 0px 20px;
  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    padding: 0px 5px 0px 10px;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_TABLET_MIN}) and (max-width: ${CommonConstants.MIN_WIDTH_TABLET_MAX}) {
    padding: 0px 15px 0px 15px;
  }
`;

export const OverviewMarketListItemTitleId = styled.div`
  background: #f4a623;
  border-radius: 20px;
  color: #faf6f8;
  font-size: 1.2rem;
  font-weight: bold;
  padding: 0px 10px 0px 10px;
  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    font-size: 0.7rem;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_TABLET_MIN}) and (max-width: ${CommonConstants.MIN_WIDTH_TABLET_MAX}) {
    font-size: 0.7rem;
  }
`;

export const OverviewMarketListItemTitleDetailContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  justify-content: center;
`;

export const OverviewMarketListItemTitleDetailLabel = styled.div`
  color: #807a77;
  font-weight: 500;
  font-size: 1.1rem;
  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    font-size: 0.7rem;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_TABLET_MIN}) and (max-width: ${CommonConstants.MIN_WIDTH_TABLET_MAX}) {
    font-size: 0.7rem;
  }
`;

export const OverviewMarketListItemTitleDetailValue = styled.div`
  color: #faf6f8;
  font-weight: bold;
  font-size: 1.1rem;
  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    font-size: 0.7rem;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_TABLET_MIN}) and (max-width: ${CommonConstants.MIN_WIDTH_TABLET_MAX}) {
    font-size: 0.7rem;
  }
`;

export const OverviewMarketListItemDetailContainer = styled.div`
  padding: 0rem 1rem 0rem 0rem;
  border-left: 0.5px solid #f4a72373;
  border-right: 0.5px solid #f4a72373;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: center;
  /* width: 100%; */

  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    display: none;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_TABLET_MIN}) and (max-width: ${CommonConstants.MIN_WIDTH_TABLET_MAX}) {
    display: none;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP}) and (max-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XXXL}) {
    display: none;
  }

  @media (max-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XXXL}) {
    flex-direction: row;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP_2K}) {
    flex-direction: row;
  }
`;

export const OverviewMarketListItemDetailBoxContainer = styled.div`
  /* width: 100%; */
  margin-left: 1rem;
`;

export const OverviewMarketListItemDetailBox = styled.div`
  display: flex;
  flex-direction: row;
`;

export const OverviewMarketListItemDetailBoxLabel = styled.div`
  color: #807a77;
  font-weight: 500;
  font-size: 0.8rem;
`;

export const OverviewMarketListItemDetailBoxValue = styled.div`
  color: #faf6f8;
  font-weight: bold;
  font-size: 0.8rem;
  margin-left: 5px;
`;

export const OverviewMarketListItemPriceContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  padding: 0.5rem 1rem 0rem 1rem;
  justify-content: flex-end;
  flex-grow: ${(props) => (props.contenStretch === "stretch" ? "0" : "1")};

  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    flex-grow: 1;
    justify-content: center;
    padding: 0rem;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_TABLET_MIN}) and (max-width: ${CommonConstants.MIN_WIDTH_TABLET_MAX}) {
    flex-grow: 1;
    justify-content: center;
    padding: 0rem;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP}) and (max-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XXXL}) {
    flex-grow: 1;
    justify-content: center;
  }

  @media (max-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XXXL}) {
    flex-grow: 1;
    justify-content: center;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_DESKTOP_2K}) {
    flex-grow: ${(props) => (props.contenStretch === "stretch" ? "0" : "1")};
  }
`;

export const OverviewMarketListItemPriceImageBox = styled.div`
  margin-right: 0.8rem;
  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    margin-right: 0.5rem;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_TABLET_MIN}) and (max-width: ${CommonConstants.MIN_WIDTH_TABLET_MAX}) {
    margin-right: 0.5rem;
  }
`;

export const OverviewMarketListItemPriceImage = styled.img`
  width: 25px;
  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    width: 14px;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_TABLET_MIN}) and (max-width: ${CommonConstants.MIN_WIDTH_TABLET_MAX}) {
    width: 14px;
  }
`;

export const OverviewMarketListItemPriceDetailContainer = styled.div`
  /* display: flex;
  flex-direction: row; */
  text-align: center;
`;

export const OverviewMarketListItemPriceDetailHighlight = styled.span`
  color: #faf6f8;
  font-weight: bold;
  font-size: 1.3rem;
  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    font-size: 0.9rem;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_TABLET_MIN}) and (max-width: ${CommonConstants.MIN_WIDTH_TABLET_MAX}) {
    font-size: 1rem;
  }
`;

export const OverviewMarketListItemPriceDetailHighlightSmall = styled.span`
  color: #faf6f8;
  font-weight: bold;
  font-size: 1rem;
  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    font-size: 0.7rem;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_TABLET_MIN}) and (max-width: ${CommonConstants.MIN_WIDTH_TABLET_MAX}) {
    font-size: 0.8;
  }
`;

export const OverviewMarketListItemPriceDetail = styled.span`
  color: #807a77;
  /* font-weight: bold; */
  font-size: 1rem;
  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    font-size: 0.7rem;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_TABLET_MIN}) and (max-width: ${CommonConstants.MIN_WIDTH_TABLET_MAX}) {
    font-size: 0.8;
  }
`;

export const OverviewMarketListItemButtonContainer = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  justify-items: center;
  cursor: pointer;
  padding-left: 1rem;
  padding-right: 1rem;
  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    padding: 0px;
    font-size: 0.5rem !important;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_TABLET_MIN}) and (max-width: ${CommonConstants.MIN_WIDTH_TABLET_MAX}) {
    padding: 0px;
    font-size: 0.5rem !important;
  }
`;

export const OverviewMarketListItemButton = styled.div`
  color: #807a77;
  /* font-weight: bold; */
  /* font-size: 2.5rem; */
`;

export const OverviewMarketListItemButtonIcon = styled(ArrowForwardIosIcon)`
  font-size: 0.7rem !important;
`;

export const OverviewMarketListBtnViewMore = styled.div`
  width: 100%;
  /* margin: 1rem; */
  background-color: rgb(128, 75, 49);
  display: none;
  justify-content: center;
  align-content: center;
  align-items: center;
  color: #ffffff;
  border-radius: 8px;

  padding-top: 5px;
  padding-bottom: 5px;
  cursor: pointer;

  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    display: flex;
  }

  @media (min-width: ${CommonConstants.MIN_WIDTH_TABLET_MIN}) and (max-width: ${CommonConstants.MIN_WIDTH_TABLET_MAX}) {
    display: flex;
  }
`;
