import React from "react";
import styled from "styled-components";


const TextLarge = styled.div `
	font-size: 100px;
	text-align: center;
	color: white;
	padding-top: 30px;
`


function DemoPlayNews() {
  return <TextLarge>Comming Soon</TextLarge>;
}

export default DemoPlayNews;
