import React, { useState } from "react";
import {
  OverviewMarketContentContainer,
  OverviewMarketContentText,
  OverviewMarketContentImageContainer,
  OverviewMarketContentImage,
  OverviewMarketContentTextTitle,
  OverviewMarketContentBtnTitle,
  OverviewMarketContentSummaryContainerMain,
  OverviewMarketContentSummaryContainer,
  OverviewMarketContentSummaryImage,
  OverviewMarketContentSummaryTextContainer,
  OverviewMarketContentSummaryTextTitle,
  OverviewMarketContentSummaryTextValue,
  OverviewMarketContentTextTitleText,
} from "./OverviewMarketContent.component";
import SiteButtonGroupRadioOutline from "../../../../resources/components/SiteButton/SiteButtonGroupRadioOutline";
import DiceBoxOverviewImage from "../../../../resources/images/6-Overview/dice-box-overview.png";
import TotalSaleIconImage from "../../../../resources/images/6-Overview/Total sale Icon.png";
import TotalValumeIconImage from "../../../../resources/images/6-Overview/Total Valume icon.png";
import AveragePriceIconImage from "../../../../resources/images/6-Overview/Average Price Icon.png";

function OverviewMarketContent(props) {
  let {
    tapOverallStats,
    activeTapOverallStats,
    onChangeTapOverallStats,
    totalSale,
    totalValume,
    averagePrice,
    descriptionSale,
    descriptionValume,
    descriptionPrice,
  } = props;

  const _renderContentSummary = (title, value, detail, image) => {
    return (
      <OverviewMarketContentSummaryContainer>
        <OverviewMarketContentSummaryImage src={image} />
        <OverviewMarketContentSummaryTextContainer>
          <OverviewMarketContentSummaryTextTitle>
            {title}
          </OverviewMarketContentSummaryTextTitle>
          <OverviewMarketContentSummaryTextValue>
            {value}
          </OverviewMarketContentSummaryTextValue>
          <OverviewMarketContentSummaryTextTitle>
            {detail}
          </OverviewMarketContentSummaryTextTitle>
        </OverviewMarketContentSummaryTextContainer>
      </OverviewMarketContentSummaryContainer>
    );
  };

  return (
    <OverviewMarketContentContainer id="dk-website-overview-market-content">
      <OverviewMarketContentText>
        <OverviewMarketContentTextTitle>
          <OverviewMarketContentTextTitleText>
            Overall Stats
          </OverviewMarketContentTextTitleText>
          <OverviewMarketContentBtnTitle>
            <SiteButtonGroupRadioOutline
              list={tapOverallStats}
              active={activeTapOverallStats}
              setActiveOnClick={onChangeTapOverallStats}
              fontSize={"1rem"}
            />
          </OverviewMarketContentBtnTitle>
        </OverviewMarketContentTextTitle>

        <OverviewMarketContentSummaryContainerMain>
          {_renderContentSummary(
            "Total sale",
            totalSale,
            descriptionSale,
            TotalSaleIconImage
          )}
          {_renderContentSummary(
            "Total Valume",
            totalValume,
            descriptionValume,
            TotalValumeIconImage
          )}
          {_renderContentSummary(
            "Average Price",
            averagePrice,
            descriptionPrice,
            AveragePriceIconImage
          )}
        </OverviewMarketContentSummaryContainerMain>
      </OverviewMarketContentText>

      <OverviewMarketContentImageContainer>
        <OverviewMarketContentImage src={DiceBoxOverviewImage} />
      </OverviewMarketContentImageContainer>
    </OverviewMarketContentContainer>
  );
}

export default OverviewMarketContent;
