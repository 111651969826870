import React, { useEffect } from "react";
import {
  MarketplaceDialogContainer,
  MarketplaceDialogRoot,
  MarketplaceDialogTitle,
  MarketplaceDialogCloseButton,
} from "./MarketplaceDialog.component";
import MarketplaceDialogDicerDetail from "./DicerDetail/MarketplaceDialogDicerDetail";
import MarketplaceDialogCapsuleDetail from "./CapsuleDetail/MarketplaceDialogCapsuleDetail";
import MarketplaceDialogCapsuleItemDetail from "./ItemDetail/MarketplaceDialogItemDetail";
/**
 *
 * @param {Props} props
 * @returns {React.FC}
 */
function MarketplaceDialog(props) {
  const { open, mode, dicerId, onClose } = props;

  useEffect(() => {
    if (open) {
      // open
      const parent = document.querySelector(".score");
      parent && parent.classList.add("open-dialog");
    } else {
      // close
      const parent = document.querySelector(".score");
      parent && parent.classList.remove("open-dialog");
    }
  }, [open]);

  const renderContent = () => {
    if (mode === "dicer") {
      return <MarketplaceDialogDicerDetail dicerId={dicerId} />;
    } else if (mode === "capsule") {
      return <MarketplaceDialogCapsuleDetail dicerId={dicerId} />;
    } else if (mode === "item") {
      return <MarketplaceDialogCapsuleItemDetail dicerId={dicerId} />;
    } else {
      return null;
    }
  };

  const handleClose = () => {
    onClose && onClose();
  };

  const _renderTitleDialog = () => {
    if (mode === "dicer") {
      return "Dicer";
    } else if (mode === "capsule") {
      return "Capsule";
    } else if (mode === "item") {
      return "Item";
    } else {
      return "";
    }
  };

  return (
    <MarketplaceDialogRoot open={open}>
      <MarketplaceDialogContainer>
        <MarketplaceDialogTitle>
          {_renderTitleDialog()} Details
        </MarketplaceDialogTitle>
        <MarketplaceDialogCloseButton
          className="material-icons"
          onClick={handleClose}
        >
          close
        </MarketplaceDialogCloseButton>
        {renderContent()}
      </MarketplaceDialogContainer>
    </MarketplaceDialogRoot>
  );
}

export default MarketplaceDialog;

class Props {
  constructor() {
    this.open = false;
    this.onClose = () => {};
    this.dicerId = "";
    /**
     * @type {"dicer" | "capsule"}
     */
    this.mode = "";
  }
}
