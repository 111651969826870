class NumberFormat {
  /**
   *
   * @param {Number} number
   * @returns {String}
   */
  static toUSD(number = 0) {
    return new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(number);
  }
}

export default NumberFormat;
