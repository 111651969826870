import styled from "styled-components";
import CommonConstants from "../../../../resources/constants/CommonConstants";
// FPC = Farm Pool Component
export const FPC_Container = styled.div`
  position: relative;
  display: ${(props) => (props.visible ? "flex" : "none")};
  flex-direction: column;
  border: 2px solid #ad6f0a;
  border-radius: 12px;
  color: #f9e7c8;
  background-color: #210901e8;
  padding: 2rem 4rem;
  height: auto;
  margin-bottom: 2rem;
  /* transition: max-height 150ms ease-out;
  max-height: ${(props) => (props.expanded ? "1000px" : "100px")}; */

  @media (max-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XXL}) {
    padding: 1.75rem 3rem;
  }

  @media (max-width: ${CommonConstants.MIN_WIDTH_DESKTOP_XL}) {
    padding: 1.5rem 2rem;
    padding-right: 1.5rem;
  }
`;

export const FPC_MultiplierBadge = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #000000;
  border-radius: 0.5rem;
  transform: skewX(-6deg);
  font-weight: bold;
  color: #000000;
  background: #fe6d00;
  left: -1.25rem;
  top: 3rem;
  width: 3rem;
  height: 2.5rem;
  z-index: 10;

  @media (max-width: ${CommonConstants.MIN_WIDTH_DESKTOP}) {
    left: calc(100% - 5rem);
  }

  @media (max-width: ${CommonConstants.MIN_WIDTH_MOBILE}) {
    top: 2.5rem;
    left: calc(100% - 4.5rem);
  }
`;
